.root {}

.input {
  border-color: #dcdcdc;
}

.inputSuccess {
  border-color: #dcdcdc;
}

.inputError {
  border-color: var(--failColor);
}

.textarea {}