@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap);
.IconAdd_root__tedyU {
  fill: var(--marketplaceColor);
}

.IconArrowHead_root__nQKEh {
  stroke: var(--matterColor);
  fill: var(--matterColor);
}

.IconBannedUser_backgroundLight__1A22_ {
  stop-color: var(--bannedColorLight);
}

.IconBannedUser_backgroundDark__2BBz6 {
  stop-color: var(--bannedColorDark);
}

.IconBannedUser_foregroundFill__680S2 {
  fill: var(--matterColorLight);
}

.IconBannedUser_foregroundStroke__nPXCA {
  stroke: var(--matterColorLight);
}

.IconCard_root__xUFPa {
}

.IconCheckMark_root__yNjLY {
  width: 24px;
  height: 24px;
  stroke: var(--marketplaceColor);
}

.IconClose_root__29xMB {
  stroke: var(--matterColor);
}

.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 50%;
  padding: 5px;
}

.popup-arrow {
  color: rgb(255, 255, 255);
}

[role='tooltip'].popup-content {
  width: 266px;
  box-shadow: rgb(222 222 222) 0px 0px 31px;
  padding: 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 160%;
  font-size: 15px;
  font-weight: 400;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

[data-popup='tooltip'].popup-overlay {
  background: transparent;
}

@media (max-width:414px) {
  [role='tooltip'].popup-content {
    width: 200px;
  }

}
.IconDisputeOrder_root__18IYo {
  stroke: var(--marketplaceColor);
}

.IconEdit_root__1v1in,
.IconEdit_pencil__2cpkA {
  stroke: var(--marketplaceColor);
}

.IconEmailAttention_root__26B8c {
}

.IconEmailAttention_marketplaceStroke__3fC-O {
  stroke: var(--marketplaceColor);
}

.IconEmailAttention_attentionStroke__9WKb7 {
  stroke: var(--attentionColor);
}

.IconEmailSent_root__2UiDn {
  stroke: var(--marketplaceColor);
}

.IconEmailSuccess_root__R6SBE {
}

.IconEmailSuccess_marketplaceStroke__hPvcN {
  stroke: var(--marketplaceColor);
}

.IconEmailSuccess_successFill__3NAul {
  fill: var(--successColor);
}

.IconEmailSuccess_checkStroke__3hLmC {
  stroke: var(--matterColorLight);
}

.IconEnquiry_root__LY0Wg {
}

.IconEnquiry_marketplaceColorStroke__1Snws {
  stroke: var(--marketplaceColor);
}

.IconKeys_strokeMarketplaceColor__1xXui {
  stroke: var(--marketplaceColor);
}

.IconKeysSuccess_strokeMarketplaceColor__3lGUG {
  stroke: var(--marketplaceColor);
}

.IconKeysSuccess_fillSuccessColor__1j48C {
  fill: var(--successColor);
}

.IconKeysSuccess_strokeLightColor__35sFg {
  stroke: var(--matterColorLight);
}

.IconReviewStar_root__2u_m3 {
  fill: var(--matterColorNegative);
}

.IconReviewStar_filled__18gGI {
  fill: var(--marketplaceColor);
}

.IconReviewUser_root__3mH8d {
  stroke: var(--marketplaceColor);
}

.IconSearch_root__3bFci {
  stroke: var(--marketplaceColor);
}

.IconSocialMediaFacebook_root__1ZSCg {
  fill: var(--matterColor);
  transition: var(--transitionStyleButton)
}
.IconSocialMediaFacebook_root__1ZSCg:hover {
    fill: var(--marketplaceColor);
  }

.IconSocialMediaInstagram_root__I496Q {
  fill: var(--matterColor);
  transition: var(--transitionStyleButton)
}
.IconSocialMediaInstagram_root__I496Q:hover {
    fill: var(--marketplaceColor);
  }

.IconSocialMediaTwitter_root__3kH2Y {
  fill: var(--matterColor);
  transition: var(--transitionStyleButton)
}
.IconSocialMediaTwitter_root__3kH2Y:hover {
    fill: var(--marketplaceColor);
  }

.IconSpinner_root__1h99J {
  /* While the viewbox of the icon is 30x30, the icon size is scaled to
   28x28 by default, which results in scaling the whole image and the
   strokes a bit.*/
  width: 28px;
  height: 28px;

  stroke: var(--marketplaceColor);
  stroke-width: 3px;
}

.IconSuccess_root__2ZnkE {
}

.IconSuccess_fillColor__2CXJX {
  fill: var(--successColor);
}

.IconHelp_root__1fzZP {
}

/* #root {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  min-width: 700px;
} */

.TagsComponent_tags__1z0Q3 {
  background: #fff;
  border: 1px solid #ebebeb;
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  margin: 0;
  padding: 6px 36px 6px 15px;
  border-radius: 3px
}

.TagsComponent_tags__1z0Q3 ul {
    margin: 0;
  }

.TagsComponent_tagsItem__bAB1N {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.TagsComponent_deleteAllTags__31RgA {
  position: absolute;
  top: 9px;
  right: 6px;
  line-height: 0;
  padding: 10px;
  cursor: pointer
}

.TagsComponent_deleteAllTags__31RgA>svg {
    stroke: none;
  }

.TagsComponent_deleteAllTags__31RgA:hover>svg {
      fill: #f00;
    }

.TagsComponent_tag__1V3xo {
  color: #fff;
  font-weight: bold;
  background: #fff;
  float: left;
  padding: 8px 9px;
  border-radius: 10em;
  margin: 2px 4px 2px 0;
  color: #0d0d0d;
  letter-spacing: -0.04em;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  background: #e9e9e9;
  border-radius: 26px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between
}

.TagsComponent_tag__1V3xo button {
    background: transparent;
    border: 0;
    cursor: pointer;
  }

.TagsComponent_tag__1V3xo svg {
    margin-left: 7px;
    cursor: pointer;
  }

.TagsComponent_tagInput__raNHs {
  padding: 2px 0px 5px;
  box-sizing: border-box;
  color: #0d0d0d;
  letter-spacing: -0.04em;
  font-weight: normal;
  line-height: 100%;
  border: 0;
  float: left;
  margin: 0px 0 0;
  font-size: 16px;
  outline: 0;
  width: auto;
  background: #fff;
  border: 0 !important
}

.TagsComponent_tagInput__raNHs:focus {
    border: 0 !important;
  }

.TagsComponent_tagInput__raNHs:hover {
    border: 0 !important;
  }

code {
  /* font-size: 12px;
  background: #fcf8d0;
  display: inline-block;
  font-family: courier;
  padding: 4px 6px;
  border-radius: 4px; */
}

.TagsComponent_tip__1c6Yn {
  /* color: #7f8c8d;
  font-size: 14px;
  margin-top: 10px;
  display: block;
  line-height: 16px; */
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.04em;
  color: #767676;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TagsComponent_totalTags__1Wr_A {
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.04em;
  color: #767676;
  width: auto !important;
}

.TagsComponent_form__33M21 .TagsComponent_labelWrapper__2LjTS {
    margin-bottom: 12px
  }

.TagsComponent_form__33M21 .TagsComponent_labelWrapper__2LjTS label {
      padding: 0;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: -0.04em;
      color: #5a5a5a;
      font-weight: 600;
    }

.TagsComponent_form__33M21 .TagsComponent_labelWrapper__2LjTS button {}

.TagsComponent_form__33M21 .TagsComponent_tags__1z0Q3 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

.TagsComponent_ingresosWrapper__3Nd9t {
  position: relative;
}

.TagsComponent_tooltip__1XDad {
  position: absolute;
  padding: 14px !important;
  right: 0px;
  width: 318px;
  height: auto;
  top: 34px;
  left: 0px;
  background-color: white;
  cursor: pointer;
  border: 0px solid black;
  border-radius: 5px;
  z-index: 1;
  display: block;
  padding: 5px;
  box-shadow: 0 -1px 4px 0 rgb(0 0 0 / 12%), 0 4px 4px 0 rgb(0 0 0 / 25%)
}

@media (min-width: 768px) {

.TagsComponent_tooltip__1XDad {
    top: 34px;
    left: 10px
}
  }

@media (min-width: 1024px) {

.TagsComponent_tooltip__1XDad {
    top: 34px;
    left: 10px
}
  }

.TagsComponent_tooltip__1XDad::after {
  content: ' ';
  position: absolute;
  bottom: 100%;
  left: 90px;
  margin-left: -10px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
  border-left: solid 12px transparent;
  border-right: solid 12px transparent;
  border-bottom: solid 8px #fff;
  -webkit-filter: drop-shadow(1px -1.2px 1px #00000020);
          filter: drop-shadow(1px -1.2px 1px #00000020);
  height: 0px;
  width: 0
}

@media (min-width: 768px) {

.TagsComponent_tooltip__1XDad::after {
    left: 40px
}
  }

.TagsComponent_tooltipStyle__17t6v {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: -0.56px;
  text-align: left;
  color: #484848;
  font-family: 'Sofia Pro Soft';
  margin-bottom: 0px !important;
}

.TagsComponent_toltipCloseBtn__-G0ZR {
  position: absolute;
  right: 15px;
  top: 15px;
}
.OutsideClickHandler_root__1ovUd {
  display: inline-block;
}

.AspectRatioWrapper_root__1rNsr {
  display: block;
  width: 100%;
  overflow: hidden;
  position: relative;
  transition: all ease 0.5s
}
.AspectRatioWrapper_root__1rNsr:hover {
    transition: all ease 0.5s
  }
.AspectRatioWrapper_root__1rNsr:hover .AspectRatioWrapper_aspectPadding__2R8fo .AspectRatioWrapper_aspectBox__2Enl0 {
        /* & img {
          transform: scale(1.05);
          box-shadow: var(--boxShadowListingCard);
          transition: all ease 0.5s;
        } */
      }
.AspectRatioWrapper_root__1rNsr .AspectRatioWrapper_aspectPadding__2R8fo .AspectRatioWrapper_aspectBox__2Enl0 {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%
    }
.AspectRatioWrapper_root__1rNsr .AspectRatioWrapper_aspectPadding__2R8fo .AspectRatioWrapper_aspectBox__2Enl0 img {
        -webkit-transform: scale(1);
                transform: scale(1);
        transition: all ease 0.5s;
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
.LimitedAccessBanner_root__Tva8u {
  background-color: #df492a;
  text-align: center;
  padding: 10px 20px 9px;
}

.LimitedAccessBanner_text__3_aam {
  margin: 0;
  display: inline-block;
  color: #fff;
  font-size: 16px;
  margin-bottom: 16px;
  line-height: 20px;
}

.LimitedAccessBanner_button__2yYEa {
  background: #2a3d4b;
  margin: 0 16px;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
  border: 0
}

.LimitedAccessBanner_button__2yYEa:hover {
    text-decoration: none;
    background: #364f61;
  }

.NotificationBadge_root__1iaay {
  /* Font */
  font-weight: var(--fontWeightBold);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}
@media (min-width: 768px) {
  .NotificationBadge_root__1iaay {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 6px
}
    }
.NotificationBadge_root__1iaay {
  color: var(--matterColorLight);
  text-align: center;

  background-color: #000000;

  /* Layout of a red dot */
  display: inline-block;
  width: 10px;
  /* min-width: 20px; */
  height: 10px;
  border-radius: 10px;
  padding: 1px 5px 3px 6px;
  margin-left: 4px;

  /* Font margins off */
  margin-top: 0;
  margin-bottom: 0
}
@media (min-width: 768px) {
  .NotificationBadge_root__1iaay {
    margin-top: 0;
    margin-bottom: 0;
    padding: 2px 5px 3px 6px
}
  }
@media (min-width: 1024px) {
  .NotificationBadge_root__1iaay {
    font-size: 14px;
    min-width: 24px;
    height: 24px;
    border-radius: 12px;
    top: -2px;
    padding: 4px 5px 4px 6px;

    position: relative
}
  }

.PropertyGroup_root__1r-sf {
  margin: 0;
}

@media (min-width: 768px) {

.PropertyGroup_twoColumns__1x1PF {
    -webkit-column-count: 2;
            column-count: 2
}
  }

.PropertyGroup_item__bMj6P {
  display: flex;
  align-items: center;
  padding: 3px 0
}

@media (min-width: 768px) {

.PropertyGroup_item__bMj6P {
    padding: 4px 0
}
  }

.PropertyGroup_checkIcon__3Xnsr {
}

.PropertyGroup_hidden__1rPeF {
  visibility: hidden;
}

.PropertyGroup_marketplaceFill__2Ijtv {
  fill: var(--marketplaceColor);
}

.PropertyGroup_iconWrapper__v3k-B {
  align-self: baseline;
  margin-right: 8px;

  /* This follows line-height */
  height: 24px;
}

.PropertyGroup_labelWrapper__yVTV4 {
  display: inline-block;
}

.PropertyGroup_selectedLabel__yMRfz,
.PropertyGroup_notSelectedLabel__33Rk0 {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.PropertyGroup_selectedLabel__yMRfz,
.PropertyGroup_notSelectedLabel__33Rk0 {
  font-size: 16px;
  line-height: 28px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 16px;
  margin-bottom: 16px;
}
    }

.PropertyGroup_selectedLabel__yMRfz,
.PropertyGroup_notSelectedLabel__33Rk0 {
  line-height: 24px;
  margin: 0;
}

.PropertyGroup_selectedLabel__yMRfz {
  font-weight: var(--fontWeightSemiBold);
}

.PropertyGroup_notSelectedLabel__33Rk0 {
  color: var(--matterColorNegative);
  position: relative;

  /* line-through */
  background-image: linear-gradient(
    transparent 10px,
    var(--matterColorNegative) 10px,
    var(--matterColorNegative) 11px,
    transparent 11px
  )
}

@media (min-width: 768px) {

.PropertyGroup_notSelectedLabel__33Rk0 {
    background-image: linear-gradient(
      transparent 12px,
      var(--matterColorNegative) 12px,
      var(--matterColorNegative) 13px,
      transparent 13px
    )
}
  }

.Handle_rootTouchBuffer__2-dpb {
  /* Position */
  position: absolute;
  top: 50%;
  margin-top: -22px;
  margin-left: -22px;

  /* Layout */
  width: 44px;
  height: 44px;
  padding: 0;
  border: 0;
}

.Handle_visibleHandle__1cQAv {
  /* Position */
  position: absolute;
  top: 12px;
  margin-left: 12px;

  /* Layout */
  width: 20px;
  height: 20px;
  border: 2px solid var(--marketplaceColor);
  border-radius: 50%;
  background-color: var(--matterColorLight);
  cursor: pointer
}

.Handle_visibleHandle__1cQAv:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
  }

.Handle_dragged__201iF {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
}

.Track_root__60R25 {
  position: relative;
  width: 100%;
  height: 100%;
}

.Track_track__1e8Um {
  /* Position */
  position: absolute;
  top: 50%;
  margin-top: -4px;
  margin-left: -10px;

  /* Layout */
  width: 100%;
  height: 8px;
  padding: 0 10px;

  box-sizing: content-box;
  background-color: var(--matterColorNegative);
  border-radius: 4px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.Track_range__20jna {
  position: absolute;
  top: 50%;
  margin-top: -4px;
  height: 8px;
  background-color: var(--marketplaceColor);
}

.RangeSlider_root__2cIvm {
  position: relative;
  margin: 0 10px;
  width: calc(100% - 20px);
  height: 24px;
}

.RangeSlider_activeHandle__1JUQM {
  z-index: 1;
}

.ResponsiveImage_root__ypInY {
  width: 100%;
}

.ResponsiveImage_noImageContainer__UNyKc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--matterColorNegative);
}

.ResponsiveImage_noImageWrapper__25jk2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ResponsiveImage_noImageIcon__11lk7 {
  padding-top: 24px;
  box-sizing: content-box;
  stroke: var(--matterColor);
}

.ResponsiveImage_noImageText__33c4w {
  font-weight: var(--fontWeightBold);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.ResponsiveImage_noImageText__33c4w {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 6px
}
    }

.ResponsiveImage_noImageText__33c4w {
  color: var(--matterColor);
  padding-bottom: 24px;

  margin-bottom: 0
}

@media (min-width: 768px) {

.ResponsiveImage_noImageText__33c4w {
    margin-bottom: 0
}
  }

.ValidationError_root__2BqiA {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}
@media (min-width: 768px) {
  .ValidationError_root__2BqiA {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }
.ValidationError_root__2BqiA {
  margin-top: 6px;
  margin-bottom: 12px;
  color: var(--failColor);
  overflow: hidden
}
@media (min-width: 768px) {
  .ValidationError_root__2BqiA {
    margin-top: 6px;
    margin-bottom: 10px
}
  }

.FieldMultiSelect_allItemsAreSelected__2DuJb {
  display: inline-block;
  text-transform: capitalize;
}

.FieldMultiSelect_allItemsAreSelected__2DuJb+.FieldMultiSelect_allItemsAreSelected__2DuJb::before {
  display: inline-block;
  white-space: pre;
  content: ', ';
}

.FieldMultiSelect_attention__-0Ow_ {}

.FieldMultiSelect_selectContainer__219ur {
  position: relative
}

.FieldMultiSelect_selectContainer__219ur>div~div {
    position: relative;
    display: block;
  }

.FieldMultiSelect_success__3mCLT>div>div {
      border: 1px solid var(--successColor) !important
    }

.FieldMultiSelect_success__3mCLT>div>div:focus,
      .FieldMultiSelect_success__3mCLT>div>div:hover {
        border: 1px solid var(--successColor) !important;
      }

.FieldMultiSelect_error__a61sV>div>div {
      border: 1px solid var(--failColor) !important
    }

.FieldMultiSelect_error__a61sV>div>div:focus,
      .FieldMultiSelect_error__a61sV>div>div:hover {
        border: 1px solid var(--failColor) !important;
      }

.react-select__menu {
  position: absolute;
  padding: 0px !important;
  z-index: 1000;
  cursor: pointer;
}

.react-select__multi-value__label {
  margin: 0 0 0 5px;
}

.FieldMultiSelect_multiSelectLabel__2P-GO {
  font-size: 20px;
}

.FieldMultiSelect_multiselectValueLabel__1MB1q {
  font-size: 20px;
}

.FieldMultiSelect_categorySelect__Z1bQo label {
  font-size: 18px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.72px;
  text-align: left;
  color: #484848;
}
.ListSlider_sectionTitle__2NA02 {
  font-size: 20px;
  font-weight: 600;
  color: var(--matterColorDark);
  margin: 0;
}

.ListSlider_profileSliderButtons__3oV9I {
  position: absolute;
  top: 40%;
  right: 0;
  z-index: 2;
  cursor: pointer
}

.ListSlider_profileSliderButtons__3oV9I .ListSlider_iconHolder__uREeY {
    background-color: #fff;
    border: 1px solid #ebebeb;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all ease 0.5s;
    cursor: pointer
  }

@media (--viewportXXLarge) {

.ListSlider_profileSliderButtons__3oV9I .ListSlider_iconHolder__uREeY {
      width: 56px;
      height: 56px
  }
    }

.ListSlider_profileSliderButtons__3oV9I .ListSlider_iconHolder__uREeY:hover {
      border-color: #484848;
      cursor: pointer;
    }

.ListSlider_profileSliderButtons__3oV9I .ListSlider_iconHolder__uREeY:first-child {
      margin-right: 4px
    }

@media (--viewportXXLarge) {

.ListSlider_profileSliderButtons__3oV9I .ListSlider_iconHolder__uREeY:first-child {
        margin-right: 14px
    }
      }

.ListSlider_profileSliderButtons__3oV9I .ListSlider_iconHolder__uREeY svg {
      fill: none;
    }

.ListSlider_homepage__3-A5U {
  width: calc(100% / 3 - 15px);
  margin-bottom: 15px;
  margin-right: 15px;
  scroll-snap-align: start
}

.ListSlider_homepage__3-A5U:last-child {
    margin-right: 0px
  }

.ListSlider_homepage__3-A5U:last-child>a {
      margin-right: 0px;
    }

.ListSlider_moreServices__c5uyV .ListSlider_moreServicesTitle__301eY {
    margin-bottom: 15px
  }

.ListSlider_moreServices__c5uyV .ListSlider_moreServicesTitle__301eY h1 {
      font-family: 'Sofia Pro Soft';
      font-style: normal;
      font-weight: bold;
      color: #1F1F1F;
      font-size: 25px;
      line-height: 100%;
      letter-spacing: -0.04em;
      margin: 0px 0px 16px 0
    }

@media (min-width:480px) {

.ListSlider_moreServices__c5uyV .ListSlider_moreServicesTitle__301eY h1 {
        font-size: 36.6667px;
        line-height: 100%
    }
      }

@media (min-width:768px) {

.ListSlider_moreServices__c5uyV .ListSlider_moreServicesTitle__301eY h1 {
        font-size: 28px;
        line-height: 100%
    }
      }

@media (min-width:1260px) {

.ListSlider_moreServices__c5uyV .ListSlider_moreServicesTitle__301eY h1 {
        font-size: 32px;
        line-height: 100%
    }
      }

@media (min-width:1366px) {

.ListSlider_moreServices__c5uyV .ListSlider_moreServicesTitle__301eY h1 {
        font-size: 32px;
        line-height: 100%
    }
      }

@media (min-width:1660px) {

.ListSlider_moreServices__c5uyV .ListSlider_moreServicesTitle__301eY h1 {
        font-size: 36.8889px;
        line-height: 100%
    }
      }

@media (min-width:1921px) {

.ListSlider_moreServices__c5uyV .ListSlider_moreServicesTitle__301eY h1 {
        font-size: 56.8956px;
        line-height: 100%
    }
      }

.ListSlider_moreServices__c5uyV .ListSlider_moreServicesTitle__301eY .ListSlider_sliderButtons__mUXs- {
      display: none
    }

@media (min-width: 768px) {

.ListSlider_moreServices__c5uyV .ListSlider_moreServicesTitle__301eY .ListSlider_sliderButtons__mUXs- {
        display: block
    }
      }

.ListSlider_moreServices__c5uyV .ListSlider_moreServicesTitle__301eY .ListSlider_sliderButtons__mUXs- .ListSlider_iconHolder__uREeY {
        background-color: #fff;
        border: 1px solid #ebebeb;
        width: 32px;
        height: 32px;
        border-radius: 100px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: all ease 0.5s
      }

@media (--viewportXXLarge) {

.ListSlider_moreServices__c5uyV .ListSlider_moreServicesTitle__301eY .ListSlider_sliderButtons__mUXs- .ListSlider_iconHolder__uREeY {
          width: 56px;
          height: 56px
      }
        }

.ListSlider_moreServices__c5uyV .ListSlider_moreServicesTitle__301eY .ListSlider_sliderButtons__mUXs- .ListSlider_iconHolder__uREeY:hover {
          border-color: #484848;
          cursor: pointer;
        }

.ListSlider_moreServices__c5uyV .ListSlider_moreServicesTitle__301eY .ListSlider_sliderButtons__mUXs- .ListSlider_iconHolder__uREeY:first-child {
          margin-right: 4px
        }

@media (--viewportXXLarge) {

.ListSlider_moreServices__c5uyV .ListSlider_moreServicesTitle__301eY .ListSlider_sliderButtons__mUXs- .ListSlider_iconHolder__uREeY:first-child {
            margin-right: 14px
        }
          }

.ListSlider_moreServices__c5uyV .ListSlider_moreServicesTitle__301eY .ListSlider_sliderButtons__mUXs- .ListSlider_iconHolder__uREeY svg {
          fill: none;
        }

.ListSlider_moreServices__c5uyV .ListSlider_cardSlider__1Ayd1 {
    /* hide scroll bar */
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;
    z-index: 0;
    -ms-scroll-snap-coordinate: 0 0;
        scroll-snap-coordinate: 0 0;
    -ms-scroll-snap-points-x: repeat(100%);
        scroll-snap-points-x: repeat(100%);
    -ms-scroll-snap-type: x mandatory;
        scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch
  }

.ListSlider_moreServices__c5uyV .ListSlider_cardSlider__1Ayd1::-webkit-scrollbar {
      display: none;
    }

@media(max-width: 767px) and (min-width: 581px) {

.ListSlider_moreServices__c5uyV .ListSlider_cardSlider__1Ayd1 {
      display: flex;
      flex-wrap: wrap;
      width: 100%
  }

      .ListSlider_moreServices__c5uyV .ListSlider_cardSlider__1Ayd1 .ListSlider_cardSliderItem__2wi7j {
        scroll-snap-align: start;
        width: calc(100% / 3 - 10px);
        margin-bottom: 15px;
        margin-right: 15px;
        scroll-snap-align: start
      }

        .ListSlider_moreServices__c5uyV .ListSlider_cardSlider__1Ayd1 .ListSlider_cardSliderItem__2wi7j:nth-child(3n) {
          margin-right: 0px;
        }

        .ListSlider_moreServices__c5uyV .ListSlider_cardSlider__1Ayd1 .ListSlider_cardSliderItem__2wi7j .ListSlider_imgWrapper__39S2v {
          height: 200px;
        }
    }

@media (max-width: 580px) {

.ListSlider_moreServices__c5uyV .ListSlider_cardSlider__1Ayd1 {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between
  }

      .ListSlider_moreServices__c5uyV .ListSlider_cardSlider__1Ayd1 .ListSlider_cardSliderItem__2wi7j {
        scroll-snap-align: start;
        width: calc(100% / 3 - 10px);
        margin-bottom: 15px;
        /* margin-right: 15px; */
        scroll-snap-align: start
      }

        .ListSlider_moreServices__c5uyV .ListSlider_cardSlider__1Ayd1 .ListSlider_cardSliderItem__2wi7j:nth-child(odd) {
          /* margin-right: 15px; */
        }

        .ListSlider_moreServices__c5uyV .ListSlider_cardSlider__1Ayd1 .ListSlider_cardSliderItem__2wi7j:nth-child(even) {
          /* margin-right: initial; */
        }

        .ListSlider_moreServices__c5uyV .ListSlider_cardSlider__1Ayd1 .ListSlider_cardSliderItem__2wi7j .ListSlider_imgWrapper__39S2v {
          height: 120px;
        }
    }

@media (max-width: 414px) {

.ListSlider_moreServices__c5uyV .ListSlider_cardSlider__1Ayd1 {
      display: flex;
      flex-wrap: wrap;
      width: 100%
  }

      .ListSlider_moreServices__c5uyV .ListSlider_cardSlider__1Ayd1 .ListSlider_cardSliderItem__2wi7j {
        width: calc(63% / 2 - 5px);
        margin-bottom: 10px
        /* margin-right: 10px; */
      }

        .ListSlider_moreServices__c5uyV .ListSlider_cardSlider__1Ayd1 .ListSlider_cardSliderItem__2wi7j:nth-child(odd) {
          /* margin-right: 10px; */
        }

        .ListSlider_moreServices__c5uyV .ListSlider_cardSlider__1Ayd1 .ListSlider_cardSliderItem__2wi7j:nth-child(even) {
          margin-right: 0;
          margin-right: initial;
        }

        .ListSlider_moreServices__c5uyV .ListSlider_cardSlider__1Ayd1 .ListSlider_cardSliderItem__2wi7j .ListSlider_imgWrapper__39S2v {
          height: 111px;
        }
    }

@media screen and (min-width: 320px) and (max-width: 370px) {
        .ListSlider_moreServices__c5uyV .ListSlider_cardSlider__1Ayd1 .ListSlider_cardSliderItem__2wi7j .ListSlider_imgWrapper__39S2v {
          height: 84px;
        }
    }

.ListSlider_moreServices__c5uyV .ListSlider_cardSlider__1Ayd1>div>div {
        overflow: hidden;
      }

.ListSlider_moreServices__c5uyV .ListSlider_cardSlider__1Ayd1>a:first-child {
      padding: 0 0 0 24px
    }

@media (min-width: 550px) {

.ListSlider_moreServices__c5uyV .ListSlider_cardSlider__1Ayd1>a:first-child {
        padding: 0
    }
      }

@media (max-width: 767px) {

.ListSlider_moreServices__c5uyV .ListSlider_recentlyAddedSlider__3F51u {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
      overflow-y: hidden
  }

      .ListSlider_moreServices__c5uyV .ListSlider_recentlyAddedSlider__3F51u .ListSlider_homepage__3-A5U {
        min-width: 260px;
        margin-right: 15px;
        scroll-snap-align: start
      }

        .ListSlider_moreServices__c5uyV .ListSlider_recentlyAddedSlider__3F51u .ListSlider_homepage__3-A5U:last-child {
          margin-right: 0px
        }

          .ListSlider_moreServices__c5uyV .ListSlider_recentlyAddedSlider__3F51u .ListSlider_homepage__3-A5U:last-child>a {
            margin-right: 0px;
          }

    }

.ListSlider_moreServicesTitle__301eY {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center
  /* padding: 0 0 0 24px; */
}

@media (min-width: 550px) {

.ListSlider_moreServicesTitle__301eY {
    padding: 0
}
  }

.ListSlider_moreServicesTitle__301eY>h1 {
    font-size: 28px;
    line-height: 1;
    letter-spacing: -1.12px;
  }

.ListSlider_authors__1ntaL {
  width: 250px;
  height: 250px;
  border-radius: 50%;
}

@media(max-width: 767px) and (min-width: 581px) {

.ListSlider_cardSliderItem__2wi7j.ListSlider_cardSliderItemMakes__H7TTv {
      scroll-snap-align: start;
      width: calc(100% / 3 - 10px);
      margin-bottom: 15px;
      /* margin-right: 15px; */
      scroll-snap-align: start
  }

      .ListSlider_cardSliderItem__2wi7j.ListSlider_cardSliderItemMakes__H7TTv:nth-child(3n) {
        margin-right: 0px;
      }
    }

@media (max-width: 580px) {

.ListSlider_cardSliderItem__2wi7j.ListSlider_cardSliderItemMakes__H7TTv {
      scroll-snap-align: start;
      width: calc(100% / 3 - 10px);
      margin-bottom: 15px;
      /* margin-right: 15px; */
      scroll-snap-align: start
  }

      .ListSlider_cardSliderItem__2wi7j.ListSlider_cardSliderItemMakes__H7TTv:nth-child(odd) {
        /* margin-right: 15px; */
      }

      .ListSlider_cardSliderItem__2wi7j.ListSlider_cardSliderItemMakes__H7TTv:nth-child(even) {
        /* margin-right: initial; */
      }
    }

@media (max-width: 414px) {

.ListSlider_cardSliderItem__2wi7j.ListSlider_cardSliderItemMakes__H7TTv {
      width: calc(100% / 3 - 5px);
      margin-bottom: 10px
      /* margin-right: 10px; */
  }

      .ListSlider_cardSliderItem__2wi7j.ListSlider_cardSliderItemMakes__H7TTv:nth-child(odd) {
        /* margin-right: 10px; */
      }

      .ListSlider_cardSliderItem__2wi7j.ListSlider_cardSliderItemMakes__H7TTv:nth-child(even) {
        margin-right: 0;
        margin-right: initial;
      }
    }

.ListSlider_cardSliderItem__2wi7j.ListSlider_cardSliderItemMakes__H7TTv>a>div {
        height: 100px !important;
        width: 100px !important;
        border-radius: 100%;
        margin: 0 auto
      }

@media screen and (min-width: 320px) and (max-width: 370px) {

.ListSlider_cardSliderItem__2wi7j.ListSlider_cardSliderItemMakes__H7TTv>a>div {
          height: 85px !important;
          width: 85px !important
      }
        }

@media (min-width:480px) {

.ListSlider_cardSliderItem__2wi7j.ListSlider_cardSliderItemMakes__H7TTv>a>div {
          height: 120px !important;
          width: 120px !important
      }
        }

@media (min-width:667px) {

.ListSlider_cardSliderItem__2wi7j.ListSlider_cardSliderItemMakes__H7TTv>a>div {
          height: 200px !important;
          width: 200px !important
      }
        }

@media (min-width:991px) {

.ListSlider_cardSliderItem__2wi7j.ListSlider_cardSliderItemMakes__H7TTv>a>div {
          height: 250px !important;
          width: 250px !important
      }
        }

.ListSlider_cardSliderItem__2wi7j.ListSlider_cardSliderItemMakes__H7TTv .ListSlider_imgWrapper__39S2v>img {
        object-fit: contain;
        padding: 0px 18px
      }

@media (min-width:667px) {

.ListSlider_cardSliderItem__2wi7j.ListSlider_cardSliderItemMakes__H7TTv .ListSlider_imgWrapper__39S2v>img {
          padding: 0px 18px
      }
        }

.ListSlider_cardSliderItem__2wi7j>a {
    display: inline-block;
    width: 100%;
    text-align: center
  }

.ListSlider_cardSliderItem__2wi7j>a:hover {
      text-decoration: none;
      transition: all ease 0.5s
    }

.ListSlider_cardSliderItem__2wi7j>a:hover .ListSlider_imgWrapper__39S2v {
        transition: all ease 0.5s
      }

.ListSlider_cardSliderItem__2wi7j>a:hover .ListSlider_imgWrapper__39S2v>img {
          -webkit-transform: scale(1.05);
                  transform: scale(1.05);
          transition: all ease 0.5s;
        }

.ListSlider_cardSliderItem__2wi7j>a .ListSlider_imgWrapper__39S2v {
      height: 250px;
      width: 100%;
      border: solid 1px #ececec;
      overflow: hidden;
      transition: all ease 0.5s;
      border-radius: 10px
    }

.ListSlider_cardSliderItem__2wi7j>a .ListSlider_imgWrapper__39S2v>img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        -webkit-transform: scale(1);
                transform: scale(1);
        transition: all ease 0.5s;
      }

.ListSlider_cardSliderItem__2wi7j>a .ListSlider_cardItemTitle__y1ZRe {
      font-size: 13px;
      font-weight: 600;
      margin-top: 10px;
      margin-bottom: 0;
      color: var(--marketplaceColorLight);
    }

@media (max-width:767px) {

.ListSlider_cardSliderItem__2wi7j.ListSlider_popularDesignerWrapper__1DKtz {
      min-width: 250px !important
  }
    }

.ListSlider_cardSliderItem__2wi7j.ListSlider_popularDesignerWrapper__1DKtz .ListSlider_popularDesignerImg__3wieA {
      width: 100%;
      height: 100%
    }

.ListSlider_cardSliderItem__2wi7j.ListSlider_popularDesignerWrapper__1DKtz .ListSlider_popularDesignerImg__3wieA>img {
        width: 100%;
        height: 100%;
      }

.ListSlider_makesImage__2Mhvp>img {
    max-width: 160px;
  }
.Button_root__2bb-J {
  font-family: 'Open Sans', sans-serif;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

@media (min-width: 768px) {

.Button_root__2bb-J {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.Button_root__2bb-J {
  border-radius: 4px;
  display: block;
  min-height: 45px;
  margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */

  padding: 8px 16px;
}

@media (min-width: 768px) {

.Button_root__2bb-J {
  padding: 10px 20px;
}
    }

.Button_root__2bb-J {

    /* Borders */
  border: none;
  border-radius: var(--borderRadius);

    /* Colors */
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

    /* Text */
  text-align: center;
  text-decoration: none;

    /* Effects */
  transition: all var(--transitionStyleButton);
  cursor: pointer;
}

.Button_root__2bb-J:hover,
    .Button_root__2bb-J:focus {
  outline: none;
  background-color: var(--marketplaceColorDark);
  text-decoration: none;
  box-shadow: var(--boxShadowButton);
    }

.Button_root__2bb-J:disabled {
  background-color: var(--matterColorNegative);
  color: var(--matterColorLight);
  cursor: not-allowed;
  box-shadow: none;
    }

@media (min-width: 768px) {

.Button_root__2bb-J {
  min-height: 50px;
}
    }

.Button_root__2bb-J {

  /* Clear padding that is set for link elements looking like buttons */
}

.Button_primaryButtonRoot__1kd3J {
  font-family: 'Open Sans', sans-serif;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

@media (min-width: 768px) {

.Button_primaryButtonRoot__1kd3J {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.Button_primaryButtonRoot__1kd3J {
  border-radius: 4px;
  display: block;
  min-height: 45px;
  margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */

  padding: 8px 16px;
}

@media (min-width: 768px) {

.Button_primaryButtonRoot__1kd3J {
  padding: 10px 20px;
}
    }

.Button_primaryButtonRoot__1kd3J {

    /* Borders */
  border: none;
  border-radius: var(--borderRadius);

    /* Colors */
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

    /* Text */
  text-align: center;
  text-decoration: none;

    /* Effects */
  transition: all var(--transitionStyleButton);
  cursor: pointer;
}

.Button_primaryButtonRoot__1kd3J:hover,
    .Button_primaryButtonRoot__1kd3J:focus {
  outline: none;
  background-color: var(--marketplaceColorDark);
  text-decoration: none;
  box-shadow: var(--boxShadowButton);
    }

.Button_primaryButtonRoot__1kd3J:disabled {
  background-color: var(--matterColorNegative);
  color: var(--matterColorLight);
  cursor: not-allowed;
  box-shadow: none;
    }

@media (min-width: 768px) {

.Button_primaryButtonRoot__1kd3J {
  min-height: 50px;
}
    }

.Button_primaryButtonRoot__1kd3J {

  background-color: #14100f;
  color: var(--matterColorLight);
}

.Button_primaryButtonRoot__1kd3J:hover {
  background-color: #14100f;
  color: var(--matterColorLight);
    }

.Button_primaryButtonRoot__1kd3J:focus {
  background-color: var(--successColorDark);
    }

.Button_primaryButtonRoot__1kd3J:disabled {
  background-color: var(--matterColorNegative);
  color: var(--matterColor);
    }

.Button_primaryButtonRoot__1kd3J {
  /* Clear padding that is set for link elements looking like buttons */
}

.Button_secondaryButtonRoot__2RvK9 {
  font-family: 'Open Sans', sans-serif;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

@media (min-width: 768px) {

.Button_secondaryButtonRoot__2RvK9 {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.Button_secondaryButtonRoot__2RvK9 {
  border-radius: 4px;
  display: block;
  min-height: 45px;
  margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */

  padding: 8px 16px;
}

@media (min-width: 768px) {

.Button_secondaryButtonRoot__2RvK9 {
  padding: 10px 20px;
}
    }

.Button_secondaryButtonRoot__2RvK9 {

    /* Borders */
  border: none;
  border-radius: var(--borderRadius);

    /* Colors */
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

    /* Text */
  text-align: center;
  text-decoration: none;

    /* Effects */
  transition: all var(--transitionStyleButton);
  cursor: pointer;
}

.Button_secondaryButtonRoot__2RvK9:hover,
    .Button_secondaryButtonRoot__2RvK9:focus {
  outline: none;
  background-color: var(--marketplaceColorDark);
  text-decoration: none;
  box-shadow: var(--boxShadowButton);
    }

.Button_secondaryButtonRoot__2RvK9:disabled {
  background-color: var(--matterColorNegative);
  color: var(--matterColorLight);
  cursor: not-allowed;
  box-shadow: none;
    }

@media (min-width: 768px) {

.Button_secondaryButtonRoot__2RvK9 {
  min-height: 50px;
}
    }

.Button_secondaryButtonRoot__2RvK9 {

  background-color: var(--matterColorLight);
  color: var(--matterColor);

    /* Padding is only for <a> elements where button styles are applied,
   buttons elements should have zero padding */
  padding: 18px;

  border-width: 1px;
  border-style: solid;
  border-color: var(--matterColorNegative);
}

.Button_secondaryButtonRoot__2RvK9:hover,
    .Button_secondaryButtonRoot__2RvK9:focus {
  background-color: var(--matterColorLight);
  border-color: var(--matterColorAnti);
    }

.Button_secondaryButtonRoot__2RvK9:disabled {
  background-color: var(--matterColorNegative);
  color: var(--matterColorLight);
  border-color: var(--matterColorNegative);
    }

.Button_secondaryButtonRoot__2RvK9 {

  /* We must lift up the text from the center since it looks better with
   the button border. Also clears the top padding set for link
   elements looking like buttons */
}

.Button_inlineTextButtonRoot__1Uh2a {
    /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

    /* Borders */
  border: none;

    /* Colors */
  color: var(--marketplaceColor);

    /* Text size should be inherited */
  text-decoration: none;
}

.Button_inlineTextButtonRoot__1Uh2a:hover {
  text-decoration: underline;
  cursor: pointer;
    }

.Button_primaryButton__1r13o {
  /* Class handle for primary button state styles */
}

.Button_secondaryButton__122dY {
  /* Class handle for secondary button state styles */
}

.Button_inProgress__dL7Js:disabled {
    background-color: var(--marketplaceColor);
  }

.Button_primaryButton__1r13o.Button_inProgress__dL7Js:disabled {
  background-color: var(--successColor);
}

.Button_secondaryButton__122dY.Button_inProgress__dL7Js:disabled {
  background-color: var(--matterColorLight);
}

.Button_ready__3ZlGl:disabled {
    background-color: var(--marketplaceColor);
  }

.Button_primaryButton__1r13o.Button_ready__3ZlGl:disabled {
  background-color: var(--successColor);
}

.Button_secondaryButton__122dY.Button_ready__3ZlGl:disabled {
  background-color: var(--matterColorLight);
}

.Button_spinner__1euk3 {
  width: 28px;
  height: 28px;
  stroke: var(--matterColorLight);
  stroke-width: 3px;
}

.Button_checkmark__2QW7p {
  width: 24px;
  height: 24px;
  stroke: var(--matterColorLight);

  /* Animation */
  -webkit-animation-name: Button_checkmarkAnimation__fGFOe;
          animation-name: Button_checkmarkAnimation__fGFOe;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-out;
          animation-timing-function: ease-out;
}

@-webkit-keyframes Button_checkmarkAnimation__fGFOe {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.75);
            transform: scale(0.75);
  }

  50% {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes Button_checkmarkAnimation__fGFOe {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.75);
            transform: scale(0.75);
  }

  50% {
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.Button_secondaryButton__122dY .Button_spinner__1euk3 {
  stroke: var(--matterColorAnti);
}

.Button_secondaryButton__122dY .Button_checkmark__2QW7p {
  stroke: var(--matterColorAnti);
}

/* Social logins && SSO buttons */

.Button_socialButtonRoot__34NCS {
  font-family: 'Open Sans', sans-serif;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px
}

@media (min-width: 768px) {

.Button_socialButtonRoot__34NCS {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.Button_socialButtonRoot__34NCS {
  border-radius: 4px;
  display: block;
  min-height: 45px;
  margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */

  padding: 8px 16px
}

@media (min-width: 768px) {

.Button_socialButtonRoot__34NCS {
  padding: 10px 20px
}
    }

.Button_socialButtonRoot__34NCS {

    /* Borders */
  border: none;
  border-radius: var(--borderRadius);

    /* Colors */
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

    /* Text */
  text-align: center;
  text-decoration: none;

    /* Effects */
  transition: all var(--transitionStyleButton);
  cursor: pointer
}

.Button_socialButtonRoot__34NCS:hover,
    .Button_socialButtonRoot__34NCS:focus {
  outline: none;
  background-color: var(--marketplaceColorDark);
  text-decoration: none;
  box-shadow: var(--boxShadowButton);
    }

.Button_socialButtonRoot__34NCS:disabled {
  background-color: var(--matterColorNegative);
  color: var(--matterColorLight);
  cursor: not-allowed;
  box-shadow: none;
    }

@media (min-width: 768px) {

.Button_socialButtonRoot__34NCS {
  min-height: 50px
}
    }

.Button_socialButtonRoot__34NCS {
  min-height: 48px;
  background-color: var(--matterColorLight);

  color: var(--matterColorDark);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;

  border: 1px solid #d2d2d2;
  border-radius: 4px;

  /* We need to add this to position the icon inside button */
  position: relative;
  width: 100%
}

@media (min-width: 768px) {

.Button_socialButtonRoot__34NCS {
    padding: 0
}
  }

.Button_socialButtonRoot__34NCS:hover,
  .Button_socialButtonRoot__34NCS:focus {
    background-color: var(--matterColorLight);
  }

.Button_socialButtonRoot__34NCS:disabled {
    background-color: var(--matterColorNegative);
  }

.Button_socialButton__3Rn-3 {}
.CookieConsent_root__1X_rb {
  /* Fixed on top of everything */
  position: fixed;
  bottom: 0;
  z-index: 1000;

  /* Dimensions */
  width: 100vw;
  padding: 8px 12px;

  /* Layout */
  display: flex;
  justify-content: center;
  align-items: center;

  /* Colors */
  background-color: var(--matterColor);
  color: var(--matterColorNegative)

  /* Cover Topbar on desktop */
}

@media (min-width: 768px) {

.CookieConsent_root__1X_rb {
    padding: 15px 24px
}
  }

.CookieConsent_message__1IzeN {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.CookieConsent_message__1IzeN {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.CookieConsent_message__1IzeN {
  margin: 0px;
  padding-right: 10px
}

@media (min-width: 768px) {

.CookieConsent_message__1IzeN {
    padding-right: 24px
}
  }

.CookieConsent_cookieLink__3p8g2 {
  color: var(--matterColorLight);
  border-bottom: 1px solid var(--matterColorNegative)
}

.CookieConsent_cookieLink__3p8g2:hover {
    text-decoration: none;
  }

.CookieConsent_continueBtn__I9CRs {
  /* Font */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.CookieConsent_continueBtn__I9CRs {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.CookieConsent_continueBtn__I9CRs {
  font-weight: var(--fontWeightSemiBold);

  /* Dimensions */
  padding: 3px 12px 3px 12px;
  margin: 0px;

  background-color: transparent;
  border: solid 1px var(--matterColorNegative);
  border-radius: 4px;
  cursor: pointer;

  transition: var(--transitionStyleButton)
}

.CookieConsent_continueBtn__I9CRs:hover {
    background-color: var(--matterColorNegative);
    color: var(--matterColor);
    transition: var(--transitionStyleButton);
  }
.ImageFromFile_root__85_n- {
  display: block;
  position: relative;
  width: 100%;
  margin: 0;
  overflow: hidden;
  background-color: var(--matterColorNegative); /* Loading BG color */
}

.ImageFromFile_aspectWrapper__3nHEH {
  padding-bottom: calc(100% * (2 / 3));
}

.ImageFromFile_rootForImage__262gm {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--borderRadius);
}

.ListingLink_longWord__AO8tP {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

.PaginationLinks_root__19CvH {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PaginationLinks_prev__1-vAG,
.PaginationLinks_next__2IY1v {
  display: block;

  /* Dimensions */
  padding-top: 26px;
  padding-bottom: 26px;

  /* Reset <a> tag sizing */
  line-height: 0;
  font-size: 0;
}

.PaginationLinks_prev__1-vAG {
  /* Minimum touch size 44px (svg width + padding) */
  padding-right: 33px;
}

.PaginationLinks_next__2IY1v {
  /* Minimum touch size 44px (svg width + padding) */
  padding-left: 33px;
}

.PaginationLinks_arrowIcon__2JtjP {
  /* Color for svg icons */
  fill: var(--marketplaceColor);
  stroke: var(--marketplaceColor)
}

.PaginationLinks_arrowIcon__2JtjP:hover {
    fill: var(--marketplaceColorDark);
    stroke: var(--marketplaceColorDark);
  }

.PaginationLinks_disabled__3UXW4,
.PaginationLinks_disabled__3UXW4:hover {
  fill: var(--matterColorAnti);
  stroke: var(--matterColorAnti);
}

.PaginationLinks_pageNumberList__1DGuD {
  display: flex;
  justify-content: space-between;
}

/**
 * Using 'justify-content: space-between;' we can deal with very narrow mobile screens.
 * However, since the length of pageNumberList can vary up to 7,
 * we need to keep track of how much space is allocated for the list
 * Maximum length of pageNumbersNavLinks is 7 (e.g. [1, '…', 4, 5, 6, '…', 9])
 */

.PaginationLinks_pageNumberList1Items__1xmxe {
  flex-basis: 30px;
}

.PaginationLinks_pageNumberList2Items__28aj_ {
  flex-basis: 60px;
}

.PaginationLinks_pageNumberList3Items__fdwE_ {
  flex-basis: 100px;
}

.PaginationLinks_pageNumberList4Items__1X8P9 {
  flex-basis: 160px;
}

.PaginationLinks_pageNumberList5Items__2BBGe {
  flex-basis: 200px;
}

.PaginationLinks_pageNumberList6Items__14-Mi {
  flex-basis: 250px;
}

.PaginationLinks_pageNumberList7Items__z4oe3 {
  flex-basis: 275px;
}

.PaginationLinks_toPageLink__IdSXF {
  /* Dimensions */
  padding: 21px 5px;

  /* Color for svg icons */
  color: var(--matterColor)
}

.PaginationLinks_toPageLink__IdSXF:hover {
    text-decoration: none;
  }

.PaginationLinks_currentPage__3SLCf {
  /* Dimensions */
  padding: 21px 5px 18px 5px;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-bottom-color: var(--matterColorDark);

  /* Color */
  color: var(--matterColorDark);
}

.PaginationLinks_paginationGap__1JLvd {
  /* Dimensions */
  padding: 21px 5px;

  /* Color for svg icons */
  color: var(--matterColor);

  /* Ellipsis is raised from baseline */
  line-height: 13px;
}

.LayoutWrapperFooter_root__3blJl {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;

  /* @media (max-width:767px) {
    position: relative;
  } */
}
.LayoutWrapperMain_root__3k0MQ {
  /* Expand to the full remaining width of the viewport */
  display: flex;
  flex-direction: column;
  width: 100%;
}

.LayoutWrapperSideNav_root__1Rp9f {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.LayoutWrapperSideNav_tabs__2POAn {
  display: flex;
  overflow-x: scroll;
  min-height: 48px;
  padding-top: 2px;
  padding-left: 2px
}

@media (min-width: 768px) {

.LayoutWrapperSideNav_tabs__2POAn {
    overflow-x: visible;
    overflow-x: initial;
    padding-left: 0px;
    min-height: 56px;
    padding-top: 10px
}
  }

@media (min-width: 768px) {

.LayoutWrapperSideNav_tabs__2POAn {
    min-height: auto;
    padding-top: 0
}
  }

.LayoutWrapperSideNav_tab__8dGpk {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 16px
}

.LayoutWrapperSideNav_tab__8dGpk:first-child {
    margin-left: 0;
  }
.LayoutWrapperTopbar_root__1lfP0 {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: var(--zIndexTopbar);
}

.LayoutSideNavigation_root__3M5Ym {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Container for side and content */

.LayoutSideNavigation_container__3R_jo {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: calc(1200px + 24px + 24px);
  margin: 30px auto;
  padding: 0 24px;
}

.LayoutSideNavigation_layoutWrapperMain__2nUxB {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: #fff;
  box-shadow: 0px 0px 4px #ddd;
}
.LayoutSingleColumn_root__1blLa {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.LayoutSingleColumn_layoutWrapperMain__zOvja {
  /* Use all available space parent element (aka root) can give and don't shrink content */
  flex-grow: 1;
  flex-shrink: 0;

  /* Expand to the full remaining width of the viewport */
  display: flex;
  flex-direction: column;
  width: 100%;
}

.MenuItem_root__EKhRf {
  height: 100%;
  width: 100%;
  margin: 0;
}

.MenuContent_root__sHt7h {
  position: absolute;
  z-index: var(--zIndexPopup);

  background-color: var(--matterColorLight);
  border-top: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowPopup);
  border-radius: 0 0 2px 2px;
  transition: var(--transitionStyleButton);
}

.MenuContent_isClosed__3ebSm {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.MenuContent_isOpen__3fkRW {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.MenuContent_content__2fffz {
  display: flex;
  flex-direction: column;
  margin: 0;
}

/* Styles for arrow (if arrowPosition is defined) */

.MenuContent_arrowTop__jwsll,
.MenuContent_arrowBelow__2TBsv {
  content: ' ';
  position: absolute;
  bottom: 100%;
  height: 0;
  width: 0;
  border: solid transparent;
  pointer-events: none;
}

.MenuContent_arrowTop__jwsll {
  border-bottom-color: var(--matterColorLight);
  border-width: 7px;
  margin-left: -7px;
}

.MenuContent_arrowBelow__2TBsv {
  border-bottom-color: var(--matterColorNegative);
  border-width: 9px;
  margin-left: -9px;
}

.MenuLabel_root__3TkmG {
  height: 100%;
  width: 100%;
  border: 0;
  cursor: pointer;
  background-color: transparent
}

.MenuLabel_root__3TkmG:hover {
    background-color: transparent;
    box-shadow: none;
  }

.MenuLabel_clickedWithMouse__31c0u:focus {
    outline: none;
  }

/* Default isOpen class - no styles yet */

.MenuLabel_isOpen__8HQrj {
}

.Menu_root__2DIg_ {
  width: auto;
  height: 100%;
  position: relative;
  outline: none;
}

/* Content is visible as modal layer */

.Modal_isOpen__2CBZc {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100

  /* scrollLayer is the .root for the Modal */
}

.Modal_isOpen__2CBZc .Modal_scrollLayer__3SKWM {
    /* Page is using flex: AuthenticationPage's .root takes all available space */
  flex-grow: 1;

    /* AuthenticationPage's root uses flexbox */
  display: flex
  }

@media (min-width: 768px) {

.Modal_isOpen__2CBZc .Modal_scrollLayer__3SKWM {
  justify-content: center;
  align-items: flex-start
  }
    }

.Modal_isOpen__2CBZc .Modal_scrollLayer__3SKWM {

    /* Add default background color to avoid bouncing scroll showing the
   page contents from behind the modal. */
    background-color: var(--matterColorLight);

    /* Additional styles for the modal window, dimming the background and positioning the modal */
    min-height: 100vh;
    overflow: auto;
    -webkit-overflow-scrolling: touch
  }

@media (min-width: 768px) {

.Modal_isOpen__2CBZc .Modal_scrollLayer__3SKWM {
      padding: 0;
      background-color: rgba(0, 0, 0, 0.4);
      background-image: none
  }
    }

.Modal_isOpen__2CBZc .Modal_container__1LvOf {
  flex-grow: 1;

    /* Create context so the close button can be positioned within it */
  position: relative;

    /* Display and dimensions */
  display: flex;
  flex-direction: column;
  padding: var(--modalPadding);

  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius);
  border-bottom: none
  }

@media (min-width: 768px) {

.Modal_isOpen__2CBZc .Modal_container__1LvOf {
  flex-basis: 480px;
  flex-grow: 0;
      /* min-height: 568px; */
  padding: var(--modalPaddingMedium);
  margin-top: 12.5vh;
  margin-bottom: 12.5vh;
  border-bottom: 8px solid var(--marketplaceColor)
  }
    }

.Modal_isOpen__2CBZc .Modal_container__1LvOf {
    min-height: 100vh;
    height: 100%
  }

@media (min-width: 768px) {

.Modal_isOpen__2CBZc .Modal_container__1LvOf {
      flex-basis: 576px;
      min-height: auto;
      height: auto
  }
    }

.Modal_content__1VIbZ {
}

/* Content is explicitly hidden (this default can be overridden with passed-in class) */

/* The use case for having both .isOpen and .isClosed is ModalInMobile use case       */

/* where desktop layout should not get any styling from Modal component.              */

.Modal_isClosed__1pEDH {
  display: none;
}

.Modal_close__3rW_Y {
    /* Position inside modal */
  position: fixed;
  top: 0;
  right: 0;

    /* Some content (like map) is positioning itself on top of close button without z-index handling */
  z-index: calc(var(--zIndexModal) + 1);

    /* Safari didn't know how to baseline-align svg icon and text */
  display: flex;
  align-items: flex-start;
  width: auto;

    /* Sizing (touch area) */
  padding: 24px;
  margin: 0;
  border: 0;
}

@media (min-width: 768px) {

.Modal_close__3rW_Y {
  padding: 27px 30px;
  position: absolute;
}
    }

.Modal_close__3rW_Y {

    /* Colors */
  background-color: transparent;
  color: var(--matterColor);

  cursor: pointer;
}

.Modal_close__3rW_Y:enabled:hover {
  background-color: transparent;
  color: var(--matterColorDark);
  box-shadow: none;
  text-decoration: none;
    }

.Modal_close__3rW_Y:enabled:active {
  background-color: transparent;
  color: var(--matterColorDark);
    }

.Modal_close__3rW_Y:disabled {
  background-color: transparent;
    }

.Modal_closeText__2umub {
    /* Font */
  font-weight: var(--fontWeightBold);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}

@media (min-width: 768px) {

.Modal_closeText__2umub {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 6px;
}
    }

.Modal_closeText__2umub {
  margin: 0;
}

@media (min-width: 768px) {

.Modal_closeText__2umub {
  margin: 0;
}
    }

.Modal_closeIcon__3xOgs {
  display: inline-block;
  margin-left: 8px;

    /* Move X icon vertically to align it with the close text. */
  margin-top: 3px;

  box-sizing: content-box;
}

@media (min-width: 768px) {

.Modal_closeIcon__3xOgs {
  margin-top: 2px;
}
    }

.Modal_closeLight__3myzy {
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton)
}

.Modal_closeLight__3myzy:enabled:hover,
  .Modal_closeLight__3myzy:enabled:active {
    color: var(--matterColorLight);
  }

.Modal_focusedDiv__33cmN:focus {
    outline: none;
  }

/* ModalInMobile is visible by default */

.ModalInMobile_root__1BNqB {
  width: 100%;
}

/* Content is hidden in Mobile layout */

.ModalInMobile_modalHidden__RxosJ {
  display: none;
}

.ModalInMobile_modalContainer__1CPnF {
  flex-grow: 1;

    /* Create context so the close button can be positioned within it */
  position: relative;

    /* Display and dimensions */
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0;

  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius)
}

@media (min-width: 768px) {

.ModalInMobile_modalContainer__1CPnF {
  flex-basis: 480px;
  flex-grow: 0;
  height: 100%;
  padding: 0;
  background-color: transparent;
  border-radius: 0
}
    }

@media (min-width: 768px) {

.ModalInMobile_modalContainer__1CPnF {
    flex-basis: 576px
}
  }

.ModalInMobile_modalContent__9dQK9 {
  width: 100%
}

@media (min-width: 768px) {

.ModalInMobile_modalContent__9dQK9 {
    height: 100%;

    /* ModalInMobile content should work with box-shadows etc. */
    overflow: visible
}
  }

.FieldBirthdayInput_fieldRoot__1mH_e {
}

.FieldBirthdayInput_inputRoot__1hGnr {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.FieldBirthdayInput_selectWrapper__1GToX {
  flex-basis: calc(33% - 12px);
}

.FieldBirthdayInput_select__2kTsV {
  display: inline-block;

  /* Border */
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: var(--attentionColor)
}

.FieldBirthdayInput_select__2kTsV:hover,
  .FieldBirthdayInput_select__2kTsV:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

.FieldBirthdayInput_notSet__3qa-K {
  color: var(--matterColorAnti);
}

.FieldBirthdayInput_selectSuccess__1XW5r {
  color: var(--matterColor);
  border-bottom-color: var(--successColor);
}

.FieldBirthdayInput_selectError__2mGlT {
  border-bottom-color: var(--failColor);
}

.FieldCheckbox_root__3zj8N {
  position: relative;
}

.FieldCheckbox_input__mLqZ5 {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0

  /* Highlight the borders if the checkbox is hovered, focused or checked */
}

.FieldCheckbox_input__mLqZ5:hover + label .FieldCheckbox_box__2sdbR,
  .FieldCheckbox_input__mLqZ5:focus + label .FieldCheckbox_box__2sdbR,
  .FieldCheckbox_input__mLqZ5:checked + label .FieldCheckbox_box__2sdbR {
    stroke: var(--marketplaceColor);
  }

.FieldCheckbox_input__mLqZ5 {

  /* successColor version */
}

.FieldCheckbox_input__mLqZ5:hover + label .FieldCheckbox_boxSuccess__1ja_d,
  .FieldCheckbox_input__mLqZ5:focus + label .FieldCheckbox_boxSuccess__1ja_d,
  .FieldCheckbox_input__mLqZ5:checked + label .FieldCheckbox_boxSuccess__1ja_d {
    stroke: var(--successColor);
  }

.FieldCheckbox_input__mLqZ5 {

  /* Display the "check" when checked */
}

.FieldCheckbox_input__mLqZ5:checked + label .FieldCheckbox_checked__cE7fY {
    display: inline;
    stroke: var(--marketplaceColor);
    stroke-width: 1px;
  }

.FieldCheckbox_input__mLqZ5 {

  /* Display the "check" when checked */
}

.FieldCheckbox_input__mLqZ5:checked + label .FieldCheckbox_checkedSuccess__TRRjK {
    display: inline;
    stroke: var(--successColor);
    stroke-width: 1px;
  }

.FieldCheckbox_input__mLqZ5 {

  /* Hightlight the text on checked, hover and focus */
}

.FieldCheckbox_input__mLqZ5:focus + label .FieldCheckbox_text__1f3zE,
  .FieldCheckbox_input__mLqZ5:hover + label .FieldCheckbox_text__1f3zE,
  .FieldCheckbox_input__mLqZ5:checked + label .FieldCheckbox_text__1f3zE {
    color: var(--matterColorDark);
  }

.FieldCheckbox_label__IMcLm {
  display: flex;
  align-items: center;
  padding: 0;
}

.FieldCheckbox_checkboxWrapper__1s98A {
  height: 32px;
  margin-top: -1px;
  margin-right: 12px;
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer
}

@media (min-width: 768px) {

.FieldCheckbox_checkboxWrapper__1s98A {
    height: 24px
}
  }

.FieldCheckbox_checked__cE7fY {
  display: none;
  fill: var(--marketplaceColor);
}

.FieldCheckbox_checkedSuccess__TRRjK {
  display: none;
  fill: var(--successColor);
}

.FieldCheckbox_boxSuccess__1ja_d,
.FieldCheckbox_box__2sdbR {
  stroke: var(--matterColorAnti);
}

.FieldCheckbox_text__1f3zE {
}

.FieldCheckbox_textRoot__1FOIz {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  color: var(--matterColor);
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}

.FieldCurrencyInput_input__3XhPL {
  border: solid 1px;
  border-color: #dcdcdc;
}

.FieldCurrencyInput_inputSuccess__1mHn2 {
  border-color: var(--successColor);
}

.FieldCurrencyInput_inputError__3BbqG {
  border-color: var(--failColor);
}
.DateInput_inputRoot__TkZ_w {
  /* Contain repainting to this component only */
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0)

  /* Override react-dates default styles to match input styles */
}

.DateInput_inputRoot__TkZ_w .SingleDatePicker {
    display: block;
  }

.DateInput_inputRoot__TkZ_w .SingleDatePicker_picker__directionLeft {
    /* !important is added to top because react-dates uses inline style for height */
    /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
    top: 36px !important;
    width: 100%;
    min-height: calc(100vh - 252px);
    background-color: var(--marketplaceColor)
  }

@media (min-width: 768px) {

.DateInput_inputRoot__TkZ_w .SingleDatePicker_picker__directionLeft {
      /* !important is added to top because react-dates uses inline style for height */
      /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
      top: 40px !important;
      min-height: auto;
      box-shadow: var(--boxShadowPopup);
      border-radius: 0 0 2px 2px
  }
    }

.DateInput_inputRoot__TkZ_w .DateInput_input {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px
    /* No margins for default font */
  }

@media (min-width: 768px) {

.DateInput_inputRoot__TkZ_w .DateInput_input {
  font-size: 16px;
  line-height: 32px
  }
    }

.DateInput_inputRoot__TkZ_w .DateInput_input {
    /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;
  padding: 6px 15px;

    /* Borders */
  border: solid 1px #dcdcdc;
  border-radius: 3px
  }

.DateInput_inputRoot__TkZ_w .DateInput_input::-webkit-input-placeholder {
  color: var(--matterColorAnti);
    }

.DateInput_inputRoot__TkZ_w .DateInput_input:-ms-input-placeholder {
  color: var(--matterColorAnti);
    }

.DateInput_inputRoot__TkZ_w .DateInput_input::placeholder {
  color: var(--matterColorAnti);
    }

.DateInput_inputRoot__TkZ_w .DateInput_input {

    /* Effects */

  transition: border-color var(--transitionStyle)
  }

.DateInput_inputRoot__TkZ_w .DateInput_input:hover,
    .DateInput_inputRoot__TkZ_w .DateInput_input:focus {
  border-color: var(--matterColor);
  outline: none;
    }

.DateInput_inputRoot__TkZ_w .DateInput_input:disabled {
  color: var(--matterColorAnti);
  border-color: var(--matterColorAnti);
    }

.DateInput_inputRoot__TkZ_w .DateInput_input {
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: var(--attentionColor);
    transition: border-bottom-color var(--transitionStyle);
    transition: all 0.15s ease-out;
    background: none
  }

@media (min-width: 768px) {

.DateInput_inputRoot__TkZ_w .DateInput_input {
      padding: 4px 0 9px 0;
      border-bottom-width: 3px
  }
    }

.DateInput_inputRoot__TkZ_w .SingleDatePickerInput {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    background: none;
  }

.DateInput_inputRoot__TkZ_w .DayPicker__horizontal {
    margin: 0 auto;
    background-color: var(--marketplaceColor);
    box-shadow: none;
  }

.DateInput_inputRoot__TkZ_w .DayPickerNavigation__horizontal {
    position: relative;
    width: 100%;
  }

.DateInput_inputRoot__TkZ_w .DayPickerNavigation_button__horizontal {
    padding: 6px 9px;
    top: 16px;
    position: absolute;
    cursor: pointer;
    display: inline
  }

.DateInput_inputRoot__TkZ_w .DayPickerNavigation_button__horizontal:first-of-type {
      left: 24px;
    }

.DateInput_inputRoot__TkZ_w .DayPickerNavigation_button__horizontal:last-of-type {
      right: 24px;
    }

.DateInput_inputRoot__TkZ_w .DayPickerNavigation_button {
    color: var(--matterColorLight);
    border: 0;
  }

.DateInput_inputRoot__TkZ_w .CalendarMonth,
  .DateInput_inputRoot__TkZ_w .CalendarMonthGrid {
    background-color: transparent;
  }

.DateInput_inputRoot__TkZ_w .DateInput {
    display: block;
    width: 100%;
  }

.DateInput_inputRoot__TkZ_w .DayPicker_weekHeader {
    color: var(--matterColorLight);
    top: 57px;
  }

.DateInput_inputRoot__TkZ_w .DayPicker_weekHeader_li {
    font-weight: 400;
  }

.DateInput_inputRoot__TkZ_w .CalendarMonth_caption {
    color: var(--matterColorLight);
  font-weight: var(--fontWeightSemiBold);
  font-size: 21px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 21px;
  margin-bottom: 17px
  }

@media (min-width: 768px) {

.DateInput_inputRoot__TkZ_w .CalendarMonth_caption {
  line-height: 32px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 21px;
  margin-bottom: 19px
  }
    }

.DateInput_inputRoot__TkZ_w .CalendarMonth_caption {
    margin: 1px 0 14px;
    font-weight: 400
  }

@media (min-width: 768px) {

.DateInput_inputRoot__TkZ_w .CalendarMonth_caption {
      margin-top: 0;
      margin-bottom: 0
  }
    }

.DateInput_inputRoot__TkZ_w .CalendarDay__default {
    background-color: var(--marketplaceColor);
    border: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

.DateInput_inputRoot__TkZ_w .CalendarDay {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
  }

@media (min-width: 768px) {

.DateInput_inputRoot__TkZ_w .CalendarDay {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
  }
    }

.DateInput_inputRoot__TkZ_w .CalendarDay {
    color: var(--matterColorLight);
    border: 0;
    margin-top: 0;
    margin-bottom: 0
  }

@media (min-width: 768px) {

.DateInput_inputRoot__TkZ_w .CalendarDay {
      margin-top: 0;
      margin-bottom: 0
  }
    }

.DateInput_inputRoot__TkZ_w {

  /* Add an underline for '.renderedDay' */
}

.DateInput_inputRoot__TkZ_w .CalendarDay__today .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%23FFF' fill-rule='evenodd'/></svg>");
    background-position: center 28px;
  }

.DateInput_inputRoot__TkZ_w {

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateInput_inputRoot__TkZ_w .CalendarDay__hovered_span,
  .DateInput_inputRoot__TkZ_w .CalendarDay__selected_span {
    background-image: transparent;
    background-color: transparent;
  }

.DateInput_inputRoot__TkZ_w .CalendarDay__hovered_span .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--DateInput_hoveredOverlayColor);
  }

.DateInput_inputRoot__TkZ_w .CalendarDay__selected_span .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--successColor);
    transition: all 0.2s ease-out;
  }

.DateInput_inputRoot__TkZ_w {
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateInput_inputRoot__TkZ_w .CalendarDay__selected {
    background-color: transparent;
    background-image: none;
  }

.DateInput_inputRoot__TkZ_w .CalendarDay__selected .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--successColor);
    border-radius: calc(var(--DateInput_selectionHeight) / 2);
  }

.DateInput_inputRoot__TkZ_w {
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateInput_inputRoot__TkZ_w .CalendarDay__after-hovered {
    background-color: transparent;
  }

.DateInput_inputRoot__TkZ_w .CalendarDay__after-hovered .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--DateInput_hoveredOverlayColor);
  }

.DateInput_inputRoot__TkZ_w .CalendarDay:hover .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateInput_selectionHeight);
    background-color: var(--DateInput_hoveredOverlayColor);
  }

.DateInput_inputRoot__TkZ_w {
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateInput_inputRoot__TkZ_w .CalendarDay__blocked_out_of_range,
  .DateInput_inputRoot__TkZ_w .CalendarDay__blocked_out_of_range:active,
  .DateInput_inputRoot__TkZ_w .CalendarDay__blocked_out_of_range:hover {
    background-color: transparent;
    color: var(--marketplaceColorDark);
    border: 0;
  }

.DateInput_inputRoot__TkZ_w {
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateInput_inputRoot__TkZ_w .CalendarDay__blocked_calendar,
  .DateInput_inputRoot__TkZ_w .CalendarDay__blocked_calendar:active,
  .DateInput_inputRoot__TkZ_w .CalendarDay__blocked_calendar:hover {
    background-color: transparent;
    color: var(--marketplaceColorDark);
    border: 0;
  }

.DateInput_inputRoot__TkZ_w .CalendarDay__blocked_out_of_range .CalendarDay__blocked_calendar .renderedDay {
    background-color: transparent;
  }

.DateInput_inputRoot__TkZ_w .DateInput_fang {
    display: none;
  }

.DateInput_inputRoot__TkZ_w .CalendarMonth_caption {
    text-transform: capitalize;
  }

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */

.DateInput_withMobileMargins__pwMsF .SingleDatePickerInput {
    width: calc(100vw - 48px);
    margin: 0 24px
  }

@media (min-width: 768px) {

.DateInput_withMobileMargins__pwMsF .SingleDatePickerInput {
      width: 100%;
      margin: 0
  }
    }

.DateInput_withMobileMargins__pwMsF .SingleDatePicker_picker__directionLeft {
    /* !important is added to top because react-dates uses inline style for height */
    /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
    top: 34px !important;
    border-top: 2px solid var(--matterColorDark);
    min-height: calc(100vh - 252px + 2px)
  }

@media (min-width: 768px) {

.DateInput_withMobileMargins__pwMsF .SingleDatePicker_picker__directionLeft {
      /* !important is added to top because react-dates uses inline style for height */
      /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
      top: 40px !important;
      border-top: 0;
      min-height: auto
  }
    }

.DateInput_withMobileMargins__pwMsF {

  /* Create gutter between inputs */
}

.DateInput_withMobileMargins__pwMsF .DateInput {
    width: 100%;
    background: none;
  }

.DateInput_rootNextMonthIcon__1O8hc,
.DateInput_rootPreviousMonthIcon__2UNZG {
  stroke: var(--matterColorLight);
  fill: var(--matterColorLight);
}

.FieldDateInput_fieldRoot__bDeH0 {
}

.FieldDateInput_pickerSuccess__3Jy1Z .DateInput_input {
    border-bottom-color: var(--successColor);
  }

.FieldDateInput_pickerError__16vjr .DateInput_input {
    border-bottom-color: var(--failColor);
  }

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */

.FieldDateInput_mobileMargins__1piyD {
  width: calc(100% - 48px);
  margin: 0 24px
}

@media (min-width: 768px) {

.FieldDateInput_mobileMargins__1piyD {
    width: 100%;
    margin: 0
}
  }

.DateRangeController_inputRoot__2LsVh {
  /*
    Calendar component using react-dates has automatically padding so
    negative margin to left and right is needed for element to fit on smaller screens.
  */

  margin: 0px -20px
}

@media (min-width: 768px) {

.DateRangeController_inputRoot__2LsVh {
    margin: 0
}
  }

.DateRangeController_inputRoot__2LsVh .CalendarMonthGrid {
    background-color: transparent;
  }

.DateRangeController_inputRoot__2LsVh .DayPicker__horizontal {
    margin: 0 auto;
    box-shadow: none;
    background-color: transparent;
  }

.DateRangeController_inputRoot__2LsVh .DayPickerNavigation__horizontal {
    position: relative;
    width: 100%;
  }

.DateRangeController_inputRoot__2LsVh .DayPickerNavigation__bottom {
    display: none
  }

.DateRangeController_inputRoot__2LsVh .DayPickerNavigation__bottom:first-of-type {
      display: block;
    }

.DateRangeController_inputRoot__2LsVh .DayPickerNavigation_button__horizontal {
    padding: 6px 9px;
    top: 21px;
    position: absolute;
    cursor: pointer;
    display: inline
  }

.DateRangeController_inputRoot__2LsVh .DayPickerNavigation_button__horizontal:first-of-type {
      left: 24px;
    }

.DateRangeController_inputRoot__2LsVh .DayPickerNavigation_button__horizontal:last-of-type {
      right: 24px;
    }

@media (min-width: 768px) {

.DateRangeController_inputRoot__2LsVh .DayPickerNavigation_button__horizontal {
      top: 18px
  }
    }

.DateRangeController_inputRoot__2LsVh .DayPickerNavigation_button {
    color: var(--matterColor);
    border: 0;
  }

.DateRangeController_inputRoot__2LsVh .CalendarMonth,
  .DateRangeController_inputRoot__2LsVh .CalendarMonthGrid {
    background-color: transparent;
  }

.DateRangeController_inputRoot__2LsVh .DayPicker_weekHeader {
    color: var(--matterColor);
    top: 62px;
  }

.DateRangeController_inputRoot__2LsVh .DayPicker_weekHeader_li {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
  }

@media (min-width: 768px) {

.DateRangeController_inputRoot__2LsVh .DayPicker_weekHeader_li {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
  }
    }

.DateRangeController_inputRoot__2LsVh .DayPicker_weekHeader_li {

    margin-top: 0;
    margin-bottom: 0
  }

@media (min-width: 768px) {

.DateRangeController_inputRoot__2LsVh .DayPicker_weekHeader_li {
      margin-top: 0;
      margin-bottom: 0
  }
    }

.DateRangeController_inputRoot__2LsVh .DayPicker_weekHeader_li small {
    font-size: 100%;
  }

.DateRangeController_inputRoot__2LsVh .CalendarMonth_caption {
    color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 16px;
  margin-bottom: 14px
  }

@media (min-width: 768px) {

.DateRangeController_inputRoot__2LsVh .CalendarMonth_caption {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 16px;
  margin-bottom: 16px
  }
    }

.DateRangeController_inputRoot__2LsVh .CalendarMonth_caption {
    margin: 1px 0 14px;
    font-weight: 400;
    line-height: 20px;
    padding-top: 31px;
    padding-bottom: 37px
  }

.DateRangeController_inputRoot__2LsVh .CalendarMonth_caption::first-letter {
      text-transform: capitalize;
    }

@media (min-width: 768px) {

.DateRangeController_inputRoot__2LsVh .CalendarMonth_caption {
      margin-top: 0;
      margin-bottom: 0
  }
    }

.DateRangeController_inputRoot__2LsVh .CalendarDay__default {
    border: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background: transparent;
  }

.DateRangeController_inputRoot__2LsVh .CalendarDay {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
  }

@media (min-width: 768px) {

.DateRangeController_inputRoot__2LsVh .CalendarDay {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
  }
    }

.DateRangeController_inputRoot__2LsVh .CalendarDay {
    color: var(--matterColor);
    border: 0;
    margin-top: 0;
    margin-bottom: 0
  }

@media (min-width: 768px) {

.DateRangeController_inputRoot__2LsVh .CalendarDay {
      margin-top: 0;
      margin-bottom: 0
  }
    }

.DateRangeController_inputRoot__2LsVh {

  /* Add an underline for '.renderedDay' */
}

.DateRangeController_inputRoot__2LsVh .CalendarDay__today .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeController_selectionHeight);
    background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%234A4A4A' fill-rule='evenodd'/></svg>");
    background-position: center 28px;
  }

.DateRangeController_inputRoot__2LsVh .CalendarDay__today.CalendarDay__selected .renderedDay {
    background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%23FFF' fill-rule='evenodd'/></svg>");
  }

.DateRangeController_inputRoot__2LsVh .CalendarDay:hover .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeController_selectionHeight);
    background-color: var(--matterColorNegative);
    color: var(--matterColor);
  }

.DateRangeController_inputRoot__2LsVh {

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateRangeController_inputRoot__2LsVh .CalendarDay__hovered_span,
  .DateRangeController_inputRoot__2LsVh .CalendarDay__selected_span {
    background-image: transparent;
    background-color: transparent;
  }

.DateRangeController_inputRoot__2LsVh .CalendarDay__hovered_span .renderedDay,
  .DateRangeController_inputRoot__2LsVh .CalendarDay__selected_span .renderedDay,
  .DateRangeController_inputRoot__2LsVh .CalendarDay__hovered_span:hover .renderedDay,
  .DateRangeController_inputRoot__2LsVh .CalendarDay__selected_span:hover .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeController_selectionHeight);
    background-color: var(--successColor);
    color: var(--matterColorLight);
  }

.DateRangeController_inputRoot__2LsVh {

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateRangeController_inputRoot__2LsVh .CalendarDay__selected_start {
    background-color: transparent;
    background-image: none;
  }

.DateRangeController_inputRoot__2LsVh .CalendarDay__selected_start .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeController_selectionHeight);
    background-color: var(--successColor);
    color: var(--matterColorLight);
    border-top-left-radius: calc(var(--DateRangeController_selectionHeight) / 2);
    border-bottom-left-radius: calc(var(--DateRangeController_selectionHeight) / 2);
  }

.DateRangeController_inputRoot__2LsVh {

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateRangeController_inputRoot__2LsVh .CalendarDay__selected_end {
    background-color: transparent;
  }

.DateRangeController_inputRoot__2LsVh .CalendarDay__selected_end .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeController_selectionHeight);
    background-color: var(--successColor);
    color: var(--matterColorLight);
    border-top-right-radius: calc(var(--DateRangeController_selectionHeight) / 2);
    border-bottom-right-radius: calc(var(--DateRangeController_selectionHeight) / 2);
  }

.DateRangeController_inputRoot__2LsVh .CalendarDay:hover.CalendarDay__selected_start .renderedDay,
  .DateRangeController_inputRoot__2LsVh .CalendarDay:hover.CalendarDay__selected_span .renderedDay,
  .DateRangeController_inputRoot__2LsVh .CalendarDay:hover.CalendarDay__selected_end .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeController_selectionHeight);
    background-color: var(--successColor);
    color: var(--matterColorLight);
  }

.DateRangeController_inputRoot__2LsVh .CalendarDay__selected_span .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeController_selectionHeight);
    background-color: var(--successColor);
    color: var(--matterColorLight);
    transition: all 0.2s ease-out;
  }

.DateRangeController_inputRoot__2LsVh {

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateRangeController_inputRoot__2LsVh .CalendarDay__blocked_out_of_range .renderedDay,
  .DateRangeController_inputRoot__2LsVh .CalendarDay__blocked_out_of_range:hover .renderedDay {
    background-color: transparent;
    color: var(--matterColorAnti);
    text-decoration: line-through;
    border: 0;
  }

.DateRangeController_arrowIcon__y1eN- {
  stroke: var(--marketplaceColor);
  fill: var(--marketplaceColor);
}

.DateRangeInput_inputRoot__1eTO- {
  /* Contain repainting to this component only */
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0)

  /* Override react-dates default styles to match input styles */
}

.DateRangeInput_inputRoot__1eTO- .DateRangePicker {
    display: block;
  }

.DateRangeInput_inputRoot__1eTO- .DateRangePicker_picker__directionLeft {
    /* !important is added to top because react-dates uses inline style for height */
    /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
    top: 36px !important;
    width: 100%;
    min-height: calc(100vh - 252px);
    background-color: var(--marketplaceColor)
  }

@media (min-width: 768px) {

.DateRangeInput_inputRoot__1eTO- .DateRangePicker_picker__directionLeft {
      /* !important is added to top because react-dates uses inline style for height */
      /* Similar problem as in issue: https://github.com/airbnb/react-dates/issues/947 */
      top: 42px !important;
      min-height: auto;
      box-shadow: var(--boxShadowPopup);
      border-radius: 0 0 2px 2px
  }
    }

.DateRangeInput_inputRoot__1eTO- .DateInput_input {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
  }

@media (min-width: 768px) {

.DateRangeInput_inputRoot__1eTO- .DateInput_input {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
  }
    }

.DateRangeInput_inputRoot__1eTO- .DateInput_input {
    padding: 0;
    margin: 0;
    border: 0;
    background: none;
  }

.DateRangeInput_inputRoot__1eTO- .DateRangePickerInput {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: none;
    background: none;
  }

.DateRangeInput_inputRoot__1eTO- .DayPicker__horizontal {
    margin: 0 auto;
    background-color: var(--marketplaceColor);
    box-shadow: none;
  }

.DateRangeInput_inputRoot__1eTO- .DayPickerNavigation__horizontal {
    position: relative;
    width: 100%;
  }

.DateRangeInput_inputRoot__1eTO- .DayPickerNavigation_button__horizontal {
    padding: 6px 9px;
    top: 16px;
    position: absolute;
    cursor: pointer;
    display: inline
  }

.DateRangeInput_inputRoot__1eTO- .DayPickerNavigation_button__horizontal:first-of-type {
      left: 24px;
    }

.DateRangeInput_inputRoot__1eTO- .DayPickerNavigation_button__horizontal:last-of-type {
      right: 24px;
    }

.DateRangeInput_inputRoot__1eTO- .DayPickerNavigation_button {
    color: var(--matterColorLight);
    border: 0;
  }

.DateRangeInput_inputRoot__1eTO- .CalendarMonth,
  .DateRangeInput_inputRoot__1eTO- .CalendarMonthGrid {
    background-color: transparent;
  }

.DateRangeInput_inputRoot__1eTO- .DateInput {
    display: block;
    width: 100%;
    padding: 5px 0 2px 0
  }

@media (min-width: 768px) {

.DateRangeInput_inputRoot__1eTO- .DateInput {
      padding: 6px 0 9px 0
  }
    }

.DateRangeInput_inputRoot__1eTO- .DayPicker_weekHeader {
    color: var(--matterColorLight);
    top: 57px;
  }

.DateRangeInput_inputRoot__1eTO- .DayPicker_weekHeader_li {
    font-weight: 400;
  }

.DateRangeInput_inputRoot__1eTO- .DayPicker__withBorder {
    border-radius: 0;
  }

.DateRangeInput_inputRoot__1eTO- .CalendarMonth_caption {
    color: var(--matterColorLight);
  font-weight: var(--fontWeightSemiBold);
  font-size: 21px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 21px;
  margin-bottom: 17px
  }

@media (min-width: 768px) {

.DateRangeInput_inputRoot__1eTO- .CalendarMonth_caption {
  line-height: 32px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 21px;
  margin-bottom: 19px
  }
    }

.DateRangeInput_inputRoot__1eTO- .CalendarMonth_caption {
    margin: 1px 0 14px;
    font-weight: 400
  }

@media (min-width: 768px) {

.DateRangeInput_inputRoot__1eTO- .CalendarMonth_caption {
      margin-top: 0;
      margin-bottom: 0
  }
    }

.DateRangeInput_inputRoot__1eTO- .CalendarDay__default {
    background-color: var(--marketplaceColor);
    border: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

.DateRangeInput_inputRoot__1eTO- .CalendarDay {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
  }

@media (min-width: 768px) {

.DateRangeInput_inputRoot__1eTO- .CalendarDay {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
  }
    }

.DateRangeInput_inputRoot__1eTO- .CalendarDay {
    color: var(--matterColorLight);
    border: 0;
    margin-top: 0;
    margin-bottom: 0
  }

@media (min-width: 768px) {

.DateRangeInput_inputRoot__1eTO- .CalendarDay {
      margin-top: 0;
      margin-bottom: 0
  }
    }

.DateRangeInput_inputRoot__1eTO- {

  /* Add an underline for '.renderedDay' */
}

.DateRangeInput_inputRoot__1eTO- .CalendarDay__today .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-image: url("data:image/svg+xml;utf8,<svg width='14' height='2' viewBox='0 0 14 2' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h14v2H0z' fill='%23FFF' fill-rule='evenodd'/></svg>");
    background-position: center 34px;
  }

.DateRangeInput_inputRoot__1eTO- {

  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateRangeInput_inputRoot__1eTO- .CalendarDay__hovered_span,
  .DateRangeInput_inputRoot__1eTO- .CalendarDay__selected_span {
    background-image: transparent;
    background-color: transparent;
  }

.DateRangeInput_inputRoot__1eTO- .CalendarDay__hovered_span .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--DateRangeInput_hoveredOverlayColor);
  }

.DateRangeInput_inputRoot__1eTO- .CalendarDay__selected_span .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--successColor);
    transition: all 0.2s ease-out;
  }

.DateRangeInput_inputRoot__1eTO- {
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateRangeInput_inputRoot__1eTO- .CalendarDay__selected_start {
    background-color: transparent;
    background-image: none;
  }

.DateRangeInput_inputRoot__1eTO- .CalendarDay__selected_start .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--successColor);
    border-top-left-radius: calc(var(--DateRangeInput_selectionHeight) / 2);
    border-bottom-left-radius: calc(var(--DateRangeInput_selectionHeight) / 2);
  }

.DateRangeInput_inputRoot__1eTO- {
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateRangeInput_inputRoot__1eTO- .CalendarDay__after-hovered_start {
    background-color: transparent;
  }

.DateRangeInput_inputRoot__1eTO- .CalendarDay__after-hovered_start .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--DateRangeInput_hoveredOverlayColor);
  }

.DateRangeInput_inputRoot__1eTO- {
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateRangeInput_inputRoot__1eTO- .CalendarDay__selected_end {
    background-color: transparent;
  }

.DateRangeInput_inputRoot__1eTO- .CalendarDay__selected_end .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--successColor);
    border-top-right-radius: calc(var(--DateRangeInput_selectionHeight) / 2);
    border-bottom-right-radius: calc(var(--DateRangeInput_selectionHeight) / 2);
    color: var(--matterColorLight);
  }

.DateRangeInput_inputRoot__1eTO- .CalendarDay:hover .renderedDay {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--DateRangeInput_selectionHeight);
    background-color: var(--DateRangeInput_hoveredOverlayColor);
  }

.DateRangeInput_inputRoot__1eTO- {
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateRangeInput_inputRoot__1eTO- .CalendarDay__blocked_out_of_range,
  .DateRangeInput_inputRoot__1eTO- .CalendarDay__blocked_out_of_range:active,
  .DateRangeInput_inputRoot__1eTO- .CalendarDay__blocked_out_of_range:hover {
    background-color: transparent;
    color: var(--marketplaceColorDark);
    border: 0;
  }

.DateRangeInput_inputRoot__1eTO- {
  /* Remove default bg-color and use our extra span instead '.renderedDay' */
}

.DateRangeInput_inputRoot__1eTO- .CalendarDay__blocked_calendar,
  .DateRangeInput_inputRoot__1eTO- .CalendarDay__blocked_calendar:active,
  .DateRangeInput_inputRoot__1eTO- .CalendarDay__blocked_calendar:hover {
    background-color: transparent;
    color: var(--marketplaceColorDark);
    border: 0;
  }

.DateRangeInput_inputRoot__1eTO- .CalendarDay__blocked_out_of_range .CalendarDay__blocked_calendar .renderedDay {
    background-color: transparent;
  }

.DateRangeInput_inputRoot__1eTO- .DateInput_fang {
    display: none;
  }

.DateRangeInput_inputRoot__1eTO- .CalendarMonth_caption {
    text-transform: capitalize;
  }

.DateRangeInput_inputRoot__1eTO- .DateInput_input__disabled {
    font-style: normal;
  }

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */

.DateRangeInput_withMobileMargins__3mF7U .DateRangePickerInput {
    width: calc(100vw - 48px);
    margin: 0 24px 3px 24px
  }

@media (min-width: 768px) {

.DateRangeInput_withMobileMargins__3mF7U .DateRangePickerInput {
      width: 100%;
      margin: 0
  }
    }

.DateRangeInput_withMobileMargins__3mF7U {

  /* Create gutter between inputs */
}

.DateRangeInput_withMobileMargins__3mF7U .DateInput {
    width: calc(50% - 6px);
    background: none
  }

@media (min-width: 768px) {

.DateRangeInput_withMobileMargins__3mF7U .DateInput {
      width: calc(50% - 12px)
  }
    }

.DateRangeInput_withMobileMargins__3mF7U .DateInput:first-of-type {
    margin-right: 12px
  }

@media (min-width: 768px) {

.DateRangeInput_withMobileMargins__3mF7U .DateInput:first-of-type {
      margin-right: 24px
  }
    }

.DateRangeInput_arrowIcon__1rrZh {
  stroke: var(--matterColorLight);
  fill: var(--matterColorLight);
}

.FieldDateRangeInput_fieldRoot__3uVZA {
}

.FieldDateRangeInput_labels__1Ra8b {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.FieldDateRangeInput_startDateLabel__13V4x,
.FieldDateRangeInput_endDateLabel__2rPIq {
  flex-basis: 50%;
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.FieldDateRangeInput_startDateLabel__13V4x,
.FieldDateRangeInput_endDateLabel__2rPIq {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.FieldDateRangeInput_startDateLabel__13V4x,
.FieldDateRangeInput_endDateLabel__2rPIq {
  font-weight: 600;
  color: var(--matterColor);
  margin-top: 0;
  margin-bottom: 0
}

@media (min-width: 768px) {

.FieldDateRangeInput_startDateLabel__13V4x,
.FieldDateRangeInput_endDateLabel__2rPIq {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600
}
  }

@media (min-width: 768px) {

.FieldDateRangeInput_startDateLabel__13V4x,
.FieldDateRangeInput_endDateLabel__2rPIq {
    padding-bottom: 1px
}
  }

.FieldDateRangeInput_labelSuccess__3X6hy {
  color: var(--successColor);
}

.FieldDateRangeInput_inputBorders__C-_iT {
  width: 100%;
  display: flex;
  justify-content: space-between;
  transition: all 0.15s ease-out;
}

.FieldDateRangeInput_input__3b_MZ {
  flex-basis: 50%;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: var(--attentionColor);

  transition: border-bottom-color var(--transitionStyle);
  transition: all 0.15s ease-out
}

.FieldDateRangeInput_input__3b_MZ:hover,
  .FieldDateRangeInput_input__3b_MZ:focus {
    border-bottom-color: var(--matterColor);
    outline: none;
  }

@media (min-width: 768px) {

.FieldDateRangeInput_input__3b_MZ {
    border-bottom-width: 3px
}
  }

.FieldDateRangeInput_inputSuccess__1MXwB {
  border-bottom-color: var(--successColor);
}

.FieldDateRangeInput_inputError__2wxfJ {
  border-bottom-color: var(--failColor);
}

.FieldDateRangeInput_hover__2zRrb {
  border-bottom-color: var(--matterColorDark);
}

/**
 * Mobile margins mean that labels, inputs (incl separate borders) get margins,
 * but calendar popup doesn't.
 */

.FieldDateRangeInput_mobileMargins__1o1BB {
  /* Mobile Safari couldn't handle width: calc(100vw - 48px); */
  width: calc(100vw - 48px);
  margin: 0 24px

  /* Gutter between inputs (when calendar is not visible) */
}

.FieldDateRangeInput_mobileMargins__1o1BB .FieldDateRangeInput_startDateLabel__13V4x,
  .FieldDateRangeInput_mobileMargins__1o1BB .FieldDateRangeInput_endDateLabel__2rPIq {
    /* Mobile Safari couldn't handle width: calc(50% - 6px); */
    flex-basis: calc(50vw - 30px);
  }

.FieldDateRangeInput_mobileMargins__1o1BB .FieldDateRangeInput_input__3b_MZ {
    /* Mobile Safari couldn't handle width: calc(50% - 6px); */
    flex-basis: calc(50vw - 30px);
    transition: all 0.15s ease-out;
  }

@media (min-width: 768px) {

.FieldDateRangeInput_mobileMargins__1o1BB {
    width: 100%;
    margin: 0

    /* Gutter between inputs (when calendar is not visible) */
}
    .FieldDateRangeInput_mobileMargins__1o1BB .FieldDateRangeInput_startDateLabel__13V4x,
    .FieldDateRangeInput_mobileMargins__1o1BB .FieldDateRangeInput_endDateLabel__2rPIq {
      flex-basis: calc(50% - 12px);
    }

    .FieldDateRangeInput_mobileMargins__1o1BB .FieldDateRangeInput_input__3b_MZ {
      flex-basis: calc(50% - 12px);
      transition: all 0.15s ease-out;
    }
  }

.FieldRadioButton_root__2m3a3 {
  position: relative;
}

.FieldRadioButton_input__2IX8k {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0

  /* Display the "check" when checked */
}

.FieldRadioButton_input__2IX8k:checked + label .FieldRadioButton_checked__2khjD {
    display: inline;
  }

.FieldRadioButton_input__2IX8k {

  /* Highlight the borders if the checkbox is hovered, focused or checked */
}

.FieldRadioButton_input__2IX8k:hover + label .FieldRadioButton_notChecked__2mh8Z,
  .FieldRadioButton_input__2IX8k:hover + label .FieldRadioButton_required__2FIsv,
  .FieldRadioButton_input__2IX8k:focus + label .FieldRadioButton_notChecked__2mh8Z,
  .FieldRadioButton_input__2IX8k:focus + label .FieldRadioButton_required__2FIsv,
  .FieldRadioButton_input__2IX8k:checked + label .FieldRadioButton_notChecked__2mh8Z,
  .FieldRadioButton_input__2IX8k:checked + label .FieldRadioButton_required__2FIsv {
    stroke: var(--matterColorDark);
  }

.FieldRadioButton_input__2IX8k {

  /* Hightlight the text on checked, hover and focus */
}

.FieldRadioButton_input__2IX8k:focus + label .FieldRadioButton_text__36hcy,
  .FieldRadioButton_input__2IX8k:hover + label .FieldRadioButton_text__36hcy,
  .FieldRadioButton_input__2IX8k:checked + label .FieldRadioButton_text__36hcy {
    color: var(--matterColorDark);
  }

.FieldRadioButton_label__2zTq4 {
  display: flex;
  align-items: center;
  padding-top: 6px;
}

.FieldRadioButton_radioButtonWrapper__tG7JM {
  /* This should follow line-height */
  height: 32px;
  margin-top: -2px;
  margin-right: 12px;
  align-self: baseline;

  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.FieldRadioButton_checked__2khjD {
  display: none;
  stroke: var(--marketplaceColor);
  fill: var(--marketplaceColor);
}

.FieldRadioButton_notChecked__2mh8Z {
  stroke: var(--matterColorAnti)
}

.FieldRadioButton_notChecked__2mh8Z:hover {
    stroke: pink;
  }

.FieldRadioButton_required__2FIsv {
  stroke: var(--attentionColor)
}

.FieldRadioButton_required__2FIsv:hover {
    stroke: pink;
  }

.FieldRadioButton_text__36hcy {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  color: var(--matterColor);
  margin-top: -1px;
  margin-bottom: 1px;
  cursor: pointer;
}

.FieldReviewRating_ratingFieldSet__1avD7 {
  border: none;
  padding: 0;
  margin: 0;
}

/* fieldset can't use flexbox hence this wrapper exists */

.FieldReviewRating_rating__h97PF {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-top: 16px;
}

.FieldReviewRating_rateInput__16Nvk {
  display: none;
}

.FieldReviewRating_label__2qsQr {
  width: 27px;
  cursor: pointer;
}

.FieldReviewRating_star__1tNkO {
  fill: var(--matterColorNegative);
  transition: var(--transitionStyleButton);
}

legend {
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 16px;
  margin-bottom: 14px;
}

@media (min-width: 768px) {

legend {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 16px;
  margin-bottom: 16px;
}
    }

legend {
  color: var(--matterColorAnti);
}

/***** CSS Magic to Highlight Stars on Hover *****/

/* Star order: reverse expected (5 -> 1) and also input before label */

/* show actived star when checked */

/* and actived star when hovering over a star */

/* and show previous stars also as activated */

.FieldReviewRating_rating__h97PF > .FieldReviewRating_rateInput__16Nvk:checked ~ .FieldReviewRating_label__2qsQr > .FieldReviewRating_star__1tNkO,
.FieldReviewRating_rating__h97PF > .FieldReviewRating_label__2qsQr:hover > .FieldReviewRating_star__1tNkO,
.FieldReviewRating_rating__h97PF > .FieldReviewRating_label__2qsQr:hover ~ .FieldReviewRating_label__2qsQr > .FieldReviewRating_star__1tNkO {
  fill: var(--marketplaceColor);
  transition: var(--transitionStyleButton);
}

/* Darken hovered star when changing rating i.e it already is active */

/* and darken hovered star too when changing rating (hovering inside current selection) */

/* and darken current selection inside hovered selection */

/* and darken hovered selection inside current selection */

.FieldReviewRating_rating__h97PF > .FieldReviewRating_rateInput__16Nvk:checked + .FieldReviewRating_label__2qsQr:hover > .FieldReviewRating_star__1tNkO,
.FieldReviewRating_rating__h97PF > .FieldReviewRating_rateInput__16Nvk:checked ~ .FieldReviewRating_label__2qsQr:hover > .FieldReviewRating_star__1tNkO,
.FieldReviewRating_rating__h97PF > .FieldReviewRating_label__2qsQr:hover ~ .FieldReviewRating_rateInput__16Nvk:checked ~ .FieldReviewRating_label__2qsQr > .FieldReviewRating_star__1tNkO,
.FieldReviewRating_rating__h97PF > .FieldReviewRating_rateInput__16Nvk:checked ~ .FieldReviewRating_label__2qsQr:hover ~ .FieldReviewRating_label__2qsQr > .FieldReviewRating_star__1tNkO {
  fill: var(--marketplaceColorDark);
}

.FieldSelect_root__1fbX- {}

.FieldSelect_select__JkxZe {
  color: var(--matterColorAnti);
  border: solid 1px;
  border-color: #dcdcdc;
  padding-right: 20px
}

.FieldSelect_select__JkxZe>option {
    color: var(--matterColor);
  }

.FieldSelect_select__JkxZe>option:disabled {
    color: var(--matterColorAnti);
  }

.FieldSelect_selectSuccess__j18E0 {
  color: var(--matterColor);
  /* border-color: var(--successColor); */
}

.FieldSelect_selectError__leG_7 {
  border-color: var(--failColor);
}
.FieldTextInput_root__2ODuL {}

.FieldTextInput_input__h1H0a {
  border-color: #dcdcdc;
}

.FieldTextInput_inputSuccess__30_Mn {
  border-color: #dcdcdc;
}

.FieldTextInput_inputError__3NfYj {
  border-color: var(--failColor);
}

.FieldTextInput_textarea__22x3U {}
.FieldCheckboxGroup_root__RFvjG {
  margin: 0;
  padding: 0;
  border: none;
}

.FieldCheckboxGroup_list__PmD_Q {
  margin: 0;
}

@media (min-width: 768px) {

.FieldCheckboxGroup_twoColumns__HP1Wb {
    -webkit-columns: 2;
            columns: 2
}
  }

.FieldCheckboxGroup_item__2HRmq {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid
}

@media (min-width: 768px) {

.FieldCheckboxGroup_item__2HRmq {
    padding: 2px 0 6px 0
}
  }

.StripeBankAccountTokenInputField_unsupportedCountryError__kHOAD {
  color: var(--failColor);
}

.StripeBankAccountTokenInputField_root__EPmOW {
  /* Parent component should not shrink this field */
  flex-shrink: 0;

  /* This component uses flexbox layout too */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.StripeBankAccountTokenInputField_input__3oFlf {
  /* Parent component should not shrink this field */
  flex-shrink: 0;
  border-color: var(--matterColorAnti);
  margin-bottom: 24px;
  width: 100%
}

.StripeBankAccountTokenInputField_input__3oFlf:last-of-type {
    margin-bottom: 0;
  }

.StripeBankAccountTokenInputField_longForm__3Xn63 {
  width: 100%;
  margin-bottom: 24px;
}

@media (min-width: 550px) {
  .StripeBankAccountTokenInputField_longForm__3Xn63 {
    width: calc(50% - 9px);
  }
}

.StripeBankAccountTokenInputField_inputSuccess__3T_o2 {
  border-color: var(--successColor);
}

.StripeBankAccountTokenInputField_inputError__1q9nM {
  border-color: var(--failColor);
}

.StripeBankAccountTokenInputField_error__3UTJ3 {
  color: var(--failColor);
}
.TabNav_root__2jlLZ {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.TabNav_tab__Jd3Ya {
  margin-left: 16px
}

.TabNav_tab__Jd3Ya:first-child {
    margin-left: 0;
  }

@media (min-width: 1024px) {

.TabNav_tab__Jd3Ya {
    margin-left: 24px
}
  }

.TabNav_link__331cQ {
  display: inline-block;
  white-space: nowrap;
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0
}

@media (min-width: 1024px) {

.TabNav_link__331cQ {
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px
}
    }

.TabNav_link__331cQ {
  color: var(--matterColor);
  padding: 10px 20px;
  margin-top: auto;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  transition: var(--transitionStyleButton)
}

.TabNav_link__331cQ:hover {
    text-decoration: none;
    color: var(--matterColorDark);
  }

@media (min-width: 1024px) {

.TabNav_link__331cQ {
    border-bottom-width: 0px
}
  }

.TabNav_selectedLink__1CsmK {
  color: var(--matterColorDark);
  text-align: center;
  background-color: #fff;
  box-shadow: 0px 0px 4px #ddd;
}

.TabNav_disabled__3hSHC {
  pointer-events: none;
  color: var(--matterColorAnti);
  text-decoration: none;
}
.TabNavHorizontal_root__1zMvr {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  justify-content: center
}

.TabNavHorizontal_root__1zMvr .TabNavHorizontal_fixedWidth__1ltcu {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
  }

.TabNavHorizontal_authTabs__UHAFm .TabNavHorizontal_fixedWidth__1ltcu {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

.TabNavHorizontal_darkSkin__2uGiK {
  background-color: var(--matterColor);
}

.TabNavHorizontal_tab__319ef {
  margin-left: 16px
}

.TabNavHorizontal_tab__319ef:first-child {
    margin-left: 0;
  }

@media (min-width: 1024px) {

.TabNavHorizontal_tab__319ef {
    margin-left: 24px
}
  }

.TabNavHorizontal_tabContent__30KEO {
    /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

    /* Borders */
  border: none;

    /* Colors */
  color: var(--marketplaceColor);

    /* Text size should be inherited */
  text-decoration: none
}

.TabNavHorizontal_tabContent__30KEO:hover {
  text-decoration: underline;
  cursor: pointer;
    }

.TabNavHorizontal_tabContent__30KEO {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;

  /* Font */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0;

  color: var(--matterColor);
  padding-bottom: 10px;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  transition: var(--transitionStyleButton)
}

.TabNavHorizontal_tabContent__30KEO:hover,
  .TabNavHorizontal_tabContent__30KEO:focus {
    text-decoration: none;
    color: var(--matterColorDark);
  }

@media (min-width: 768px) {

.TabNavHorizontal_tabContent__30KEO {
    border-bottom-width: 4px;
    padding: 16px 0px
}
  }

.TabNavHorizontal_button__3kFKh {
  /* Override Chrome's border-radius which is visible when
   border is used as underline */
  border-radius: 0;
  /* Override Chrome's button outline */
  outline: 0;
}

.TabNavHorizontal_selectedTabContent__1aeaf {
  border-bottom-color: var(--matterColorDark);
  color: var(--matterColorDark);
}

.TabNavHorizontal_disabled__2RwLw {
  pointer-events: none;
  color: var(--matterColorAnti);
  text-decoration: none;
}

/* Dark skin */

.TabNavHorizontal_tabContentDarkSkin__1-8PP {
  color: var(--matterColorAnti)
}

.TabNavHorizontal_tabContentDarkSkin__1-8PP:hover {
    color: var(--matterColorLight);
  }

.TabNavHorizontal_selectedTabContentDarkSkin__Mr5Rc {
  border-bottom-color: var(--matterColorLight);
  color: var(--matterColorLight);
}

.TabNavHorizontal_disabledDarkSkin__2o2a- {
  color: var(--matterColorDark);
  opacity: 0.3;
}
.Tabs_root__1Lwap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1 1;
}

.UserNav_root__MukAU {
  display: none
}

@media (min-width: 768px) {

.UserNav_root__MukAU {
    display: flex;
    justify-content: flex-end;
    height: 57px;
    align-items: flex-end;
    padding: 13px 24px 0 24px
}
  }

@media (min-width: 1024px) {

.UserNav_root__MukAU {
    padding: 0 36px
}
  }

.UserNav_tab__3t1t9 {
  display: flex;
  align-items: flex-end;
  height: 100%
}

.UserNav_tab__3t1t9:first-child>a {
      margin-left: 0px;
    }

.UserNav_tab__3t1t9>a {
    margin-left: 20px;
  }
.Avatar_avatarBase__iusY1 {
  font-family: 'Open Sans', sans-serif;
  border-radius: 50%;

  /* Position possible initials to the center of the component */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Colors */
  background-image: linear-gradient(-180deg,
      var(--marketplaceColorLight) 0%,
      var(--marketplaceColor) 100%);
  color: var(--matterColorLight)
}

.Avatar_avatarBase__iusY1:hover {
    text-decoration: none;
  }

/* Small Avatar */

.Avatar_root__D2SbB {

  /* Layout */
  width: 40px;
  height: 40px;
}

.Avatar_initials__2sIWV {
  font-size: 14px;
  font-weight: var(--fontWeightBold);
}

.Avatar_avatarImage__fYB9v {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

/* Medium Avatar */

.Avatar_smallAvatar__30uY_ {

  /* Fixed dimensions */
  width: 24px;
  height: 24px;
}

.Avatar_mediumAvatar__FcVZz {

  /* Fixed dimensions */
  width: 60px;
  height: 60px;
}

.Avatar_mediumAvatar__FcVZz .Avatar_initials__2sIWV {
  font-size: 20px;
  font-weight: var(--fontWeightSemiBold);
}

/* Large Avatar */

.Avatar_largeAvatar__335PK {

  /* Fixed dimensions */
  width: 96px;
  height: 96px;
}

.Avatar_largeAvatar__335PK .Avatar_initials__2sIWV {
  font-size: 30px;
  font-weight: var(--fontWeightSemiBold);
}

.Avatar_bannedUserIcon__2rptv {
  width: 100%;
  height: 100%;
}
.BookingTimeInfo_root__1HfMf {
}

.BookingTimeInfo_bookingInfo__1ag-P {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.BookingTimeInfo_dateSection__3XjbW {
  margin-right: 5px;
}

.OrderBreakdown_root__3Rfjo {
  display: flex;
  flex-direction: column;
}

.OrderBreakdown_nowrap__3gJmM {
  white-space: nowrap;
}

.OrderBreakdown_lineItem__3olsT {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.OrderBreakdown_lineItem__3olsT {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.OrderBreakdown_lineItem__3olsT {
  margin: 0;
}

@media (min-width: 768px) {

.OrderBreakdown_lineItem__3olsT {
    padding-top: 7px;
    padding-bottom: 1px;
}
  }

.OrderBreakdown_lineItem__3olsT {

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.OrderBreakdown_bookingPeriod__3EdkV {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 6px
}

@media (min-width: 768px) {

.OrderBreakdown_bookingPeriod__3EdkV {
    padding-bottom: 8px
}
  }

.OrderBreakdown_bookingPeriodSectionRigth__2bNeE {
  text-align: right;
}

.OrderBreakdown_dayLabel__1dt5_ {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.OrderBreakdown_dayLabel__1dt5_ {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.OrderBreakdown_dayLabel__1dt5_ {
  margin: 0;
  color: var(--matterColorAnti);
  line-height: 24px;
  padding-top: 2px;
  padding-bottom: 4px
}

@media (min-width: 768px) {

.OrderBreakdown_dayLabel__1dt5_ {
    padding-top: 1px;
    padding-bottom: 7px
}
  }

.OrderBreakdown_dayInfo__ss6NU {
  font-family: 'Open Sans', sans-serif;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

@media (min-width: 768px) {

.OrderBreakdown_dayInfo__ss6NU {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.OrderBreakdown_subTotalLineItem__1CCMn {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.OrderBreakdown_subTotalLineItem__1CCMn {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.OrderBreakdown_subTotalLineItem__1CCMn {
  font-weight: var(--fontWeightBold);
  margin: 0;
  padding: 5px 0 1px 0;
}

@media (min-width: 768px) {

.OrderBreakdown_subTotalLineItem__1CCMn {
    padding: 7px 0 1px 0;
}
  }

.OrderBreakdown_subTotalLineItem__1CCMn {

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.OrderBreakdown_lineItemTotal__2tiaV {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.OrderBreakdown_lineItemTotal__2tiaV {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.OrderBreakdown_lineItemTotal__2tiaV {
  margin: 0;
  padding-top: 6px;
  padding-bottom: 6px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between
}

@media (min-width: 768px) {

.OrderBreakdown_lineItemTotal__2tiaV {
    padding-top: 7px;
    padding-bottom: 1px
}
  }

.OrderBreakdown_itemLabel__1rh8x {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
  flex: 1 1;
}

.OrderBreakdown_itemValue__3hXOa {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
  margin: 0 0 0 10px;
}

.OrderBreakdown_totalDivider__3xqXl {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: var(--matterColorNegative)
}

@media (min-width: 768px) {

.OrderBreakdown_totalDivider__3xqXl {
    margin: 7px 0 0px 0
}
  }

.OrderBreakdown_totalLabel__2mL_H {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
}

.OrderBreakdown_totalPrice__1yHgW {
  font-family: 'Open Sans', sans-serif;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

@media (min-width: 768px) {

.OrderBreakdown_totalPrice__1yHgW {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.OrderBreakdown_totalPrice__1yHgW {
  margin: 0 0 0 10px;
  padding-top: 0px;
}

.OrderBreakdown_feeInfo__1GZcK {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased
}

@media (min-width: 768px) {

.OrderBreakdown_feeInfo__1GZcK {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px
}
    }

.OrderBreakdown_feeInfo__1GZcK {
  flex-shrink: 0;
  margin: 0;
  color: var(--matterColorAnti);
  padding-top: 4px;
  padding-bottom: 14px
}

@media (min-width: 768px) {

.OrderBreakdown_feeInfo__1GZcK {
    padding-top: 11px;
    padding-bottom: 21px
}
  }

/* Order form inside modalContainer needs special handling */

/* since "inMobile" breakpoint is actually --viewportLarge */

.OrderPanel_modalContainer__2hX23 {
  flex-grow: 1;

    /* Create context so the close button can be positioned within it */
  position: relative;

    /* Display and dimensions */
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0;

  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius)
}

@media (min-width: 768px) {

.OrderPanel_modalContainer__2hX23 {
  flex-basis: 480px;
  flex-grow: 0;
  height: 100%;
  padding: 0;
  background-color: transparent;
  border-radius: 0
}
    }

.OrderPanel_modalContainer__2hX23 {

  height: 100vh
}

@media (min-width: 768px) {

.OrderPanel_modalContainer__2hX23 {
    flex-basis: 576px;
    height: 100%;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh
}
  }

@media (min-width: 1024px) {

.OrderPanel_modalContainer__2hX23 {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0
}
  }

.OrderPanel_modalHeading__1N19V {
  margin-top: 89px;
  margin-bottom: 36px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between
}

@media (min-width: 768px) {

.OrderPanel_modalHeading__1N19V {
    padding: 0
}
  }

@media (min-width: 1024px) {

.OrderPanel_modalHeading__1N19V {
    display: none
}
  }

.OrderPanel_title__BJ6Fr {
  /* Font */
  font-weight: var(--fontWeightSemiBold);
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.5px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px
}

@media (min-width: 768px) {

.OrderPanel_title__BJ6Fr {
  font-size: 40px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 48px;
  letter-spacing: -1px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 24px;
  margin-bottom: 24px
}
    }

.OrderPanel_title__BJ6Fr {
  color: var(--matterColorDark);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0
}

@media (min-width: 768px) {

.OrderPanel_title__BJ6Fr {
    margin-top: 0;
    margin-bottom: 5px
}
  }

.OrderPanel_price__d8YrR {
  font-weight: var(--fontWeightSemiBold);
  font-size: 21px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 21px;
  margin-bottom: 17px;
}

@media (min-width: 768px) {

.OrderPanel_price__d8YrR {
  line-height: 32px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 21px;
  margin-bottom: 19px;
}
    }

.OrderPanel_price__d8YrR {
  color: var(--marketplaceColor);
  font-weight: 700;
}

.OrderPanel_sellerInfo__SBsqL {
  margin: 20px 0;
  float: left;
  width: 100%;
}

.OrderPanel_author__3NV71 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.OrderPanel_author__3NV71 {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.OrderPanel_author__3NV71 {
  margin: 0px
}

@media (min-width: 768px) {

.OrderPanel_author__3NV71 {
    color: var(--matterColorDark);
    font-size: 17px
}
  }

.OrderPanel_providerAvatar__1v8cW {
  margin-right: 10px;
  width: 76px;
  height: 76px
}

.OrderPanel_providerAvatar__1v8cW>span {
    font-size: 20px !important;
  }

.OrderPanel_orderHeading__Og1O6 {
  display: none
}

@media (min-width: 1024px) {

.OrderPanel_orderHeading__Og1O6 {
    display: flex;
    justify-content: space-between;
    align-items: center
}
  }

.OrderPanel_orderHeading__Og1O6 .OrderPanel_title__BJ6Fr {
    margin-bottom: 0;
  }

.OrderPanel_orderTitle__ZH0ss {
  /* Font */
  color: var(--matterColorDark);
  font-weight: var(--fontWeightSemiBold);
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 2px
}

@media (min-width: 1024px) {

.OrderPanel_orderTitle__ZH0ss {
    margin-bottom: 0
}
  }

.OrderPanel_orderHelp__3R8Xk {
  display: none;
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.OrderPanel_orderHelp__3R8Xk {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

@media (min-width: 768px) {

.OrderPanel_orderHelp__3R8Xk {
    color: var(--matterColor);
    display: block;
    margin-top: 0
}
  }

.OrderPanel_bookingForm__1A80x {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 0 0 84px 0
}

@media (min-width: 768px) {

.OrderPanel_bookingForm__1A80x {
    margin: 0;
    min-height: 400px
}
  }

@media (min-width: 1024px) {

.OrderPanel_bookingForm__1A80x {
    min-width: 312px;
    min-height: auto
}
  }

.OrderPanel_bookingDatesSubmitButtonWrapper__3Ec47 {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--matterColorLight)
}

@media (min-width: 768px) {

.OrderPanel_bookingDatesSubmitButtonWrapper__3Ec47 {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent
}
  }

.OrderPanel_openOrderForm__2p0Pu {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0)
}

@media (min-width: 768px) {

.OrderPanel_openOrderForm__2p0Pu {
    padding: 18px 60px 18px 60px
}
  }

@media (min-width: 1024px) {

.OrderPanel_openOrderForm__2p0Pu {
    display: none
}
  }

.OrderPanel_priceContainer__fjsuM {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 5px 12px;
}

.OrderPanel_priceValue__2zbLY {
  /* Font */
  font-weight: var(--fontWeightSemiBold);
  font-size: 21px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 21px;
  margin-bottom: 17px
}

@media (min-width: 768px) {

.OrderPanel_priceValue__2zbLY {
  line-height: 32px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 21px;
  margin-bottom: 19px
}
    }

.OrderPanel_priceValue__2zbLY {
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px
}

@media (min-width: 768px) {

.OrderPanel_priceValue__2zbLY {
    margin-top: 0;
    margin-bottom: 0
}
  }

.OrderPanel_perUnit__2zUKI {
  /* Font */
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased
}

@media (min-width: 768px) {

.OrderPanel_perUnit__2zUKI {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px
}
    }

.OrderPanel_perUnit__2zUKI {
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px
}

@media (min-width: 768px) {

.OrderPanel_perUnit__2zUKI {
    margin-top: 0;
    margin-bottom: 0
}
  }

.OrderPanel_orderButton__2Hn6N {
  font-family: 'Open Sans', sans-serif;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

@media (min-width: 768px) {

.OrderPanel_orderButton__2Hn6N {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.OrderPanel_orderButton__2Hn6N {
  border-radius: 4px;
  display: block;
  min-height: 45px;
  margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */

  padding: 8px 16px;
}

@media (min-width: 768px) {

.OrderPanel_orderButton__2Hn6N {
  padding: 10px 20px;
}
    }

.OrderPanel_orderButton__2Hn6N {

    /* Borders */
  border: none;
  border-radius: var(--borderRadius);

    /* Colors */
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

    /* Text */
  text-align: center;
  text-decoration: none;

    /* Effects */
  transition: all var(--transitionStyleButton);
  cursor: pointer;
}

.OrderPanel_orderButton__2Hn6N:hover,
    .OrderPanel_orderButton__2Hn6N:focus {
  outline: none;
  background-color: var(--marketplaceColorDark);
  text-decoration: none;
  box-shadow: var(--boxShadowButton);
    }

.OrderPanel_orderButton__2Hn6N:disabled {
  background-color: var(--matterColorNegative);
  color: var(--matterColorLight);
  cursor: not-allowed;
  box-shadow: none;
    }

@media (min-width: 768px) {

.OrderPanel_orderButton__2Hn6N {
  min-height: 50px;
}
    }

.OrderPanel_orderButton__2Hn6N {

  background-color: #14100f;
  color: var(--matterColorLight);
}

.OrderPanel_orderButton__2Hn6N:hover {
  background-color: #14100f;
  color: var(--matterColorLight);
    }

.OrderPanel_orderButton__2Hn6N:focus {
  background-color: var(--successColorDark);
    }

.OrderPanel_orderButton__2Hn6N:disabled {
  background-color: var(--matterColorNegative);
  color: var(--matterColor);
    }

.OrderPanel_orderButton__2Hn6N {
  padding: 10px 30px;
  min-height: 50px;
}

.OrderPanel_closedListingButton__34akx {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.OrderPanel_receipt__37CJG {
  flex-shrink: 0;
}

.OrderPanel_tags__2pIFY {
  flex-wrap: wrap;
  display: flex
}

.OrderPanel_tags__2pIFY>span {
    border: 1px solid black;
    border-radius: 2px;
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    padding: 2px 8px;
    text-decoration: none;
    transition: background-color .25s ease, color .25s ease;
    margin-right: 3px;
    color: black;
    line-height: 20px;
    margin-bottom: 3px;
    font-size: 14px;
    word-break: break-all;
  }

.OrderPanel_bookmark__2oYRg>svg {
    width: 20px;
  }

.OrderPanel_productDetails__1pKyQ {
  margin-bottom: 15px
}

.OrderPanel_productDetails__1pKyQ .OrderPanel_productinfo__1plQ8 {
    width: 100%;
  }

.OrderPanel_tagsSection__3P7HP {
  margin-top: 20px;
  float: left;
  width: 100%
}

.OrderPanel_tagsSection__3P7HP>span {
    color: var(--marketplaceColor);
    margin: 0 0 10px 0;
    font-size: 18px;
  }

.OrderPanel_tagsSection__3P7HP>h2 {
    margin: 0 0 10px 0;
    color: var(--marketplaceColor);
    font-size: 18px;
  }

.OrderPanel_tagsSection__3P7HP .OrderPanel_tags__2pIFY {}

.OrderPanel_paypalPurchase__2ZS1q {
  margin-top: 20px;
  float: left;
  width: 100%
}

.OrderPanel_paypalPurchase__2ZS1q>h2 {
    margin: 0 0 10px 0;
    color: var(--marketplaceColor);
    font-size: 18px
  }

.OrderPanel_paypalPurchase__2ZS1q>h2>img {}

.OrderPanel_paypalPurchase__2ZS1q>p {
    margin: 0;
  }

.OrderPanel_orderSellerInfo__3Qh6R {
  margin: 40px 0 20px 0
}

.OrderPanel_orderSellerInfo__3Qh6R>h2 {
    font-size: 18px;
    line-height: 100%;
    font-weight: var(--fontWeightSemiBold);
    color: var(--matterColorDark);
  }
.BookingDatesForm_root__2YZXK {
  display: flex;
  flex-direction: column;
}

.BookingDatesForm_bookingDates__1dJe7 {
  flex-shrink: 0;
  margin-bottom: 38px;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
}

.BookingDatesForm_priceBreakdownContainer__D2g86 {
  padding: 0 24px;
  margin-bottom: 40px
}

@media (min-width: 768px) {

.BookingDatesForm_priceBreakdownContainer__D2g86 {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px
}
  }

.BookingDatesForm_priceBreakdownTitle__15eyq {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 5px;
  margin-bottom: 14px
}

@media (min-width: 768px) {

.BookingDatesForm_priceBreakdownTitle__15eyq {
    margin-top: 5px;
    margin-bottom: 26px
}
  }

.BookingDatesForm_error__1RQgD {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.BookingDatesForm_spinner__2-812 {
  margin: auto;
}

.BookingDatesForm_sideBarError__2sZdz {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.BookingDatesForm_sideBarError__2sZdz {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.BookingDatesForm_sideBarError__2sZdz {
  color: var(--failColor);
  margin: 0 24px 12px 24px
}

@media (min-width: 768px) {

.BookingDatesForm_sideBarError__2sZdz {
    margin: 0 0 12px 0
}
  }

.BookingDatesForm_smallPrint__RKpDr {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased
}

@media (min-width: 768px) {

.BookingDatesForm_smallPrint__RKpDr {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px
}
    }

.BookingDatesForm_smallPrint__RKpDr {
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0
}

@media (min-width: 768px) {

.BookingDatesForm_smallPrint__RKpDr {
    margin-top: auto;
    margin-bottom: 20px
}
  }

@media (min-width: 1024px) {

.BookingDatesForm_smallPrint__RKpDr {
    margin-top: 4px;
    margin-bottom: 21px
}
  }

.BookingDatesForm_submitButtonWrapper__2dCa3 {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%
}

@media (min-width: 768px) {

.BookingDatesForm_submitButtonWrapper__2dCa3 {
    padding: 0;
    width: 100%
}
  }

.ProductOrderForm_quantityField__3hsZM {
  margin-bottom: 10px;
  width: 100%;
}

.ProductOrderForm_deliveryField__1mC1W {}

.ProductOrderForm_singleDeliveryMethodSelected__jYPM0 {
  margin: 0;
  font-weight: 700
}

@media (min-width: 768px) {

.ProductOrderForm_singleDeliveryMethodSelected__jYPM0 {
    margin: 0
}
  }

.ProductOrderForm_breakdownWrapper__14bsh {
  padding: 0 24px
}

@media (min-width: 768px) {

.ProductOrderForm_breakdownWrapper__14bsh {
    padding: 0;
    margin-top: 40px
}
  }

.ProductOrderForm_breakdownWrapper__14bsh>h3 {
    color: var(--matterColorDark);
  }

.ProductOrderForm_submitButton__2c68s {
  margin-top: 14px
}

.ProductOrderForm_submitButton__2c68s>button {
    min-height: 50px;
    width: 100%;
    padding: 10px 30px;
  }

.ProductOrderForm_messageButton__31LEd {
  margin-top: 14px
}

.ProductOrderForm_messageButton__31LEd>button {
    min-height: 50px;
    width: 100%;
    padding: 10px 30px;
    background-color: #ffffff;
    color: black;
    border: 1px solid #dedede
  }

.ProductOrderForm_messageButton__31LEd>button:hover,
    .ProductOrderForm_messageButton__31LEd>button:focus,
    .ProductOrderForm_messageButton__31LEd>button:active {
      background-color: #ffffff;
      color: black;
    }

.ProductOrderForm_finePrint__2qEmh {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased;
}

@media (min-width: 768px) {

.ProductOrderForm_finePrint__2qEmh {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px;
}
    }

.ProductOrderForm_finePrint__2qEmh {
  text-align: center;
}

.ProductOrderForm_error__2xwqk {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.ProductOrderForm_error__2xwqk {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.ProductOrderForm_error__2xwqk {
  color: var(--failColor);

  padding: 0 24px
}

@media (min-width: 768px) {

.ProductOrderForm_error__2xwqk {
    padding: 0
}
  }
.EnquiryForm_root__T-vcz {
}

.EnquiryForm_icon__1NN32 {
  display: none
}

@media (min-width: 768px) {

.EnquiryForm_icon__1NN32 {
    display: block;
    margin-bottom: 36px
}
  }

.EnquiryForm_heading__2MgAd {
  font-weight: var(--fontWeightSemiBold);
  font-size: 22px;
  line-height: 100%;
  letter-spacing: -0.5px;
  margin: 0;
  font-weight: var(--fontWeightBold);
}

@media (min-width: 768px) {

.EnquiryForm_heading__2MgAd {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.9px;
  margin: 0;
}
    }

.EnquiryForm_field__1nsXb {
  margin: 25px 0 24px 0
}

@media (min-width: 768px) {

.EnquiryForm_field__1nsXb {
    margin: 22px 0 24px 0
}
  }

.EnquiryForm_error__135qu {
  color: var(--failColor);
  margin-top: 0
}

@media (min-width: 768px) {

.EnquiryForm_error__135qu {
    margin-top: 0
}
  }

.ListingPage_root__2EM3B {}
.ListingPage_pageRoot__2FTgJ {
  display: flex
}
@media (min-width: 768px) {
.ListingPage_pageRoot__2FTgJ {
    padding-bottom: 90px
}
  }
@media (min-width: 1024px) {
.ListingPage_pageRoot__2FTgJ {
    padding-bottom: 0
}
  }
.ListingPage_loadingText__HdbHd {
  margin: 24px;
}
.ListingPage_errorText__3FMBP {
  color: var(--failColor);
  margin: 24px;
}
.ListingPage_sectionImages__1BIUK {}
@media (min-width: 768px) {
.ListingPage_heroHeight__15gXQ {
    height: calc(0.41 * 100vw);
    max-height: 50vh
}
  }
.ListingPage_imageWrapperForSectionImage__1KOmD {
  background-color: var(--matterColorNegative);
  /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;
}
.ListingPage_actionBar__ksYT1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: var(--matterColorNegative);
  background-color: var(--matterColor);
  z-index: 1;
  /* bring on top of mobile image */

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: auto;
  cursor: initial;

  border-radius: var(--borderRadius);
}
.ListingPage_actionBarForProductLayout__3iiqA {
  margin: 0 0 24px 0;
  width: 100%;
}
.ListingPage_actionBarForHeroLayout__1QYGb {
  position: absolute;
  top: 13px;
  left: 13px;
  width: calc(100% - 26px);

  /* Action bar prevents the image click events going to the parent and
   should not show a pointer */
  cursor: auto;
  cursor: initial;
}
.ListingPage_ownListingText__21UeF {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}
@media (min-width: 768px) {
.ListingPage_ownListingText__21UeF {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }
.ListingPage_ownListingText__21UeF {
  margin: 14px 12px 11px 24px
}
@media (min-width: 768px) {
.ListingPage_ownListingText__21UeF {
    margin: 25px 12px 22px 24px
}
  }
.ListingPage_ownListingTextPendingApproval__2a3rB {
  color: var(--attentionColor);
}
.ListingPage_closedListingText__27Tkg {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}
@media (min-width: 768px) {
.ListingPage_closedListingText__27Tkg {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }
.ListingPage_closedListingText__27Tkg {
  margin: 14px 12px 11px 24px;
  text-align: center;
  width: 100%
}
@media (min-width: 768px) {
.ListingPage_closedListingText__27Tkg {
    margin: 25px 12px 22px 24px
}
  }
.ListingPage_editListingLink__1fWxW {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}
@media (min-width: 768px) {
.ListingPage_editListingLink__1fWxW {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }
.ListingPage_editListingLink__1fWxW {
  flex-shrink: 0;
  margin: 0;
  padding: 14px 24px 11px 12px;
  color: var(--matterColorNegative);

  transition: var(--transitionStyleButton)
}
.ListingPage_editListingLink__1fWxW:hover {
    color: var(--matterColorLight);
    text-decoration: none;
  }
@media (min-width: 768px) {
.ListingPage_editListingLink__1fWxW {
    margin: 0;
    padding: 25px 24px 22px 12px
}
  }
.ListingPage_editIcon__2nk0I {
  margin: -6px 7px 0 0;
}
.ListingPage_rootForImage__bG-Dg {
  width: 100%;
  transition: var(--transitionStyleButton)
}
@media (min-width: 768px) {
.ListingPage_rootForImage__bG-Dg {
    object-fit: cover;
    border-radius: var(--borderRadius)
}

    .ListingPage_rootForImage__bG-Dg:hover {
      -webkit-transform: scale(1.005);
              transform: scale(1.005);
      box-shadow: var(--boxShadowListingCard);
    }
  }
.ListingPage_viewPhotos__21TCD {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased
}
@media (min-width: 768px) {
.ListingPage_viewPhotos__21TCD {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px
}
    }
.ListingPage_viewPhotos__21TCD {
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  padding: 8px 13px 6px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton)
}
.ListingPage_viewPhotos__21TCD:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }
@media (min-width: 768px) {
.ListingPage_viewPhotos__21TCD {
    margin: 0
}
  }
.ListingPage_carouselModalScrollLayer__3FDR2 {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}
.ListingPage_carouselModalContainer__1Z3sZ {
  width: 100%;
  height: 100%;
}
@media (min-width: 768px) {
.ListingPage_contentWrapperForHeroLayout__1YilH {
    max-width: 1056px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative
}
  }
@media (min-width: 1024px) {
.ListingPage_contentWrapperForHeroLayout__1YilH {
    max-width: 1128px;
    padding: 0 36px;
    margin: 0 auto 117px
}
  }
@media (min-width: 1921px) {
.ListingPage_contentWrapperForHeroLayout__1YilH {
    max-width: 1056px;
    padding: 0
}
  }
@media (min-width: 768px) {
.ListingPage_mainColumnForProductLayout__bS22H {
}}
@media (min-width: 1024px) {
.ListingPage_mainColumnForProductLayout__bS22H {
    /* contentContainer's full width - (orderColumn + paddings + margin) */
    max-width: calc(100% - 300px);
    width: 100%;
    padding-right: 48px
}
  }
@media (min-width: 768px) {
.ListingPage_orderColumnForProductLayout__2Tl8c {
}}
@media (min-width: 1024px) {
.ListingPage_orderColumnForProductLayout__2Tl8c {
    display: block;
    max-width: 300px
}
  }
.ListingPage_contentWrapperForProductLayout__1F2HK {
  margin: 0 0 56px 0;
  padding: 15px 15px 0
}
@media (min-width: 768px) {
.ListingPage_contentWrapperForProductLayout__1F2HK {
    width: 100%;
    max-width: 1248px;
    padding: 15px 15px 0;
    margin: 0 auto
}
  }
@media (min-width: 1024px) {
.ListingPage_contentWrapperForProductLayout__1F2HK {
    display: flex;
    flex-direction: row;
    padding: 64px 24px;
    margin: 0 auto
}
  }
.ListingPage_mainColumnForHeroLayout__3lvwY {
  flex-basis: 100%;
  margin-bottom: 23px
}
@media (min-width: 768px) {
.ListingPage_mainColumnForHeroLayout__3lvwY {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0
}
  }
@media (min-width: 1024px) {
.ListingPage_mainColumnForHeroLayout__3lvwY {
    margin-top: 50px;
    margin-bottom: 0px;
    /* contentContainer's full width - (orderColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1
}
  }
@media (min-width: 768px) {
.ListingPage_orderColumnForHeroLayout__1nKuv {
}}
@media (min-width: 1024px) {
.ListingPage_orderColumnForHeroLayout__1nKuv {
    display: block;
    margin-top: 79px;
    margin-left: 60px;
    border-left: 1px solid var(--matterColorNegative);
    padding-left: 60px;
    flex-basis: 312px;
    flex-shrink: 0
}
  }
@media (min-width: 1024px) {
.ListingPage_orderPanel__3hYTb {
    position: sticky;
    top: 108px
}
  }
@media (min-width: 1024px) {
.ListingPage_productOrderPanel__DzeDz {
    display: block;
    min-width: 300px;
    /* position: sticky;
    top: 108px;
    overflow-y: scroll; */
    height: 100vh;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none
    /* Firefox */

}
  }
.ListingPage_productOrderPanel__DzeDz::-webkit-scrollbar {
    display: none;
  }
.ListingPage_sectionAvatar__1SS5- {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px
}
@media (min-width: 768px) {
.ListingPage_sectionAvatar__1SS5- {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0
}
  }
.ListingPage_avatarMobile__O0-B5 {
  display: flex
}
@media (min-width: 768px) {
.ListingPage_avatarMobile__O0-B5 {
    display: none
    /* Hide the medium avatar from the bigger screens */
}
  }
.ListingPage_avatarDesktop__3r5AL {
  display: none
  /* Hide the large avatar from the smaller screens */
}
@media (min-width: 768px) {
.ListingPage_avatarDesktop__3r5AL {
    display: flex
}
  }
.ListingPage_sectionHeading__1kxx0 {
  margin-top: 22px;
  margin-bottom: 34px
}
@media (min-width: 768px) {
.ListingPage_sectionHeading__1kxx0 {
    display: flex;
    margin-bottom: 31px
}
  }
.ListingPage_sectionHeading__1kxx0 .ListingPage_heading__2LoXY {
    width: 100%
  }
.ListingPage_sectionHeading__1kxx0 .ListingPage_heading__2LoXY .ListingPage_bookmarkContainer__1DKra {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }
.ListingPage_desktopPriceContainer__3pJUt {
  display: none
}
@media (min-width: 1024px) {
.ListingPage_desktopPriceContainer__3pJUt {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0
}
  }
.ListingPage_desktopPriceValue__2FLH3 {
  /* Font */
  font-weight: var(--fontWeightSemiBold);
  font-size: 22px;
  line-height: 100%;
  letter-spacing: -0.5px;
  margin: 0;
  font-weight: var(--fontWeightBold)
}
@media (min-width: 768px) {
.ListingPage_desktopPriceValue__2FLH3 {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.9px;
  margin: 0
}
    }
.ListingPage_desktopPriceValue__2FLH3 {
  color: var(--marketplaceColor)
}
@media (min-width: 768px) {
.ListingPage_desktopPriceValue__2FLH3 {
    margin-top: 0;
    margin-bottom: 1px
}
  }
.ListingPage_desktopPerUnit__1S3eF {
  /* Font */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}
@media (min-width: 768px) {
.ListingPage_desktopPerUnit__1S3eF {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }
.ListingPage_desktopPerUnit__1S3eF {
  color: var(--matterColor)
}
@media (min-width: 768px) {
.ListingPage_desktopPerUnit__1S3eF {
    margin-top: 0;
    margin-bottom: 0
}
  }
.ListingPage_heading__2LoXY {
  margin-bottom: 33px;
  padding: 0px
}
@media (min-width: 768px) {
.ListingPage_heading__2LoXY {
    margin-bottom: 0;
    padding: 0
}
  }
@media (min-width: 1024px) {
.ListingPage_heading__2LoXY {
    margin-bottom: 0;
    padding: 0 24px
}
  }
.ListingPage_title__25K_B {
  font-weight: var(--fontWeightSemiBold);
  font-size: 22px;
  line-height: 100%;
  letter-spacing: -0.5px;
  margin: 0;
  font-weight: var(--fontWeightBold)
}
@media (min-width: 768px) {
.ListingPage_title__25K_B {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.9px;
  margin: 0
}
    }
.ListingPage_title__25K_B {
  color: var(--matterColorDark);
  font-weight: var(--fontWeightSemiBold);
  line-height: 135%;
  width: 100%;
  margin-top: 0;
  margin-bottom: 8px
}
@media (min-width: 768px) {
.ListingPage_title__25K_B {
    margin-top: 0;
    margin-bottom: 2px
}
  }
.ListingPage_author__2gkOm {
  width: 100%;
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}
@media (min-width: 768px) {
.ListingPage_author__2gkOm {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }
.ListingPage_author__2gkOm {
  margin-top: 7px;
  margin-bottom: 0
}
@media (min-width: 768px) {
.ListingPage_author__2gkOm {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor)
}
  }
.ListingPage_authorNameLink__2LDBs {
  color: var(--matterColor);
}
.ListingPage_contactWrapper__36eJT {
  display: none
}
@media (min-width: 768px) {
.ListingPage_contactWrapper__36eJT {
    display: inline
}
  }
.ListingPage_separator__3gmYb {
  margin: 0 6px;
}
.ListingPage_contactLink__2ksmf {
    /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

    /* Borders */
  border: none;

    /* Colors */
  color: var(--marketplaceColor);

    /* Text size should be inherited */
  text-decoration: none;
}
.ListingPage_contactLink__2ksmf:hover {
  text-decoration: underline;
  cursor: pointer;
    }
.ListingPage_contactLink__2ksmf {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}
@media (min-width: 768px) {
.ListingPage_contactLink__2ksmf {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px;
}
    }
.ListingPage_contactLink__2ksmf {
  margin: 0;
}
.ListingPage_sectionDescription__1r_ac {
  padding: 0px;
  margin-bottom: 36px
}
@media (min-width: 768px) {
.ListingPage_sectionDescription__1r_ac {
    padding: 0;
    margin-bottom: 40px
}
  }
.ListingPage_sectionTitle__1GoEc {
  /* Font for sections titles */
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 16px;
  margin-bottom: 14px;
}
@media (min-width: 768px) {
.ListingPage_sectionTitle__1GoEc {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 16px;
  margin-bottom: 16px;
}
    }
.ListingPage_sectionTitle__1GoEc {
  color: var(--matterColorDark);
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.11px;
}
.ListingPage_detailsTitle__1zBtu,
.ListingPage_descriptionTitle__1P4sw {
  margin-top: 0;
  margin-bottom: 12px
}
@media (min-width: 768px) {
.ListingPage_detailsTitle__1zBtu,
.ListingPage_descriptionTitle__1P4sw {
    margin-top: 0;
    margin-bottom: 8px
}
  }
.ListingPage_description__3JpOG {
  padding: 1px 0 5px 0;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: var(--fontWeightRegular);

  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line
}
@media (min-width: 768px) {
.ListingPage_description__3JpOG {
    padding: 3px 0 5px 0;
    margin-top: 0;
    margin-bottom: 0
}
  }
.ListingPage_sectionDetails__3NlVB {
  padding: 0 24px;
  margin-bottom: 32px
}
@media (min-width: 768px) {
.ListingPage_sectionDetails__3NlVB {
    padding: 0;
    margin-bottom: 40px
}
  }
.ListingPage_details__3seWa {
  padding-bottom: 1px;
  margin: 0;
}
.ListingPage_detailsRow__2jVMM {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 1px 0 4px 0;
  line-height: 36px;
  list-style: none;
  font-weight: var(--fontWeightRegular)
}
.ListingPage_detailsRow__2jVMM:not(:last-child) {
    border-bottom: 1px solid var(--matterColorNegative);
  }
@media (min-width: 768px) {
.ListingPage_detailsRow__2jVMM {
    padding: 7px 0 0px 0;
    line-height: 40px
}
  }
.ListingPage_detailLabel___O8bX {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.09px;
}
.ListingPage_sectionFeatures__19m57 {
  padding: 0 24px;
  margin-bottom: 32px
}
@media (min-width: 768px) {
.ListingPage_sectionFeatures__19m57 {
    padding: 0;
    margin-bottom: 52px
}
  }
.ListingPage_featuresTitle__1Z5cf {

  margin-top: 0;
  margin-bottom: 16px
}
@media (min-width: 768px) {
.ListingPage_featuresTitle__1Z5cf {
    margin-top: 0;
    margin-bottom: 12px
}
  }
.ListingPage_sectionMap__3pMXN {
  padding: 0px;
  margin-bottom: 35px;
  margin-top: 20px;
  float: left;
  width: 100%
}
@media (min-width: 768px) {
.ListingPage_sectionMap__3pMXN {
    padding: 0;
    margin-bottom: 0
}
  }
@media (min-width: 1024px) {
.ListingPage_sectionMap__3pMXN {
    margin-bottom: 0
}
  }
.ListingPage_locationTitle__31Dck {
  color: var(--marketplaceColor);
  font-size: 18px;

  margin-top: 0;
  margin-bottom: 10px
}
@media (min-width: 768px) {
.ListingPage_locationTitle__31Dck {
    margin-top: 0;
    margin-bottom: 14px
}
  }
.ListingPage_sectionReviews__1MGWl {
  padding: 0px;
  margin-bottom: 5px
}
@media (min-width: 768px) {
.ListingPage_sectionReviews__1MGWl {
    padding: 0
}
  }
@media (min-width: 1024px) {
.ListingPage_sectionReviews__1MGWl {
    padding-bottom: 5px;
    margin-bottom: 0
}
  }
.ListingPage_reviewsTitle__2-Uam {

  margin: 0px 0 20px 0
}
@media (min-width: 768px) {
.ListingPage_reviewsTitle__2-Uam {
    margin: 0px 0 27px 0
}
  }
@media (min-width: 1024px) {
.ListingPage_reviewsTitle__2-Uam {
    padding: 5px 0 3px 0;
    margin: 0px 0 26px 0
}
  }
.ListingPage_sectionAuthor__1xmIM {
  position: relative;
  padding: 0px;
  margin-bottom: 5px
}
@media (min-width: 1024px) {
.ListingPage_sectionAuthor__1xmIM {
    margin-bottom: 3px
}
  }
.ListingPage_aboutProviderTitle__1UQSA {

  margin: 54px 0 23px 0
}
@media (min-width: 768px) {
.ListingPage_aboutProviderTitle__1UQSA {
    margin: 53px 0 27px 0
}
  }
@media (min-width: 1024px) {
.ListingPage_aboutProviderTitle__1UQSA {
    margin: 64px 0 24px 0;
    padding: 5px 0 3px 0
}
  }
.ListingPage_enquiryModalContent__3g25N {
  flex-grow: 1;
  display: flex;
}
.ListingPage_enquiryForm__2s7IU {
  flex-grow: 1;
  margin: 70px 0 100px 0
}
@media (min-width: 768px) {
.ListingPage_enquiryForm__2s7IU {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0
}
  }
.ListingPage_enquirySubmitButtonWrapper__31PLx {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight)
}
@media (min-width: 768px) {
.ListingPage_enquirySubmitButtonWrapper__31PLx {
    position: static;
    padding: 0;
    margin-top: auto
}
  }
.ListingPage_map__17kEZ {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  width: 100%;
  /* Static map: dimensions are 640px */
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;
  height: 300px
}
@media (min-width: 768px) {
.ListingPage_map__17kEZ {
    height: 300px
}
  }
@media (min-width: 1024px) {
.ListingPage_map__17kEZ {
    max-width: 640px;
    height: 300px
}
  }
.ListingPage_map__17kEZ>div {
    max-width: 100% !important
  }
.ListingPage_map__17kEZ>div>img {
      width: 100%;
    }
@media (min-width: 1024px) {
.ListingPage_map__17kEZ>div {
  }}
.ListingPage_longWord__3sxKr {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}
.ListingPage_productGallery__1ZT5_ {
  margin: 0
}
@media (min-width: 768px) {
.ListingPage_productGallery__1ZT5_ {
    margin: 24px 0 48px 0
}
  }
@media (min-width: 1024px) {
.ListingPage_productGallery__1ZT5_ {
    margin: 0 0 72px 0;
    padding-bottom: 4px
}
  }
@media (min-width: 1024px) {
.ListingPage_productMobileHeading__MG4GX {
    display: none
}
  }
.ListingPage_productDesktopHeading__3WWQD {
  display: none
}
@media (min-width: 1024px) {
.ListingPage_productDesktopHeading__3WWQD {
    display: block
}
  }
.ListingPage_productSideContent__1UHjN {}
@media (min-width: 1024px) {
.ListingPage_productBookingPanel__33L8F {
    display: block;
    margin-top: 24px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0
}
  }
.ListingPage_bookmarkContainer__1DKra {}
.ListingPage_bookmark__3BIC3>svg {
    width: 20px;
  }
.ListingPage_first__2n5su {
  display: flex;
  justify-content: space-between;
}
.ListingPage_second__107VU {
  display: flex;
  justify-content: space-between;
}
.ListingPage_priceFld__Zfpuy {
  margin-bottom: 20px;
  width: 100%;
}
.ListingPage_desktopReviewRating__XtJ0m {}
& .ListingPage_reviewRatingStar__1ymqD {
  height: 20px;
  width: 20px;
  margin-right: 2px
}
& .ListingPage_reviewRatingStar__1ymqD:last-of-type {
    margin-right: 0;
  }
.ListingPage_reviews__2e5yw>h2 {
    color: var(--matterColorDark);
    font-weight: var(--fontWeightSemiBold);
    letter-spacing: -0.11px;
    font-size: 18px;
    line-height: 24px;
  }
.ListingPage_reviews__2e5yw .ListingPage_desktopReviewRating__XtJ0m .ListingPage_reviewRatingStar__1ymqD {
      height: 20px;
      width: 20px;
      margin-right: 2px
    }
.ListingPage_reviews__2e5yw .ListingPage_desktopReviewRating__XtJ0m .ListingPage_reviewRatingStar__1ymqD:last-of-type {
        margin-right: 0;
      }
.ListingPage_priceError__2m2Vj{
  color: red;
  margin-bottom: 8px;
  margin-top: 0;
  font-size: 15px;
}
.ListingCard_root__2lBIo {
  display: flex;
  flex-direction: column;
  border: solid 1px #e7e7e7;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  z-index: 0;
  height: 100%
}

.ListingCard_root__2lBIo:hover {
    text-decoration: none;
  }

.ListingCard_listingCardBlock__1iejY {
  position: relative;
  height: 100%
}

.ListingCard_listingCardBlock__1iejY .ListingCard_favorite__2_FCQ {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgb(255 255 255 / 81%);
    /* z-index: 1; */
    border-radius: 100px;
    padding: 6px;
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer
  }

.ListingCard_listingCardBlock__1iejY .ListingCard_favorite__2_FCQ>span {
      line-height: 0
    }

.ListingCard_listingCardBlock__1iejY .ListingCard_favorite__2_FCQ>span>svg {
        width: 16px;
        height: 16px;
      }

.ListingCard_threeToTwoWrapper__27YXq {
  display: block;
  width: 100%;
  position: relative;
  transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 4ms;
  height: 200px;
  overflow: hidden
}

@media (min-width:1024px) {

.ListingCard_threeToTwoWrapper__27YXq {
    height: 230px
}
  }

@media (max-width: 767px) {

.ListingCard_threeToTwoWrapper__27YXq {
   height: 160px
}
  }

.ListingCard_threeToTwoWrapper__27YXq:hover {
    transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 4ms;
    box-shadow: var(--boxShadowListingCard)
  }

.ListingCard_threeToTwoWrapper__27YXq:hover img {
      -webkit-transform: scale(1.05);
              transform: scale(1.05);
      transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 4ms;
    }

.ListingCard_threeToTwoWrapper__27YXq .ListingCard_cardTag__2bS-N {
    background-color: rgb(255 255 255 / 81%);
    color: #333;
    font-size: 12px;
    position: absolute;
    bottom: 6px;
    z-index: 1;
    padding: 0px 18px;
    left: 6px;
    border-radius: 6px;
    z-index: 1;
  }

/* Firefox doesn't support image aspect ratio inside flexbox */

.ListingCard_aspectWrapper__OaeOx {
  background: var(--matterColorNegative);
  height: 100%
}

.ListingCard_aspectWrapper__OaeOx img {
    transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 4ms;
  }

.ListingCard_rootForImage__2Al2s {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ListingCard_info__3upOW {
  display: flex;
  flex-direction: column;
  padding: 10px
}

.ListingCard_info__3upOW .ListingCard_mainInfo__25O5i {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 6px 0
  }

.ListingCard_info__3upOW .ListingCard_mainInfo__25O5i .ListingCard_category__C1Ac0 {
      font-size: 14px;
      color: rgb(145 145 145);
      margin: 0;
    }

.ListingCard_info__3upOW .ListingCard_mainInfo__25O5i .ListingCard_title__3y9wD {
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 16px;
  margin-bottom: 14px
    }

@media (min-width: 768px) {

.ListingCard_info__3upOW .ListingCard_mainInfo__25O5i .ListingCard_title__3y9wD {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 16px;
  margin-bottom: 16px
    }
    }

.ListingCard_info__3upOW .ListingCard_mainInfo__25O5i .ListingCard_title__3y9wD {
      color: #282727;
      margin-top: 0;
      margin-bottom: 0;
      font-size: 15px;
      line-height: 130%;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden
    }

@media (min-width: 768px) {

.ListingCard_info__3upOW .ListingCard_mainInfo__25O5i .ListingCard_title__3y9wD {
        margin-top: 0;
        margin-bottom: 0
    }
      }

.ListingCard_info__3upOW .ListingCard_mainInfo__25O5i .ListingCard_itemPrice__3cd0w {
      font-size: 13px;
      line-height: 110%;
    }

.ListingCard_info__3upOW .ListingCard_mainInfo__25O5i .ListingCard_certificateInfo__1oMpJ {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased
    }

@media (min-width: 768px) {

.ListingCard_info__3upOW .ListingCard_mainInfo__25O5i .ListingCard_certificateInfo__1oMpJ {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px
    }
    }

.ListingCard_info__3upOW .ListingCard_mainInfo__25O5i .ListingCard_certificateInfo__1oMpJ {
      color: var(--matterColor);
      margin-top: 0;
      margin-bottom: 0
    }

@media (min-width: 768px) {

.ListingCard_info__3upOW .ListingCard_mainInfo__25O5i .ListingCard_certificateInfo__1oMpJ {
        margin-top: 0;
        margin-bottom: 0
    }
      }

.ListingCard_info__3upOW .ListingCard_itemDiscount__11nL3 {
    margin-bottom: 2px
  }

.ListingCard_info__3upOW .ListingCard_itemDiscount__11nL3 .ListingCard_itemModal__Xrgtv {
      font-size: 14px;
      color: #949494;
      line-height: 111%;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start
    }

.ListingCard_info__3upOW .ListingCard_itemDiscount__11nL3 .ListingCard_itemModal__Xrgtv>span {
        margin-right: 4px;
        font-weight: 400;
      }

.ListingCard_info__3upOW .ListingCard_itemDescription__3r4bR {
    margin-bottom: 8px
  }

.ListingCard_info__3upOW .ListingCard_itemDescription__3r4bR>p {
      font-size: 14px;
      color: rgb(145 145 145);
      line-height: 111%;
      margin: 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

.ListingCard_info__3upOW .ListingCard_price__2M7uQ {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-shrink: 0
  }

.ListingCard_info__3upOW .ListingCard_price__2M7uQ .ListingCard_priceValue__3FOmG {
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 16px;
  margin-bottom: 14px
    }

@media (min-width: 768px) {

.ListingCard_info__3upOW .ListingCard_price__2M7uQ .ListingCard_priceValue__3FOmG {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 16px;
  margin-bottom: 16px
    }
    }

.ListingCard_info__3upOW .ListingCard_price__2M7uQ .ListingCard_priceValue__3FOmG {
      margin-top: 0;
      margin-bottom: 0
    }

@media (min-width: 768px) {

.ListingCard_info__3upOW .ListingCard_price__2M7uQ .ListingCard_priceValue__3FOmG {
        margin-top: 0;
        margin-bottom: 0
    }
      }

.ListingCard_info__3upOW .ListingCard_price__2M7uQ .ListingCard_perUnit__2W3EZ {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased
    }

@media (min-width: 768px) {

.ListingCard_info__3upOW .ListingCard_price__2M7uQ .ListingCard_perUnit__2W3EZ {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px
    }
    }

.ListingCard_info__3upOW .ListingCard_price__2M7uQ .ListingCard_perUnit__2W3EZ {
      color: var(--matterColor);
      margin-top: 0;
      margin-bottom: 0
    }

@media (min-width: 768px) {

.ListingCard_info__3upOW .ListingCard_price__2M7uQ .ListingCard_perUnit__2W3EZ {
        margin-top: 0;
        margin-bottom: 0
    }
      }

.ListingCard_longWord__3GehS {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

.ListingCard_make__3WlN5 {
  display: flex;
  justify-content: space-between;
  padding: 0px 21px 8px 21px;
}

.ListingCard_category__C1Ac0 {
  color: #282727;
  font-size: 13px;
}
.Map_root__RzSUy {
  width: 100%;
  height: 100%;
  background-color: var(--matterColorNegative);
}

.Map_mapRoot__P1_dA {
  width: 100%;
  height: 100%;
}

.Page_root__3SIRI {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  padding-bottom: 136px
}

@media (min-width:320px) {

.Page_root__3SIRI {
    padding-bottom: 100px
}
  }

@media (min-width: 768px) {

.Page_root__3SIRI {
    padding-bottom: 90px
}
  }

.Page_searchRoot__GStuU {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  padding-bottom: 136px
}

@media (max-width:767px) {

.Page_searchRoot__GStuU {
    padding-bottom: 0px
}
  }

@media (min-width: 768px) {

.Page_searchRoot__GStuU {
    padding-bottom: 90px
}
  }

.Page_content__2COkr {}

.Page_scrollingDisabled__3F51e {
  /* position: fixed and width are added to prevent iOS from scrolling content */
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.Reviews_root__7F-sW {
  margin: 0;
}

.Reviews_reviewItem__3_xUn {
  margin-bottom: 32px
}

.Reviews_reviewItem__3_xUn:last-of-type {
    margin-bottom: 0;
  }

@media (min-width: 768px) {

.Reviews_reviewItem__3_xUn {
    margin-bottom: 39px
}
  }

.Reviews_review__3xYhq {
  display: flex;
  flex-direction: row;
  flex-basis: 564px;
  flex-shrink: 1;
  flex-grow: 0;
}

.Reviews_avatar__3cj5A {
  flex-shrink: 0;
  margin-right: 22px;
}

.Reviews_reviewContent__2fs35 {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.Reviews_reviewContent__2fs35 {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.Reviews_reviewContent__2fs35 {
  font-style: italic;
  margin: 0;
  white-space: pre-line;
}

.Reviews_reviewInfo__3Ty8T {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.Reviews_reviewInfo__3Ty8T {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.Reviews_reviewInfo__3Ty8T {
  margin-top: 10px;
  margin-bottom: 0px;
  color: var(--matterColorAnti)
}

@media (min-width: 768px) {

.Reviews_reviewInfo__3Ty8T {
    margin-top: 9px
}
  }

.Reviews_separator__3fvdC {
  margin: 0 7px;
}

.Reviews_desktopSeparator__WFWUH {
  margin: 0 7px;
  display: none
}

@media (min-width: 768px) {

.Reviews_desktopSeparator__WFWUH {
    display: inline
}
  }

.Reviews_mobileReviewRating__MzuZ- {
  display: block;
  margin-top: 3px;
  margin-bottom: 9px
}

@media (min-width: 768px) {

.Reviews_mobileReviewRating__MzuZ- {
    display: none
}
  }

.Reviews_desktopReviewRatingWrapper__2dnIG {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  position: relative;
  display: none
}

@media (min-width: 768px) {

.Reviews_desktopReviewRatingWrapper__2dnIG {
    display: inline
}
  }

.Reviews_desktopReviewRating__2fsKF {
  /* For aligning rating starts in the middle
   * of a paragraph line */
  display: inline-flex;
  position: absolute;
  top: 4px;
  white-space: nowrap;
}

.Reviews_reviewRatingStar__3Uflt {
  height: 12px;
  width: 12px;
  margin-right: 2px
}

.Reviews_reviewRatingStar__3Uflt:last-of-type {
    margin-right: 0;
  }

.SavedCardDetails_root__1PHsm {}

.SavedCardDetails_savedPaymentMethodTitle__UzbFK {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.SavedCardDetails_savedPaymentMethodTitle__UzbFK {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.SavedCardDetails_savedPaymentMethodTitle__UzbFK {
  font-weight: bold;
  cursor: pointer;
}

.SavedCardDetails_savedPaymentMethod__3Zdqd {
  display: inline-block;
  margin-right: auto;
}

.SavedCardDetails_paymentMethodPlaceholderDesktop__39Yl3 {
  display: none;
}

.SavedCardDetails_paymentMethodPlaceholderMobile__2zL3z {
  display: inline-block;
}

@media (min-width: 550px) {
  .SavedCardDetails_paymentMethodPlaceholderDesktop__39Yl3 {
    display: inline-block;
  }

  .SavedCardDetails_paymentMethodPlaceholderMobile__2zL3z {
    display: none;
  }
}

.SavedCardDetails_cardIcon__3AsvU {
  margin: 0 12px 0 4px;
  width: 24px;
  height: 16px;
  display: inline-block;
}

.SavedCardDetails_closeIcon__10fjj {
  display: inline-block;
  margin-left: 8px;

    /* Move X icon vertically to align it with the close text. */
  margin-top: 3px;

  box-sizing: content-box;
}

@media (min-width: 768px) {

.SavedCardDetails_closeIcon__10fjj {
  margin-top: 2px;
}
    }

.SavedCardDetails_closeIcon__10fjj {
  margin-left: 0;
  margin-right: 5px;
}

.SavedCardDetails_iconCheckmark__ek61Q {
  stroke: var(--successColor);
  display: inline-block;
  margin: 12px 0 0 24px;
}

.SavedCardDetails_iconCheckmarkHidden__3vCrx {
  visibility: hidden;
  display: inline-block;
  margin: 0 0 0 24px;
}

.SavedCardDetails_expirationDate__2bCy5 {
  padding-left: 24px;
}

.SavedCardDetails_expirationDateExpired__2MmQN {
  padding-left: 24px;
  color: var(--failColor);
}

.SavedCardDetails_cardExpiredText__M0rd1 {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.SavedCardDetails_cardExpiredText__M0rd1 {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.SavedCardDetails_cardExpiredText__M0rd1 {
  color: var(--failColor);
  font-weight: var(--fontWeightSemiBold);
  margin-top: 13px;
  margin-bottom: 11px;
}

.SavedCardDetails_menu__yhxcr {
  max-width: 100%;
  height: auto;
}

.SavedCardDetails_menuLabel__1tY1P {
  text-align: left;
  font-weight: 500;
  padding: 0;
  border-radius: 4px;
  border: 1px solid var(--matterColorNegative);
  transition: all 0.2s ease-out;
  z-index: calc(var(--zIndexPopup) + 1);
  position: relative
}

.SavedCardDetails_menuLabel__1tY1P:hover {
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorAnti);
  }

.SavedCardDetails_menuLabel__1tY1P:focus {
    outline: none;
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    text-decoration: none;
    border-radius: 4px 4px 0px 0px;
  }

.SavedCardDetails_menuLabelWrapper__2BEvd,
.SavedCardDetails_menuLabelWrapperExpired__2wsgY {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 12px 24px 10px 16px;
}

.SavedCardDetails_menuLabelWrapperExpired__2wsgY {
  border: 1px solid var(--failColor);
}

.SavedCardDetails_menuContent__1PuJi {
  position: absolute;
  z-index: var(--zIndexPopup);

  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowFilterButton);
  border-radius: 4px;
  border: 1px solid var(--matterColorNegative);

  transition: all 0.15s ease-out;

  /* When the dropdown is open, we need to hack the box-shadow's position
    to fit the whole dropdown menu component with padding-top

    MenuLabel height 56px + original padding-top 7px results to 63px
  */
  padding: 63px 0 15px 0;
  top: 0px;
}

.SavedCardDetails_menuItem__2fYw3 {
  color: var(--matterColor)
}

.SavedCardDetails_menuItem__2fYw3:hover {
    background-color: #f2f2f2;
  }

.SavedCardDetails_menuText__1RJ25,
.SavedCardDetails_menuTextReplaceCard__1c_2k {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  line-height: 24px;
  padding: 12px 8px;
  color: var(--matterColor);
  text-align: left;
  font-weight: 500;
  width: 80%
}

.SavedCardDetails_menuText__1RJ25:hover, .SavedCardDetails_menuTextReplaceCard__1c_2k:hover {
    text-decoration: none;
  }

.SavedCardDetails_menuText__1RJ25:focus, .SavedCardDetails_menuTextReplaceCard__1c_2k:focus {
    outline: 0;
    background-color: #f2f2f2;
  }

.SavedCardDetails_menuTextReplaceCard__1c_2k svg {
  position: relative;
  top: -2px;
  width: 22px;
  margin: 0 10px 0 4px;
}

.SavedCardDetails_menuDivider__3vuk2 {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}

@media (min-width: 768px) {

.SavedCardDetails_menuDivider__3vuk2 {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px;
}
    }

.SavedCardDetails_menuDivider__3vuk2 {
  color: var(--matterColorAnti);
  padding: 14px 24px 2px 24px;
  margin: 0;
}

.SavedCardDetails_iconArrow__1GnyS {
  stroke: var(--matterColor);
  fill: var(--matterColor);
  transition: all 0.2s ease-out;
}

.SavedCardDetails_IconArrowAnimation__X_Zls {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.SavedCardDetails_savedPaymentMethodDelete__1Yy0V {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.SavedCardDetails_savedPaymentMethodDelete__1Yy0V {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.SavedCardDetails_savedPaymentMethodDelete__1Yy0V {
  color: var(--matterColorAnti);
  cursor: pointer;
  margin-top: 14px;
  margin-bottom: 10px
}

.SavedCardDetails_savedPaymentMethodDelete__1Yy0V:hover {
    color: var(--matterColor);
  }

.SavedCardDetails_savedPaymentMethodDelete__1Yy0V:focus {
    outline: none;
    color: var(--matterColor);
  }

.SavedCardDetails_modalButtonsWrapper__3ks9q {
  padding-top: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

/* Title of the modal */

.SavedCardDetails_modalTitle__1VL2g {
  font-weight: var(--fontWeightSemiBold);
  font-size: 22px;
  line-height: 100%;
  letter-spacing: -0.5px;
  margin: 0;
  font-weight: var(--fontWeightBold);
}

@media (min-width: 768px) {

.SavedCardDetails_modalTitle__1VL2g {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.9px;
  margin: 0;
}
    }

.SavedCardDetails_modalTitle__1VL2g {
  padding-top: 36px;
}

/* Paragraph for the Modal */

.SavedCardDetails_modalMessage__9bZZU {
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  line-height: 24px;
  margin: 18px 0 0 0;
}

@media (min-width: 768px) {

.SavedCardDetails_modalMessage__9bZZU {
  font-weight: var(--fontWeightRegular);
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.11px;
  margin: 24px 0 0 0;
}
    }

.SavedCardDetails_modalMessage__9bZZU {
  min-height: 200px;
}

.SavedCardDetails_cancelCardDelete__1swhv {
  color: var(--matterColorAnti);
  cursor: pointer;
  width: 50%;
  text-align: right;
  padding-right: 64px
}

.SavedCardDetails_cancelCardDelete__1swhv:hover {
    color: var(--matterColor);
  }
.StripeConnectAccountStatusBox_root__A3mvv {
}

.StripeConnectAccountStatusBox_verificiationBox__1AiG5 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 15px 32px 23px 32px;
  margin-bottom: 24px;

  border-radius: 4px;
}

@media (min-width: 768px) {
  .StripeConnectAccountStatusBox_verificiationBox__1AiG5 {
    flex-wrap: nowrap;
  }
}

.StripeConnectAccountStatusBox_verificiationBoxTextWrapper__3-G8Z {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 24px 3px 0px;
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .StripeConnectAccountStatusBox_verificiationBoxTextWrapper__3-G8Z {
    margin-bottom: 0px;
  }
}

.StripeConnectAccountStatusBox_verificationBoxTitle__395sn {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.StripeConnectAccountStatusBox_verificationBoxTitle__395sn {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.StripeConnectAccountStatusBox_verificationBoxTitle__395sn {
  font-weight: var(--fontWeightSemiBold);

  align-self: center;

  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.StripeConnectAccountStatusBox_verificationBoxText__BOMv1 {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.StripeConnectAccountStatusBox_verificationBoxText__BOMv1 {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.StripeConnectAccountStatusBox_verificationBoxText__BOMv1 {
  font-weight: var(--fontWeightRegular);
  margin-top: 0;
  margin-bottom: 0;
}

.StripeConnectAccountStatusBox_getVerifiedButton__2pVfj {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}

@media (min-width: 768px) {

.StripeConnectAccountStatusBox_getVerifiedButton__2pVfj {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px;
}
    }

.StripeConnectAccountStatusBox_getVerifiedButton__2pVfj {
  font-weight: var(--fontWeightSemiBold);

  border-radius: 4px;
  min-height: 38px;
  width: 300px;
  max-width: 110px;
}

/* Verification required box */

.StripeConnectAccountStatusBox_verficiationNeededBox__2oqy3 {
  border: 1px solid var(--attentionColor);
  background: var(--attentionColorLight);
}

/* Verification error box */

.StripeConnectAccountStatusBox_verficiationErrorBox__z5vHi {
  background: var(--failColorLight);
  border: 1px solid var(--failColor);
}

/* Verification success box */

.StripeConnectAccountStatusBox_verficiationSuccessBox__2n-Bn {
  background: var(--successColorLight);
  border: 1px solid var(--successColor);
  padding: 8px 24px;
}

.StripeConnectAccountStatusBox_verificationBoxSuccessTextWrapper__FOCD3 {
  margin-bottom: 0;
}

.StripeConnectAccountStatusBox_editVerificationButton__8Lgt_ {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.StripeConnectAccountStatusBox_editVerificationButton__8Lgt_ {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.StripeConnectAccountStatusBox_editVerificationButton__8Lgt_ {
  color: var(--matterColor);
  font-weight: var(--fontWeightNormal);
  min-height: 46px;
  margin: 0;
  padding-top: 3px
}

.StripeConnectAccountStatusBox_editVerificationButton__8Lgt_:hover,
  .StripeConnectAccountStatusBox_editVerificationButton__8Lgt_:focus {
    outline: none;
    color: var(--matterColorDark);
  }

.StripeConnectAccountStatusBox_icon__3q6Ik {
  margin-right: 10px;
}

.StripeConnectAccountStatusBox_iconEditPencil__3WlLg {
  stroke: var(--matterColor);
}

.StripeConnectAccountStatusBox_spinner__3-TVC {
  width: 24px;
  height: 24px;
  stroke: var(--successColor);
  stroke-width: 3px;
}

.StripePaymentAddress_root__yY89e {
  display: flex;
  flex-direction: column;
}

.StripePaymentAddress_formRow__syhPP {
  /* This container uses flexbox layout */
  display: flex;
  justify-content: space-between;
  width: 100%;

  /* parent aka root is flexbox, this container takes all available space */
  flex-grow: 1;
  flex-shrink: 0;
  flex-wrap: wrap
}

@media (min-width: 550px) {

.StripePaymentAddress_formRow__syhPP {
    flex-wrap: no-wrap
}
  }

.StripePaymentAddress_field__1ZCeu {
  width: 100%;
  margin-top: 24px
}

@media (min-width: 550px) {

.StripePaymentAddress_field__1ZCeu {
    width: calc(50% - 12px)
}
  }

.UserCard_root__3B18H {}

.UserCard_content__2Sf_8 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.UserCard_avatar__3jfFO {
  flex-shrink: 0;
  margin-right: 16px;
  width: 56px;
  height: 56px
}

.UserCard_avatar__3jfFO>span {
    font-size: 20px !important;
  }

@media (min-width: 768px) {

.UserCard_avatar__3jfFO {
    margin-right: 26px;
    width: 96px;
    height: 96px
}

    .UserCard_avatar__3jfFO>span {
      font-size: 30px !important;
    }
  }

.UserCard_info__1Vve4 {
  flex: 1 1;
}

.UserCard_heading__2RXPF {
  font-weight: var(--fontWeightSemiBold);
  font-size: 21px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 21px;
  margin-bottom: 17px
}

@media (min-width: 768px) {

.UserCard_heading__2RXPF {
  line-height: 32px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 21px;
  margin-bottom: 19px
}
    }

.UserCard_heading__2RXPF {
  margin: 0
}

@media (min-width: 768px) {

.UserCard_heading__2RXPF {
    margin: 0
}
  }

.UserCard_link__3V5L4 {
  white-space: nowrap;
}

.UserCard_mobileBio__37KkS,
.UserCard_desktopBio__3avZD {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 18px 0 0 0;
  transition: all 1s ease-in;
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.UserCard_mobileBio__37KkS,
.UserCard_desktopBio__3avZD {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

@media (min-width: 768px) {

.UserCard_mobileBio__37KkS,
.UserCard_desktopBio__3avZD {
    margin: 0
}
  }

@media (min-width: 768px) {

.UserCard_mobileBio__37KkS {
    display: none
}
  }

.UserCard_desktopBio__3avZD {
  display: none
}

@media (min-width: 768px) {

.UserCard_desktopBio__3avZD {
    display: block
}
  }

.UserCard_showMore__V22y- {
    /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

    /* Borders */
  border: none;

    /* Colors */
  color: var(--marketplaceColor);

    /* Text size should be inherited */
  text-decoration: none;
}

.UserCard_showMore__V22y-:hover {
  text-decoration: underline;
  cursor: pointer;
    }

.UserCard_showMore__V22y- {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.UserCard_showMore__V22y- {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.UserCard_showMore__V22y- {
  margin: 0 0 0 5px;
}

.UserCard_links__2fwaw {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.UserCard_links__2fwaw {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.UserCard_links__2fwaw {
  margin: 0px;
}

@media (min-width: 768px) {

.UserCard_withBioMissingAbove__1qnGZ {
    /* Fix baseline alignment if bio is missing from above */
    margin: 0px
}
  }

.UserCard_linkSeparator__3zFQW {
  margin: 0 10px;
  color: var(--marketplaceColor);
}

.UserCard_headingRow__3eciZ {
  display: flex;
  flex-direction: column
}

@media (min-width: 768px) {

.UserCard_headingRow__3eciZ {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline
}
  }

.UserCard_editProfileDesktop__1yfb0 {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.UserCard_editProfileDesktop__1yfb0 {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.UserCard_editProfileDesktop__1yfb0 {
  display: none;
  margin-bottom: 0;
  white-space: nowrap
}

@media (min-width: 768px) {

.UserCard_editProfileDesktop__1yfb0 {
    display: inline
}
  }

.UserCard_editProfileMobile__1owq3 {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.UserCard_editProfileMobile__1owq3 {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.UserCard_editProfileMobile__1owq3 {
  display: inline
}

@media (min-width: 768px) {

.UserCard_editProfileMobile__1owq3 {
    display: none
}
  }

.UserCard_contact__2jPz0 {
    /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

    /* Borders */
  border: none;

    /* Colors */
  color: var(--marketplaceColor);

    /* Text size should be inherited */
  text-decoration: none;
}

.UserCard_contact__2jPz0:hover {
  text-decoration: underline;
  cursor: pointer;
    }

.UserCard_contact__2jPz0 {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.UserCard_contact__2jPz0 {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.UserCard_contact__2jPz0 {
  margin: 0;
}

.UserCard_orderUserCard__FaxjP {
  margin-top: 20px
}

.UserCard_orderUserCard__FaxjP .UserCard_avatar__3jfFO {
    flex-shrink: 0;
    margin-right: 16px;
    width: 56px;
    height: 56px
  }

.UserCard_orderUserCard__FaxjP .UserCard_avatar__3jfFO>span {
      font-size: 20px !important;
    }

.UserCard_orderUserCard__FaxjP .UserCard_withBioMissingAbove__1qnGZ {
    display: none;
  }

.UserCard_orderUserCard__FaxjP .UserCard_heading__2RXPF {
    font-size: 16px;
    color: var(--matterColorDark);
    line-height: 100%;
    font-weight: 400;
  }

.UserCard_orderUserCard__FaxjP .UserCard_info__1Vve4 .UserCard_desktopBio__3avZD,
    .UserCard_orderUserCard__FaxjP .UserCard_info__1Vve4 .UserCard_links__2fwaw {
      display: none;
    }

.UserCard_desktopReviewRating__3EaCb{
  margin-left: 6px;
}
.StripeConnectAccountForm_root__1Y_CI {
  margin-top: 24px;
}

@media (min-width: 768px) {
  .StripeConnectAccountForm_root__1Y_CI {
    margin-top: 48px;
  }
}

.StripeConnectAccountForm_disabled__1LODI {
  opacity: 0.5;
}

.StripeConnectAccountForm_sectionContainer__2caoe {
  margin-bottom: 35px
}

@media (min-width: 768px) {

.StripeConnectAccountForm_sectionContainer__2caoe {
    margin-bottom: 56px
}
  }

.StripeConnectAccountForm_formRow__2sP6c {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;
}

.StripeConnectAccountForm_radioButtonRow__3OrIN {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 24px;
  white-space: nowrap;
}

.StripeConnectAccountForm_radioButtonRow__3OrIN> :first-child {
  margin-right: 36px;
}

.StripeConnectAccountForm_selectCountry__1tVXT {
  margin-bottom: 24px;
}

.StripeConnectAccountForm_error__2sACW {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.StripeConnectAccountForm_error__2sACW {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.StripeConnectAccountForm_error__2sACW {
  margin-top: 24px;
  color: var(--failColor);
}

.StripeConnectAccountForm_termsText__XiRfI {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased
}

@media (min-width: 768px) {

.StripeConnectAccountForm_termsText__XiRfI {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px
}
    }

.StripeConnectAccountForm_termsText__XiRfI {
  color: var(--matterColorAnti);
  margin: 0
}

@media (min-width: 768px) {

.StripeConnectAccountForm_termsText__XiRfI {
  margin: 0
}
    }

.StripeConnectAccountForm_termsText__XiRfI {
  margin-bottom: 12px
}

@media (min-width: 768px) {

.StripeConnectAccountForm_termsText__XiRfI {
    margin-bottom: 16px
}
  }

.StripeConnectAccountForm_termsLink__32__u {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased
}

@media (min-width: 768px) {

.StripeConnectAccountForm_termsLink__32__u {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px
}
    }

.StripeConnectAccountForm_termsLink__32__u {
  color: var(--matterColor);
  margin: 0
}

@media (min-width: 768px) {

.StripeConnectAccountForm_termsLink__32__u {
  margin: 0
}
    }

.StripeConnectAccountForm_termsLink__32__u:hover {
    text-decoration: underline;
    cursor: pointer;
  }

.StripeConnectAccountForm_bankDetailsStripeField__3ystW p {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.StripeConnectAccountForm_bankDetailsStripeField__3ystW p {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.StripeConnectAccountForm_missingStripeKey__g71Zp {
  color: var(--failColor);
}

.StripeConnectAccountForm_accountInformationTitle__2ny6- {
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 16px;
  margin-bottom: 14px;
}

@media (min-width: 768px) {

.StripeConnectAccountForm_accountInformationTitle__2ny6- {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 16px;
  margin-bottom: 16px;
}
    }

.StripeConnectAccountForm_accountInformationTitle__2ny6- {
  margin: 0;
  padding-top: 5px;
  padding-bottom: 1px;
}

@media (min-width: 768px) {
  .StripeConnectAccountForm_accountInformationTitle__2ny6- {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.StripeConnectAccountForm_savedCountry__1UFWy {
  padding-top: 6px;
  padding-bottom: 0px;
}

@media (min-width: 768px) {
  .StripeConnectAccountForm_savedCountry__1UFWy {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.StripeConnectAccountForm_savedInformation__gFVs8 {
  margin-bottom: 24px;
}

.StripeConnectAccountForm_savedBankAccount__3jvQb {
  width: 100%;
  border-bottom: 2px solid var(--successColor);
  margin-bottom: 24px;
  padding: 4px 0 10px 0;
  color: var(--matterColor);
  text-align: left
}

.StripeConnectAccountForm_savedBankAccount__3jvQb:hover {
    cursor: text;
    text-decoration: none;
    border-color: var(--matterColor);
  }

.StripeConnectAccountForm_savedBankAccount__3jvQb:focus {
    outline: none;
    border-color: var(--matterColor);
  }
.ModalMissingInformation_root__36VHT {
  /* Adding empty .root class to enforce className overwrite */
}

/* Title of the modal */

.ModalMissingInformation_modalTitle__3vqrJ {
  font-weight: var(--fontWeightSemiBold);
  font-size: 22px;
  line-height: 100%;
  letter-spacing: -0.5px;
  margin: 0;
  font-weight: var(--fontWeightBold);
}

@media (min-width: 768px) {

.ModalMissingInformation_modalTitle__3vqrJ {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.9px;
  margin: 0;
}
    }

/* Paragraph for the Modal */

.ModalMissingInformation_modalMessage__2QI_c {
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  line-height: 24px;
  margin: 18px 0 0 0;
}

@media (min-width: 768px) {

.ModalMissingInformation_modalMessage__2QI_c {
  font-weight: var(--fontWeightRegular);
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.11px;
  margin: 24px 0 0 0;
}
    }

/* Make the email pop */

.ModalMissingInformation_email__3FMA8 {
  font-weight: var(--fontWeightHighlightEmail);
}

.ModalMissingInformation_helperLink__2Rz5d {
    /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

    /* Borders */
  border: none;

    /* Colors */
  color: var(--marketplaceColor);

    /* Text size should be inherited */
  text-decoration: none;
}

.ModalMissingInformation_helperLink__2Rz5d:hover {
  text-decoration: underline;
  cursor: pointer;
    }

.ModalMissingInformation_helperLink__2Rz5d {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased;
}

@media (min-width: 768px) {

.ModalMissingInformation_helperLink__2Rz5d {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px;
}
    }

.ModalMissingInformation_helperLink__2Rz5d {
  color: var(--matterColor);
  margin: 0;
}

@media (min-width: 768px) {

.ModalMissingInformation_helperLink__2Rz5d {
  margin: 0;
}
    }

.ModalMissingInformation_helperText__15Rjz {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased;
}

@media (min-width: 768px) {

.ModalMissingInformation_helperText__15Rjz {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px;
}
    }

.ModalMissingInformation_helperText__15Rjz {
  color: var(--matterColorAnti);
  margin: 0;
}

@media (min-width: 768px) {

.ModalMissingInformation_helperText__15Rjz {
  margin: 0;
}
    }

.ModalMissingInformation_error__cxLBW {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.ModalMissingInformation_error__cxLBW {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.ModalMissingInformation_error__cxLBW {
  margin-top: 24px;
  color: var(--failColor);
}

.ModalMissingInformation_bottomWrapper__eY9X7 {
  text-align: center;
  margin-top: 60px;
  align-self: stretch;
}

@media (min-width: 768px) {

.ModalMissingInformation_bottomWrapper__eY9X7 {
  margin-top: 50px;
}
    }

.ModalMissingInformation_bottomWrapper__eY9X7 {

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.ModalMissingInformation_modalIcon__ZNM1z {
  height: 48px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.ModalMissingInformation_modalIcon__ZNM1z {
  height: 64px;
  margin-bottom: 23px;
}
    }

.ModalMissingInformation_reminderModalLinkButton__1ZoLv {
  font-family: 'Open Sans', sans-serif;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

@media (min-width: 768px) {

.ModalMissingInformation_reminderModalLinkButton__1ZoLv {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.ModalMissingInformation_reminderModalLinkButton__1ZoLv {
  border-radius: 4px;
  display: block;
  min-height: 45px;
  margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */

  padding: 8px 16px;
}

@media (min-width: 768px) {

.ModalMissingInformation_reminderModalLinkButton__1ZoLv {
  padding: 10px 20px;
}
    }

.ModalMissingInformation_reminderModalLinkButton__1ZoLv {

    /* Borders */
  border: none;
  border-radius: var(--borderRadius);

    /* Colors */
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

    /* Text */
  text-align: center;
  text-decoration: none;

    /* Effects */
  transition: all var(--transitionStyleButton);
  cursor: pointer;
}

.ModalMissingInformation_reminderModalLinkButton__1ZoLv:hover,
    .ModalMissingInformation_reminderModalLinkButton__1ZoLv:focus {
  outline: none;
  background-color: var(--marketplaceColorDark);
  text-decoration: none;
  box-shadow: var(--boxShadowButton);
    }

.ModalMissingInformation_reminderModalLinkButton__1ZoLv:disabled {
  background-color: var(--matterColorNegative);
  color: var(--matterColorLight);
  cursor: not-allowed;
  box-shadow: none;
    }

@media (min-width: 768px) {

.ModalMissingInformation_reminderModalLinkButton__1ZoLv {
  min-height: 50px;
}
    }

.ModalMissingInformation_reminderModalLinkButton__1ZoLv {

  background-color: #14100f;
  color: var(--matterColorLight);
}

.ModalMissingInformation_reminderModalLinkButton__1ZoLv:hover {
  background-color: #14100f;
  color: var(--matterColorLight);
    }

.ModalMissingInformation_reminderModalLinkButton__1ZoLv:focus {
  background-color: var(--successColorDark);
    }

.ModalMissingInformation_reminderModalLinkButton__1ZoLv:disabled {
  background-color: var(--matterColorNegative);
  color: var(--matterColor);
    }


  .PrivacyPolicy_root__2pBDF p {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
  }
@media (min-width: 768px) {
  .PrivacyPolicy_root__2pBDF p {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
  }
    }
.PrivacyPolicy_root__2pBDF h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 29px 0 13px 0
  }
@media (min-width: 768px) {
  .PrivacyPolicy_root__2pBDF h2 {
      margin: 32px 0 0 0
  }
    }
.PrivacyPolicy_root__2pBDF .PrivacyPolicy_lastUpdated__2zDLP {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
  }
@media (min-width: 768px) {
  .PrivacyPolicy_root__2pBDF .PrivacyPolicy_lastUpdated__2zDLP {
  font-size: 16px;
  line-height: 28px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 16px;
  margin-bottom: 16px
  }
    }
.PrivacyPolicy_root__2pBDF .PrivacyPolicy_lastUpdated__2zDLP {
    margin-top: 0;
    margin-bottom: 55px
  }
@media (min-width: 768px) {
  .PrivacyPolicy_root__2pBDF .PrivacyPolicy_lastUpdated__2zDLP {
      margin-top: 0;
      margin-bottom: 54px
  }
    }


  .TermsOfService_root__YB9-b p {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
  }
@media (min-width: 768px) {
  .TermsOfService_root__YB9-b p {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
  }
    }
.TermsOfService_root__YB9-b h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 29px 0 13px 0
  }
@media (min-width: 768px) {
  .TermsOfService_root__YB9-b h2 {
      margin: 32px 0 0 0
  }
    }
.TermsOfService_root__YB9-b .TermsOfService_lastUpdated__13BAl {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
  }
@media (min-width: 768px) {
  .TermsOfService_root__YB9-b .TermsOfService_lastUpdated__13BAl {
  font-size: 16px;
  line-height: 28px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 16px;
  margin-bottom: 16px
  }
    }
.TermsOfService_root__YB9-b .TermsOfService_lastUpdated__13BAl {
    margin-top: 0;
    margin-bottom: 55px
  }
@media (min-width: 768px) {
  .TermsOfService_root__YB9-b .TermsOfService_lastUpdated__13BAl {
      margin-top: 0;
      margin-bottom: 54px
  }
    }

.Footer_root__VZ9wu {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);
  width: 100%;
  padding: 24px;
  background-color: var(--matterColorLight);
  position: relative;
  bottom: 0

}

@media (max-width:480px) {

.Footer_root__VZ9wu {
    padding: 14px

}
  }

@media (min-width: 768px) {

.Footer_root__VZ9wu {
    position: absolute;
    bottom: 0

}
  }

.Footer_content__27StV {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column
}

@media (min-width: 768px) {

.Footer_content__27StV {
    flex-direction: row
}
  }

.Footer_content__27StV .Footer_list__1QJ4w {
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    width: 100%;
    justify-content: space-between
  }

@media (max-width:480px) {

.Footer_content__27StV .Footer_list__1QJ4w {
      flex-wrap: wrap
  }
    }

@media (min-width: 768px) {

.Footer_content__27StV .Footer_list__1QJ4w {
      width: auto;
      width: initial;
      justify-content: initial
  }
    }

.Footer_content__27StV .Footer_list__1QJ4w .Footer_listItem__5GzU8 {
      min-height: 24px;
      margin-bottom: 6px
    }

@media (max-width:480px) {

.Footer_content__27StV .Footer_list__1QJ4w .Footer_listItem__5GzU8 {
        width: calc(100% / 3)
    }
      }

@media (min-width: 768px) {

.Footer_content__27StV .Footer_list__1QJ4w .Footer_listItem__5GzU8 {
        margin-bottom: 0
    }
      }

.Footer_content__27StV .Footer_list__1QJ4w .Footer_listItem__5GzU8 .Footer_link__Xabb2 {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
      }

@media (min-width: 768px) {

.Footer_content__27StV .Footer_list__1QJ4w .Footer_listItem__5GzU8 .Footer_link__Xabb2 {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
      }
    }

.Footer_content__27StV .Footer_list__1QJ4w .Footer_listItem__5GzU8 .Footer_link__Xabb2 {
        line-height: 20px;
        color: var(--matterColor);
        transition: var(--transitionStyleButton);
        padding: 10px;
        display: inline-block;
        margin-top: 0;
        margin-bottom: 12px;
        font-size: 15px
      }

@media (max-width:767px) {

.Footer_content__27StV .Footer_list__1QJ4w .Footer_listItem__5GzU8 .Footer_link__Xabb2 {
          margin-bottom: 8px;
          padding: 0px
      }
        }

.Footer_content__27StV .Footer_list__1QJ4w .Footer_listItem__5GzU8 .Footer_link__Xabb2:hover {
          color: var(--marketplaceColor);
          text-decoration: none;
        }

@media (min-width: 768px) {

.Footer_content__27StV .Footer_list__1QJ4w .Footer_listItem__5GzU8 .Footer_link__Xabb2 {
          margin-top: 0;
          margin-bottom: 0
      }
        }

.Footer_content__27StV .Footer_extraLinks__3W8NU {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: 36px
  }

@media (min-width: 768px) {

.Footer_content__27StV .Footer_extraLinks__3W8NU {
      width: auto;
      width: initial
  }
    }

.Footer_content__27StV .Footer_extraLinks__3W8NU .Footer_socialLinks__u3JPb {}

.Footer_content__27StV .Footer_extraLinks__3W8NU .Footer_copyrightLinks__wAt1t .Footer_rightLinks__ZxJHo:last-child {
          margin-right: 0;
        }

.Footer_content__27StV .Footer_extraLinks__3W8NU .Footer_rightLinks__ZxJHo {
      margin-right: 12px;
    }

.Footer_content__27StV .Footer_extraLinks__3W8NU .Footer_logo__3ACxa {
      object-fit: contain;
      object-position: left center;
      height: 37px;
      width: 80px;
    }

.Footer_content__27StV .Footer_extraLinks__3W8NU .Footer_icon__ISVxC {
      display: inline-block;
      width: 20px;
      height: 36px;
      text-align: center
    }

.Footer_content__27StV .Footer_extraLinks__3W8NU .Footer_icon__ISVxC.Footer_twitter__1Ocid>svg {
          width: 20px;
          height: 20px;
        }

.Footer_mobileContent__30IJa {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column
}

@media (min-width: 768px) {

.Footer_mobileContent__30IJa {
    flex-direction: row
}
  }

.Footer_mobileContent__30IJa .Footer_list__1QJ4w {
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    width: 100%;
    justify-content: space-between
  }

@media (max-width:480px) {

.Footer_mobileContent__30IJa .Footer_list__1QJ4w {
      /* flex-wrap: wrap; */
      /* padding: 18px 4px; */
  }
    }

@media (min-width: 768px) {

.Footer_mobileContent__30IJa .Footer_list__1QJ4w {
      width: auto;
      width: initial;
      justify-content: initial
  }
    }

.Footer_mobileContent__30IJa .Footer_list__1QJ4w .Footer_listItem__5GzU8 {
      min-height: 24px;
      margin-bottom: 6px
    }

@media (max-width:480px) {

.Footer_mobileContent__30IJa .Footer_list__1QJ4w .Footer_listItem__5GzU8 {
        width: calc(100% / 2)
    }
      }

@media (min-width: 768px) {

.Footer_mobileContent__30IJa .Footer_list__1QJ4w .Footer_listItem__5GzU8 {
        margin-bottom: 0
    }
      }

.Footer_mobileContent__30IJa .Footer_list__1QJ4w .Footer_listItem__5GzU8 .Footer_link__Xabb2 {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
      }

@media (min-width: 768px) {

.Footer_mobileContent__30IJa .Footer_list__1QJ4w .Footer_listItem__5GzU8 .Footer_link__Xabb2 {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
      }
    }

.Footer_mobileContent__30IJa .Footer_list__1QJ4w .Footer_listItem__5GzU8 .Footer_link__Xabb2 {
        line-height: 20px;
        color: var(--matterColor);
        transition: var(--transitionStyleButton);
        padding: 10px;
        display: inline-block;
        margin-top: 0;
        margin-bottom: 12px;
        font-size: 15px
      }

@media (max-width:767px) {

.Footer_mobileContent__30IJa .Footer_list__1QJ4w .Footer_listItem__5GzU8 .Footer_link__Xabb2 {
          margin-bottom: 8px;
          padding: 0px
      }
        }

.Footer_mobileContent__30IJa .Footer_list__1QJ4w .Footer_listItem__5GzU8 .Footer_link__Xabb2:hover {
          color: var(--marketplaceColor);
          text-decoration: none;
        }

@media (min-width: 768px) {

.Footer_mobileContent__30IJa .Footer_list__1QJ4w .Footer_listItem__5GzU8 .Footer_link__Xabb2 {
          margin-top: 0;
          margin-bottom: 0
      }
        }

.Footer_mobileContent__30IJa .Footer_extraLinks__3W8NU {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%
  }

@media (min-width: 768px) {

.Footer_mobileContent__30IJa .Footer_extraLinks__3W8NU {
      width: auto;
      width: initial
  }
    }

.Footer_mobileContent__30IJa .Footer_extraLinks__3W8NU .Footer_socialLinks__u3JPb {}

.Footer_mobileContent__30IJa .Footer_extraLinks__3W8NU .Footer_copyrightLinks__wAt1t .Footer_rightLinks__ZxJHo:last-child {
          margin-right: 0;
        }

.Footer_mobileContent__30IJa .Footer_extraLinks__3W8NU .Footer_rightLinks__ZxJHo {
      margin-right: 12px;
    }

.Footer_mobileContent__30IJa .Footer_extraLinks__3W8NU .Footer_logo__3ACxa {
      object-fit: contain;
      object-position: left center;
      height: 37px;
      width: 80px;
    }

.Footer_mobileContent__30IJa .Footer_extraLinks__3W8NU .Footer_icon__ISVxC {
      display: inline-block;
      width: 20px;
      height: 36px;
      text-align: center
    }

.Footer_mobileContent__30IJa .Footer_extraLinks__3W8NU .Footer_icon__ISVxC.Footer_twitter__1Ocid>svg {
          width: 20px;
          height: 20px;
        }

/* Footer links */
.Topbar_root__2XaTO {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: relative;
}

.Topbar_container__1oZu7 {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: var(--boxShadow)
}

@media (min-width: 768px) {

.Topbar_container__1oZu7 {
    display: none
}
  }

.Topbar_home__v65xt {
  display: flex;
  height: var(--topbarHeight);
  /* Calculate vertical padding. Something like: (60px - 25px)/2 */
  padding: calc((var(--topbarHeight) - var(--Topbar_logoHeight)) / 2) 24px
}

@media (max-width:768px) {

.Topbar_home__v65xt {
    height: 50px;
    text-align: center
}
  }

.Topbar_home__v65xt>img {
    max-width: 100%;
    margin: 0 auto;
  }

.Topbar_logo__1afs5 {
  object-fit: contain;
}

.Topbar_logoutButton__NHy1v {
  margin: 0;
}

/* Child component's size (and positioning) should be given here */

.Topbar_menu__3eBQH,
.Topbar_searchMenu__pcLOS {
  /* Font */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.Topbar_menu__3eBQH,
.Topbar_searchMenu__pcLOS {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.Topbar_menu__3eBQH,
.Topbar_searchMenu__pcLOS {
  color: var(--matterColor);

  /* Layout */
  display: flex;
  width: 66px;
  margin: 0;
  border-width: 0px;
  background-color: transparent

  /* Hovers */
}

.Topbar_menu__3eBQH:enabled, .Topbar_searchMenu__pcLOS:enabled {
    cursor: pointer;
  }

.Topbar_menu__3eBQH:enabled:hover,
  .Topbar_menu__3eBQH:enabled:active,
  .Topbar_searchMenu__pcLOS:enabled:hover,
  .Topbar_searchMenu__pcLOS:enabled:active {
    background-color: transparent;
    box-shadow: none;
  }

.Topbar_menu__3eBQH:disabled, .Topbar_searchMenu__pcLOS:disabled {
    background-color: transparent;
    cursor: auto;
  }

.Topbar_menu__3eBQH {
  padding: 15px 24px;
  position: relative;
}

.Topbar_searchMenu__pcLOS {
  padding: 12px 24px;
}

.Topbar_menuIcon__2Xe4w,
.Topbar_searchMenuIcon__bU1iH {
  margin: 9px 0;
}

.Topbar_notificationDot__3YgrR {
  /* Dimensions */
  width: 12px;
  height: 12px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 17px;
  right: 17px;

  /* Style: red dot with white border */
  background-color: var(--failColor);
  border: 2px solid var(--matterColorLight);

  -webkit-animation-name: Topbar_notificationPop__3K39M;

          animation-name: Topbar_notificationPop__3K39M;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;

  -webkit-animation-timing-function: ease-in;

          animation-timing-function: ease-in;
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
}

@-webkit-keyframes Topbar_notificationPop__3K39M {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

@keyframes Topbar_notificationPop__3K39M {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

.Topbar_desktop__MNRJD {
  display: none
}

@media (min-width: 768px) {

.Topbar_desktop__MNRJD {
    display: block
}
  }

/* Icon component styles */

.Topbar_rootMenuIcon__asYkF {
  fill: var(--matterColor);
}

.Topbar_rootSearchIcon__phJ-4 {
  stroke: var(--matterColor);
}

.Topbar_searchContainer__2oK5u {
  position: relative;
  height: 100%;
  margin-top: 94px;
}

.Topbar_mobileHelp__pyD8H {
  color: var(--matterColorAnti);
  margin: 0 24px;

  /* Absolute position to avoid affecting the layout of the autocomplete
   predictions list */
  position: absolute;
  top: 73px;
}

/* ================ Modal ================ */

.Topbar_modalContainer__37KPC {
  flex-grow: 1;

    /* Create context so the close button can be positioned within it */
  position: relative;

    /* Display and dimensions */
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0;

  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius)
}

@media (min-width: 768px) {

.Topbar_modalContainer__37KPC {
  flex-basis: 480px;
  flex-grow: 0;
  height: 100%;
  padding: 0;
  background-color: transparent;
  border-radius: 0
}
    }

.Topbar_modalContainer__37KPC {
  padding: 0
}

@media (min-width: 768px) {

.Topbar_modalContainer__37KPC {
    flex-basis: 576px
}
  }

.Topbar_missingInformationModal__1CLSb {
  flex-grow: 1;

    /* Create context so the close button can be positioned within it */
  position: relative;

    /* Display and dimensions */
  display: flex;
  flex-direction: column;
  padding: var(--modalPadding);

  background-color: var(--matterColorLight);
  border-radius: var(--borderRadius);
  border-bottom: none;
}

@media (min-width: 768px) {

.Topbar_missingInformationModal__1CLSb {
  flex-basis: 480px;
  flex-grow: 0;
      /* min-height: 568px; */
  padding: var(--modalPaddingMedium);
  margin-top: 12.5vh;
  margin-bottom: 12.5vh;
  border-bottom: 8px solid var(--marketplaceColor);
}
    }

.Topbar_missingInformationModal__1CLSb {
  padding-top: 70px;
}

/* Title of the modal */

.Topbar_modalTitle__3AUy5 {
  font-weight: var(--fontWeightSemiBold);
  font-size: 22px;
  line-height: 100%;
  letter-spacing: -0.5px;
  margin: 0;
  font-weight: var(--fontWeightBold);
}

@media (min-width: 768px) {

.Topbar_modalTitle__3AUy5 {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.9px;
  margin: 0;
}
    }

/* Paragraph for the Modal */

.Topbar_modalMessage__Re7Ws {
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  line-height: 24px;
  margin: 18px 0 0 0;
}

@media (min-width: 768px) {

.Topbar_modalMessage__Re7Ws {
  font-weight: var(--fontWeightRegular);
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.11px;
  margin: 24px 0 0 0;
}
    }

/* Make the email pop */

.Topbar_email__2Yl-u {
  font-weight: var(--fontWeightHighlightEmail);
}

.Topbar_helperLink__2t6w2 {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased;
}

@media (min-width: 768px) {

.Topbar_helperLink__2t6w2 {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px;
}
    }

.Topbar_helperLink__2t6w2 {
  color: var(--matterColor);
  margin: 0;
}

@media (min-width: 768px) {

.Topbar_helperLink__2t6w2 {
  margin: 0;
}
    }

.Topbar_helperText__3JjlQ {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightRegular);
  font-size: 13px;
  line-height: 18px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 9.5px;
  margin-bottom: 8.5px;
  -webkit-font-smoothing: subpixel-antialiased;
}

@media (min-width: 768px) {

.Topbar_helperText__3JjlQ {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10.5px;
  margin-bottom: 13.5px;
}
    }

.Topbar_helperText__3JjlQ {
  color: var(--matterColorAnti);
  margin: 0;
}

@media (min-width: 768px) {

.Topbar_helperText__3JjlQ {
  margin: 0;
}
    }

.Topbar_error__3a-Qd {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.Topbar_error__3a-Qd {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.Topbar_error__3a-Qd {
  margin-top: 24px;
  color: var(--failColor);
}

.Topbar_bottomWrapper__1KmeV {
  text-align: center;
  margin-top: 60px;
  align-self: stretch;
}

@media (min-width: 768px) {

.Topbar_bottomWrapper__1KmeV {
  margin-top: 50px;
}
    }

.Topbar_bottomWrapper__1KmeV {

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

.Topbar_modalIcon__D-jyG {
  height: 48px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.Topbar_modalIcon__D-jyG {
  height: 64px;
  margin-bottom: 23px;
}
    }

.Topbar_reminderModalLinkButton__1lRVz {
  font-family: 'Open Sans', sans-serif;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

@media (min-width: 768px) {

.Topbar_reminderModalLinkButton__1lRVz {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.Topbar_reminderModalLinkButton__1lRVz {
  border-radius: 4px;
  display: block;
  min-height: 45px;
  margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */

  padding: 8px 16px;
}

@media (min-width: 768px) {

.Topbar_reminderModalLinkButton__1lRVz {
  padding: 10px 20px;
}
    }

.Topbar_reminderModalLinkButton__1lRVz {

    /* Borders */
  border: none;
  border-radius: var(--borderRadius);

    /* Colors */
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

    /* Text */
  text-align: center;
  text-decoration: none;

    /* Effects */
  transition: all var(--transitionStyleButton);
  cursor: pointer;
}

.Topbar_reminderModalLinkButton__1lRVz:hover,
    .Topbar_reminderModalLinkButton__1lRVz:focus {
  outline: none;
  background-color: var(--marketplaceColorDark);
  text-decoration: none;
  box-shadow: var(--boxShadowButton);
    }

.Topbar_reminderModalLinkButton__1lRVz:disabled {
  background-color: var(--matterColorNegative);
  color: var(--matterColorLight);
  cursor: not-allowed;
  box-shadow: none;
    }

@media (min-width: 768px) {

.Topbar_reminderModalLinkButton__1lRVz {
  min-height: 50px;
}
    }

.Topbar_reminderModalLinkButton__1lRVz {

  background-color: #14100f;
  color: var(--matterColorLight);
}

.Topbar_reminderModalLinkButton__1lRVz:hover {
  background-color: #14100f;
  color: var(--matterColorLight);
    }

.Topbar_reminderModalLinkButton__1lRVz:focus {
  background-color: var(--successColorDark);
    }

.Topbar_reminderModalLinkButton__1lRVz:disabled {
  background-color: var(--matterColorNegative);
  color: var(--matterColor);
    }

.Topbar_genericError__2vEQS {
  position: fixed;
  top: calc(var(--topbarHeight) + 15px);

  /* Place enough to the right to hide the error */
  right: -355px;
  opacity: 0;

  max-width: 340px;
  margin: 0 15px;

  /* Animate error from the right */
  transition: all ease-out 0.2s;
  transition-delay: 1s;

  /* Bleed the bg color from the bottom */
  padding-bottom: 4px;
  background-color: var(--marketplaceColor);
  border-radius: 4px;

  /* Place on top of everything */
  z-index: var(--zIndexGenericError)
}

@media (min-width: 768px) {

.Topbar_genericError__2vEQS {
    top: calc(var(--topbarHeightDesktop) + 15px)
}
  }

.Topbar_genericErrorVisible__34Em4 {
  right: 0;
  opacity: 1;
}

.Topbar_genericErrorContent__1Q2no {
  padding: 16px 22px;
  border-radius: 4px;
  background-color: var(--marketplaceColorLight);
  border-radius: 4px;
}

.Topbar_genericErrorText__2KBsW {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.Topbar_genericErrorText__2KBsW {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.Topbar_genericErrorText__2KBsW {
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  color: var(--matterColorLight)
}

@media (min-width: 768px) {

.Topbar_genericErrorText__2KBsW {
    margin: 0
}
  }

.Topbar_accountMenu__9_isc {
  padding: 14px
}

.Topbar_accountMenu__9_isc>div {
    width: 34px;
    height: 34px
  }

.Topbar_accountMenu__9_isc>div>span {
      position: relative;
      top: -2px;
    }

.Topbar_notificationBadge__2QYfQ{
  position: absolute;
  top: 36px;
  /* left: 0; */
  right: 12px;
  bottom: 0;
}
.TopbarSearchForm_mobileInputRoot__3hF5V {
  width: 100%;
}

.TopbarSearchForm_desktopInputRoot__vUwNv {}

.TopbarSearchForm_mobileIcon__3vrQ0 {
  margin-left: 24px;
  padding: 11px 0 17px 0;

  /* Borders */
  border-bottom-width: var(--borderRadiusMobileSearch);

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--matterColorLight);
}

.TopbarSearchForm_mobileInput__cRdvp {
  flex-grow: 1;

  /* Font */
  font-weight: var(--fontWeightSemiBold);
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.5px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px
}

@media (min-width: 768px) {

.TopbarSearchForm_mobileInput__cRdvp {
  font-size: 40px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 48px;
  letter-spacing: -1px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 24px;
  margin-bottom: 24px
}
    }

.TopbarSearchForm_mobileInput__cRdvp {

  /* Layout */
  margin: 0 24px 0 0;
  padding: 4px 13px 10px 13px;
  height: var(--TopbarSearchForm_inputHeight);
  line-height: unset;

  /* Borders */
  border-bottom-width: var(--borderRadiusMobileSearch)

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
}

.TopbarSearchForm_mobileInput__cRdvp::-webkit-input-placeholder {
    line-height: normal;
  }

.TopbarSearchForm_mobileInput__cRdvp:hover,
  .TopbarSearchForm_mobileInput__cRdvp:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }

.TopbarSearchForm_desktopIcon__1SSSp {
  border: none;
  padding-top: 3px;

  display: flex;
  width: 24px;
  align-self: stretch;
  background-color: var(--matterColorLight);
}

.TopbarSearchForm_desktopInput__1sYI9 {
  flex-grow: 1;
  /* Font */
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.TopbarSearchForm_desktopInput__1sYI9 {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.TopbarSearchForm_desktopInput__1sYI9 {
  line-height: unset;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 10px;

  /* Search text gets ellipsis if it's too wide */
  text-overflow: ellipsis;
  overflow-x: hidden
}

.TopbarSearchForm_desktopInput__1sYI9:hover,
  .TopbarSearchForm_desktopInput__1sYI9:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }

.TopbarSearchForm_desktopInput__1sYI9:hover::-webkit-input-placeholder, .TopbarSearchForm_desktopInput__1sYI9:focus::-webkit-input-placeholder {
    color: var(--matterColor);
  }

.TopbarSearchForm_desktopInput__1sYI9:hover:-ms-input-placeholder, .TopbarSearchForm_desktopInput__1sYI9:focus:-ms-input-placeholder {
    color: var(--matterColor);
  }

.TopbarSearchForm_desktopInput__1sYI9:hover::placeholder,
  .TopbarSearchForm_desktopInput__1sYI9:focus::placeholder {
    color: var(--matterColor);
  }

.TopbarSearchForm_desktopInput__1sYI9 {

  /* Placeholder text might be too wide */
}

.TopbarSearchForm_desktopInput__1sYI9::-webkit-input-placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightMedium);
    -webkit-transition: var(--transitionStyleButton);
    transition: var(--transitionStyleButton);
  }

.TopbarSearchForm_desktopInput__1sYI9:-ms-input-placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightMedium);
    -ms-transition: var(--transitionStyleButton);
    transition: var(--transitionStyleButton);
  }

.TopbarSearchForm_desktopInput__1sYI9::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightMedium);
    transition: var(--transitionStyleButton);
  }

.TopbarSearchForm_desktopInput__1sYI9 {

  margin-top: 0;
  margin-bottom: 0
}

@media (min-width: 768px) {

.TopbarSearchForm_desktopInput__1sYI9 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: var(--fontWeightSemiBold)
}
  }

.TopbarSearchForm_mobilePredictions__33L9a {
  position: absolute;
  top: calc(var(--TopbarSearchForm_inputHeight) - var(--TopbarSearchForm_bottomBorder));
  left: 0;
  min-height: calc(100vh - calc(var(--TopbarSearchForm_topbarMargin) + var(--TopbarSearchForm_inputHeight) - var(--TopbarSearchForm_bottomBorder)));
  width: 100%;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
}

.TopbarSearchForm_mobilePredictionsAttribution__3W8Dn {
  /* When using the Google Maps Places geocoder, the "Powered by Google"
   text is hidden in Mobile Safari without giving some extra space to
   it. */
  margin-bottom: 100px;
}

.TopbarSearchForm_desktopPredictions__16In0 {
  position: absolute;
  width: 100%;
  top: calc(100% + 5px);
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
  max-width: 434px;
}

/**
 * Keyword search
 */

.TopbarSearchForm_keywordSearchWrapper__3244_ {
  position: relative;
  display: flex;
  height: 100%;
}

.TopbarSearchForm_searchSubmit__bkRrz {
  border: 0;
  padding: 0;
  cursor: pointer
}

.TopbarSearchForm_searchSubmit__bkRrz:hover,
  .TopbarSearchForm_searchSubmit__bkRrz:focus {
    outline: none
  }

.TopbarSearchForm_searchSubmit__bkRrz:hover .TopbarSearchForm_iconSvgGroup__RMRo7, .TopbarSearchForm_searchSubmit__bkRrz:focus .TopbarSearchForm_iconSvgGroup__RMRo7 {
      stroke: var(--matterColor);
    }

.TopbarSearchForm_icon__3-fK7 {
  display: flex;
  width: 24px;
  align-self: stretch;
  border-bottom: 2px solid var(--marketplaceColor);
  background-color: var(--matterColorLight);
}

.TopbarSearchForm_iconSvg__1j2rf {
  margin: auto;
}

.TopbarSearchForm_iconSvgGroup__RMRo7 {
  stroke: var(--marketplaceColor);
  stroke-width: 2.5px
}

@media (min-width: 768px) {

.TopbarSearchForm_iconSvgGroup__RMRo7 {
    stroke-width: 2px
}
  }
.TopbarMobileMenu_root__cfaRf {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
  padding: 130px 0px;
}

.TopbarMobileMenu_content__1zcXo {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.TopbarMobileMenu_footer__2MLyr {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
}

.TopbarMobileMenu_avatar__1zxcm {
  /* Layout */
  flex-shrink: 0;
  margin: var(--TopbarMobileMenu_topMargin) 0 0 0;
}

.TopbarMobileMenu_greeting__3O-J6 {
  /* Font */
  font-weight: var(--fontWeightSemiBold);
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.5px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px;
}

@media (min-width: 768px) {

.TopbarMobileMenu_greeting__3O-J6 {
  font-size: 40px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 48px;
  letter-spacing: -1px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 24px;
  margin-bottom: 24px;
}
    }

.TopbarMobileMenu_greeting__3O-J6 {
  margin-bottom: 1px;
  margin-top: 16px;
}

.TopbarMobileMenu_logoutButton__3oUFy {
    /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

    /* Borders */
  border: none;

    /* Colors */
  color: var(--marketplaceColor);

    /* Text size should be inherited */
  text-decoration: none;
}

.TopbarMobileMenu_logoutButton__3oUFy:hover {
  text-decoration: underline;
  cursor: pointer;
    }

.TopbarMobileMenu_logoutButton__3oUFy {
  /* Logout font is smaller and gray since the action is not recommended. */
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 16px;
  margin-bottom: 14px;
}

@media (min-width: 768px) {

.TopbarMobileMenu_logoutButton__3oUFy {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 16px;
  margin-bottom: 16px;
}
    }

.TopbarMobileMenu_logoutButton__3oUFy {
  color: var(--matterColorAnti);

  /* Position component */
  width: auto;
  width: initial;
  margin: 4px 0 24px 0;
  padding: 0;
}

.TopbarMobileMenu_inbox__3xp4r {
  /* Font */
  font-weight: var(--fontWeightSemiBold);
  font-size: 21px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 21px;
  margin-bottom: 17px;
}

@media (min-width: 768px) {

.TopbarMobileMenu_inbox__3xp4r {
  line-height: 32px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 21px;
  margin-bottom: 19px;
}
    }

.TopbarMobileMenu_inbox__3xp4r {
  color: var(--marketplaceColor);
  position: relative;

  margin-top: auto;
  margin-bottom: 13px;
}

.TopbarMobileMenu_navigationLink__2cYUo {
  /* Font */
  font-weight: var(--fontWeightSemiBold);
  font-size: 21px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 21px;
  margin-bottom: 17px;
}

@media (min-width: 768px) {

.TopbarMobileMenu_navigationLink__2cYUo {
  line-height: 32px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 21px;
  margin-bottom: 19px;
}
    }

.TopbarMobileMenu_navigationLink__2cYUo {
  color: var(--marketplaceColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;
}

.TopbarMobileMenu_currentPageLink__3ZjjE {
  color: var(--marketplaceColorDark);
}

.TopbarMobileMenu_notificationBadge__2S5Wb {
  position: absolute;
  top: 4px;
  right: -26px

  /* This is inside a link - remove underline */
}

.TopbarMobileMenu_notificationBadge__2S5Wb:hover {
    text-decoration: none;
  }

.TopbarMobileMenu_createNewListingLink__3P6AY {
  font-family: 'Open Sans', sans-serif;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

@media (min-width: 768px) {

.TopbarMobileMenu_createNewListingLink__3P6AY {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.TopbarMobileMenu_createNewListingLink__3P6AY {
  border-radius: 4px;
  display: block;
  min-height: 45px;
  margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */

  padding: 8px 16px;
}

@media (min-width: 768px) {

.TopbarMobileMenu_createNewListingLink__3P6AY {
  padding: 10px 20px;
}
    }

.TopbarMobileMenu_createNewListingLink__3P6AY {

    /* Borders */
  border: none;
  border-radius: var(--borderRadius);

    /* Colors */
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

    /* Text */
  text-align: center;
  text-decoration: none;

    /* Effects */
  transition: all var(--transitionStyleButton);
  cursor: pointer;
}

.TopbarMobileMenu_createNewListingLink__3P6AY:hover,
    .TopbarMobileMenu_createNewListingLink__3P6AY:focus {
  outline: none;
  background-color: var(--marketplaceColorDark);
  text-decoration: none;
  box-shadow: var(--boxShadowButton);
    }

.TopbarMobileMenu_createNewListingLink__3P6AY:disabled {
  background-color: var(--matterColorNegative);
  color: var(--matterColorLight);
  cursor: not-allowed;
  box-shadow: none;
    }

@media (min-width: 768px) {

.TopbarMobileMenu_createNewListingLink__3P6AY {
  min-height: 50px;
}
    }

.TopbarMobileMenu_authenticationGreeting__6ULuD {
  /* Font */
  font-weight: var(--fontWeightSemiBold);
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.5px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px;
}

@media (min-width: 768px) {

.TopbarMobileMenu_authenticationGreeting__6ULuD {
  font-size: 40px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 48px;
  letter-spacing: -1px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 24px;
  margin-bottom: 24px;
}
    }

.TopbarMobileMenu_authenticationGreeting__6ULuD {

  margin-bottom: 24px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}

.TopbarMobileMenu_authenticationLinks__k0QBR {
  white-space: nowrap;
}

.TopbarMobileMenu_signupLink__2Ar-G {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.TopbarMobileMenu_loginLink__t97uv {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.TopbarMobileMenu_currentPage__zZadS {
  color: var(--matterColorDark);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}

.TopbarMobileMenu_spacer__1_bpl {
  width: 100%;
  height: 124px;
}

.TopbarMobileMenu_all__3OUBK{
  color: #0b0b0b
}

.TopbarMobileMenu_all__3OUBK>h2{
    font-size: 20px;
    margin: 10px 0px;
  }
.TopbarAccountMobileMenu_root__-7ZGp {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
}

.TopbarAccountMobileMenu_content__1hlMR {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.TopbarAccountMobileMenu_footer__EsB_I {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
}

.TopbarAccountMobileMenu_avatar___Qqk6 {
  /* Layout */
  flex-shrink: 0;
  margin: var(--TopbarMobileMenu_topMargin) 0 0 0;
}

.TopbarAccountMobileMenu_greeting__1VvMi {
  /* Font */
  font-weight: var(--fontWeightSemiBold);
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.5px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px;
}

@media (min-width: 768px) {

.TopbarAccountMobileMenu_greeting__1VvMi {
  font-size: 40px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 48px;
  letter-spacing: -1px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 24px;
  margin-bottom: 24px;
}
    }

.TopbarAccountMobileMenu_greeting__1VvMi {
  margin-bottom: 1px;
  margin-top: 16px;
}

.TopbarAccountMobileMenu_logoutButton__39fFx {
    /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

    /* Borders */
  border: none;

    /* Colors */
  color: var(--marketplaceColor);

    /* Text size should be inherited */
  text-decoration: none;
}

.TopbarAccountMobileMenu_logoutButton__39fFx:hover {
  text-decoration: underline;
  cursor: pointer;
    }

.TopbarAccountMobileMenu_logoutButton__39fFx {
  /* Logout font is smaller and gray since the action is not recommended. */
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 16px;
  margin-bottom: 14px;
}

@media (min-width: 768px) {

.TopbarAccountMobileMenu_logoutButton__39fFx {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 16px;
  margin-bottom: 16px;
}
    }

.TopbarAccountMobileMenu_logoutButton__39fFx {
  color: var(--matterColorAnti);

  /* Position component */
  width: auto;
  width: initial;
  margin: 4px 0 24px 0;
  padding: 0;
}

.TopbarAccountMobileMenu_inbox__3KzrA {
  /* Font */
  font-weight: var(--fontWeightSemiBold);
  font-size: 21px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 21px;
  margin-bottom: 17px;
}

@media (min-width: 768px) {

.TopbarAccountMobileMenu_inbox__3KzrA {
  line-height: 32px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 21px;
  margin-bottom: 19px;
}
    }

.TopbarAccountMobileMenu_inbox__3KzrA {
  color: var(--marketplaceColor);
  position: relative;

  margin-top: auto;
  margin-bottom: 13px;
}

.TopbarAccountMobileMenu_navigationLink__3TYbj {
  /* Font */
  font-weight: var(--fontWeightSemiBold);
  font-size: 21px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 21px;
  margin-bottom: 17px;
}

@media (min-width: 768px) {

.TopbarAccountMobileMenu_navigationLink__3TYbj {
  line-height: 32px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 21px;
  margin-bottom: 19px;
}
    }

.TopbarAccountMobileMenu_navigationLink__3TYbj {
  color: var(--marketplaceColor);

  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;
}

.TopbarAccountMobileMenu_currentPageLink__3-yct {
  color: var(--marketplaceColorDark);
}

.TopbarAccountMobileMenu_notificationBadge__2MygM {
  position: absolute;
  top: 8px;
  right: -16px

  /* This is inside a link - remove underline */
}

.TopbarAccountMobileMenu_notificationBadge__2MygM:hover {
    text-decoration: none;
  }

.TopbarAccountMobileMenu_createNewListingLink__2OVe0 {
  font-family: 'Open Sans', sans-serif;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

@media (min-width: 768px) {

.TopbarAccountMobileMenu_createNewListingLink__2OVe0 {
      /* TODO: Make sure button text aligns with the baseline */
}
    }

.TopbarAccountMobileMenu_createNewListingLink__2OVe0 {
  border-radius: 4px;
  display: block;
  min-height: 45px;
  margin: 0;

    /* Padding is only for <a> elements where button styles are applied,
		buttons elements should have zero padding */

  padding: 8px 16px;
}

@media (min-width: 768px) {

.TopbarAccountMobileMenu_createNewListingLink__2OVe0 {
  padding: 10px 20px;
}
    }

.TopbarAccountMobileMenu_createNewListingLink__2OVe0 {

    /* Borders */
  border: none;
  border-radius: var(--borderRadius);

    /* Colors */
  background-color: var(--marketplaceColor);
  color: var(--matterColorLight);

    /* Text */
  text-align: center;
  text-decoration: none;

    /* Effects */
  transition: all var(--transitionStyleButton);
  cursor: pointer;
}

.TopbarAccountMobileMenu_createNewListingLink__2OVe0:hover,
    .TopbarAccountMobileMenu_createNewListingLink__2OVe0:focus {
  outline: none;
  background-color: var(--marketplaceColorDark);
  text-decoration: none;
  box-shadow: var(--boxShadowButton);
    }

.TopbarAccountMobileMenu_createNewListingLink__2OVe0:disabled {
  background-color: var(--matterColorNegative);
  color: var(--matterColorLight);
  cursor: not-allowed;
  box-shadow: none;
    }

@media (min-width: 768px) {

.TopbarAccountMobileMenu_createNewListingLink__2OVe0 {
  min-height: 50px;
}
    }

.TopbarAccountMobileMenu_authenticationGreeting__3kLa4 {
  /* Font */
  font-weight: var(--fontWeightSemiBold);
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.5px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px;
}

@media (min-width: 768px) {

.TopbarAccountMobileMenu_authenticationGreeting__3kLa4 {
  font-size: 40px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 48px;
  letter-spacing: -1px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 24px;
  margin-bottom: 24px;
}
    }

.TopbarAccountMobileMenu_authenticationGreeting__3kLa4 {

  margin-bottom: 24px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}

.TopbarAccountMobileMenu_authenticationLinks__3D0Pv {
  white-space: nowrap;
}

.TopbarAccountMobileMenu_signupLink__3lTkG {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.TopbarAccountMobileMenu_loginLink__1c_ck {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.TopbarAccountMobileMenu_currentPage__q1Wir {
  color: var(--matterColorDark);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}

.TopbarAccountMobileMenu_spacer__1zVyO {
  width: 100%;
  height: 124px;
}

/* Desktop */

.TopbarDesktop_root__2I0Rh {
  width: 100%;
  padding: 10px 15px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowLight)
}

@media (min-width: 1024px) {

.TopbarDesktop_root__2I0Rh {
    padding: 10px 24px
}
  }

.TopbarDesktop_fixWidthContainer__3A8MQ {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

/* logo */

.TopbarDesktop_logoLink__1ZpmW {
  flex-shrink: 0;
  height: 100%;
}

.TopbarDesktop_logo__JDYFB {
  height: 56px;
  max-width: 150px;
  object-fit: contain;
  object-position: left center;
}

.TopbarDesktop_navLink__3fSuy {
  font-size: 15px;
  line-height: 130%;
  color: var(--matterColor);
  padding: 8px 15px;
  border-radius: 0px;
  transition: all ease 0.5s;
  border: solid 1px transparent
}

.TopbarDesktop_navLink__3fSuy.TopbarDesktop_signupLink__1qF1h {
    background-color: #140f0f;
    color: var(--matterColorLight);
    border-color: #515151;
    margin-left: 20px
  }

.TopbarDesktop_navLink__3fSuy.TopbarDesktop_signupLink__1qF1h:hover {
      text-decoration: none;
      background-color: var(--marketplaceColor);
      border-color: var(--marketplaceColor);
      color: var(--matterColorLight);
    }

.TopbarDesktop_navLink__3fSuy.TopbarDesktop_loginLink__2-CWH {
    background-color: var(--matterColorLight);
    border-color: var(--marketplaceColorDark);
    color: var(--marketplaceColorDark);
    margin-left: 10px
  }

.TopbarDesktop_navLink__3fSuy.TopbarDesktop_loginLink__2-CWH:hover {
      text-decoration: none;
      background-color: var(--marketplaceColor);
      border-color: var(--marketplaceColor);
      color: var(--matterColorLight);
    }

.TopbarDesktop_navLink__3fSuy:hover {
    text-decoration: none;
    color: var(--marketplaceColor);
    transition: all ease 0.5s;
  }

/* Search */

.TopbarDesktop_searchLink__QQpgY {
  flex-grow: 1
}

@media (min-width: 1024px) {

.TopbarDesktop_searchLink__QQpgY {
    padding-left: 0
}
  }

.TopbarDesktop_search__1mLjk {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}

@media (min-width: 768px) {

.TopbarDesktop_search__1mLjk {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }

.TopbarDesktop_search__1mLjk {
  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;
}

@media (min-width: 768px) {

.TopbarDesktop_search__1mLjk {
  margin: 28px 0;
}
    }

.TopbarDesktop_search__1mLjk {
  color: var(--matterColor);
}

.TopbarDesktop_topbarSearchWithLeftPadding__2vwLe {
  padding-left: 24px
}

@media (min-width: 1024px) {

.TopbarDesktop_topbarSearchWithLeftPadding__2vwLe {
    padding-left: 34px
}
  }

.TopbarDesktop_inbox__IM7Df {
  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;
}

@media (min-width: 768px) {

.TopbarDesktop_inbox__IM7Df {
  margin: 28px 0;
}
    }

.TopbarDesktop_inbox__IM7Df {
  position: relative;
}

.TopbarDesktop_notificationDot__iFnOy {
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 2px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--failColor);

  -webkit-animation-name: TopbarDesktop_notificationPop__14V9R;

          animation-name: TopbarDesktop_notificationPop__14V9R;
  -webkit-animation-duration: 0.1s;
          animation-duration: 0.1s;
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;

  -webkit-animation-timing-function: ease-in;

          animation-timing-function: ease-in;
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
}

@-webkit-keyframes TopbarDesktop_notificationPop__14V9R {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

@keyframes TopbarDesktop_notificationPop__14V9R {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */

.TopbarDesktop_profileMenuLabel__2T-a9 {
  /* @apply --TopbarDesktop_linkHover; */
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0px;
  margin-left: 10px
}

.TopbarDesktop_profileMenuLabel__2T-a9:active {
    border-bottom: 0;
  }

.TopbarDesktop_profileMenuIsOpen__5GvHs:hover {
    border-bottom: 0;
  }

.TopbarDesktop_avatar__14bJV {}

.TopbarDesktop_profileMenuContent__135gs {
  min-width: 276px;
  padding-top: 20px;
  top: calc(100% + 10px);
}

/* left animated "border" like hover element */

.TopbarDesktop_menuItemBorder__1UX0b {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.TopbarDesktop_profileSettingsLink__2b-oE,
.TopbarDesktop_yourListingsLink__3nzwo {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px
}

@media (min-width: 768px) {

.TopbarDesktop_profileSettingsLink__2b-oE,
.TopbarDesktop_yourListingsLink__3nzwo {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px
}
    }

.TopbarDesktop_profileSettingsLink__2b-oE,
.TopbarDesktop_yourListingsLink__3nzwo {
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton)
}

.TopbarDesktop_profileSettingsLink__2b-oE:hover, .TopbarDesktop_yourListingsLink__3nzwo:hover {
    color: var(--matterColorDark);
    text-decoration: none
  }

.TopbarDesktop_profileSettingsLink__2b-oE:hover .TopbarDesktop_menuItemBorder__1UX0b, .TopbarDesktop_yourListingsLink__3nzwo:hover .TopbarDesktop_menuItemBorder__1UX0b {
      width: 6px;
      background-color: var(--marketplaceColor);
    }

@media (min-width: 768px) {

.TopbarDesktop_profileSettingsLink__2b-oE,
.TopbarDesktop_yourListingsLink__3nzwo {
    margin: 0
}
  }

.TopbarDesktop_logoutButton__2HuY1 {
    /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

    /* Borders */
  border: none;

    /* Colors */
  color: var(--marketplaceColor);

    /* Text size should be inherited */
  text-decoration: none
}

.TopbarDesktop_logoutButton__2HuY1:hover {
  text-decoration: underline;
  cursor: pointer;
    }

.TopbarDesktop_logoutButton__2HuY1 {
  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}

@media (min-width: 768px) {

.TopbarDesktop_logoutButton__2HuY1 {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }

.TopbarDesktop_logoutButton__2HuY1 {
  font-size: 14px;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative)
}

.TopbarDesktop_logoutButton__2HuY1:hover {
    color: var(--matterColorDark);
    text-decoration: none
  }

.TopbarDesktop_logoutButton__2HuY1:hover .TopbarDesktop_menuItemBorder__1UX0b {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }

@media (min-width: 768px) {

.TopbarDesktop_logoutButton__2HuY1 {
    margin-top: 20px;
    margin-bottom: 0
}
  }

.TopbarDesktop_currentPage__2-jto {
  color: var(--matterColorDark);
  position: relative
}

.TopbarDesktop_currentPage__2-jto .TopbarDesktop_menuItemBorder__1UX0b {
    width: 6px;
    background-color: var(--matterColorDark);
  }

.TopbarDesktop_currentPage__2-jto:hover .TopbarDesktop_menuItemBorder__1UX0b {
      background-color: var(--matterColorDark);
    }

.TopbarDesktop_links__hBzP6 {
  display: flex;
  align-items: center;
}

.TopbarDesktop_tabDot__1kFN- {
  height: 10px;
  width: 10px;
  background-color: #000;
  border-radius: 50%;
  display: inline-block;
  margin-left: 3px;
}
.LocationSearchForm_root__1gfGH {
  border-radius: 2px;
}

.LocationSearchForm_searchInputIcon__3jIVp {
  box-sizing: content-box;
  padding-left: 24px;
  margin-top: 2px;

  display: flex;
  width: 24px;
  align-self: stretch;
  border-bottom: 2px solid var(--marketplaceColor);
  background-color: var(--matterColorLight);
}

.LocationSearchForm_searchInput__3ab2x {
  flex-grow: 1;
  height: 69px;
  padding-left: 10px;
  margin: 0;
  line-height: unset;
  border-bottom-width: 2px

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
}

.LocationSearchForm_searchInput__3ab2x::-webkit-input-placeholder {
    line-height: normal;
  }

.LocationSearchForm_searchInput__3ab2x:hover,
  .LocationSearchForm_searchInput__3ab2x:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }

.LocationSearchForm_searchInput__3ab2x:hover::-webkit-input-placeholder, .LocationSearchForm_searchInput__3ab2x:focus::-webkit-input-placeholder {
    color: var(--matterColor);
  }

.LocationSearchForm_searchInput__3ab2x:hover:-ms-input-placeholder, .LocationSearchForm_searchInput__3ab2x:focus:-ms-input-placeholder {
    color: var(--matterColor);
  }

.LocationSearchForm_searchInput__3ab2x:hover::placeholder,
  .LocationSearchForm_searchInput__3ab2x:focus::placeholder {
    color: var(--matterColor);
  }

.LocationSearchForm_searchInput__3ab2x::-webkit-input-placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightRegular);
    -webkit-transition: var(--transitionStyleButton);
    transition: var(--transitionStyleButton);
  }

.LocationSearchForm_searchInput__3ab2x:-ms-input-placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightRegular);
    -ms-transition: var(--transitionStyleButton);
    transition: var(--transitionStyleButton);
  }

.LocationSearchForm_searchInput__3ab2x::placeholder {
    text-overflow: ellipsis;
    overflow-x: hidden;
    font-weight: var(--fontWeightRegular);
    transition: var(--transitionStyleButton);
  }

.LocationSearchForm_searchPredictions__2UqS2 {
  position: absolute;
  width: 100%;
  top: 50px;
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);

  margin-top: 16px;
  margin-bottom: 102px
}

@media (min-width: 1921px) {

.LocationSearchForm_searchPredictions__2UqS2 {
    margin-bottom: 0
}
  }

/* 404 page */
.NotFoundPage_root__3y97B {
  /* Expand to the available space */
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.NotFoundPage_content__1e4oq {
  max-width: 587px;
  width: 80%;
  margin-top: 60px;
  margin-bottom: 60px
}
@media (min-width: 768px) {
.NotFoundPage_content__1e4oq {
    margin-top: 10vh
}
  }
.NotFoundPage_number__EnzLM {
  font-size: 36px;
  line-height: 100%;
  letter-spacing: -0.7px;
  margin-top: 0px;
  margin-bottom: 14px
}
@media (min-width: 768px) {
.NotFoundPage_number__EnzLM {
  font-size: 50px;
  letter-spacing: -1.5px;
  margin-bottom: 23px
}
    }
.NotFoundPage_number__EnzLM {
  text-align: center;
  color: var(--marketplaceColor);
  margin: 1px 0 0 0
}
@media (min-width: 768px) {
.NotFoundPage_number__EnzLM {
    margin: 0
}
  }
.NotFoundPage_heading__1dQWd {
  font-weight: var(--fontWeightSemiBold);
  font-size: 22px;
  line-height: 100%;
  letter-spacing: -0.5px;
  margin: 0;
  font-weight: var(--fontWeightBold)
}
@media (min-width: 768px) {
.NotFoundPage_heading__1dQWd {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.9px;
  margin: 0
}
    }
.NotFoundPage_heading__1dQWd {
  text-align: center;
  margin-top: 16px
}
@media (min-width: 768px) {
.NotFoundPage_heading__1dQWd {
    margin-top: 22px
}
  }
.NotFoundPage_description__3Bm7k {
  text-align: center;
  margin-bottom: 0
}
@media (min-width: 768px) {
.NotFoundPage_description__3Bm7k {
    margin-top: 24px;
    margin-bottom: 0
}
  }
.NotFoundPage_searchForm__2rO_u {
  max-width: 408px;
  margin: 62px auto 0 auto;

  background-color: var(--matterColorLight);
  border-bottom: 4px solid var(--marketplaceColor);
  box-shadow: var(--boxShadowNotFoundPageSearch)
}
@media (min-width: 768px) {
.NotFoundPage_searchForm__2rO_u {
    margin-top: 64px
}
  }

.LoadableComponentErrorBoundary_root__VJIrD {
  /* Expand to the available space */
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.LoadableComponentErrorBoundary_topbar__3R8wI {
  /* Size */
  width: 100%;
  height: var(--topbarHeight);

  /* Layout for child components */
  display: flex;
  align-items: center;
  justify-content: center;

  /* fill */
  background-color: var(--matterColorLight);

  /* shadows */
  box-shadow: var(--boxShadow)
}

@media (min-width: 1024px) {

.LoadableComponentErrorBoundary_topbar__3R8wI {
    height: var(--topbarHeightDesktop);
    justify-content: center
}
  }

.LoadableComponentErrorBoundary_logoMobile__11_Ut {
  display: block
}

.LoadableComponentErrorBoundary_logoMobile__11_Ut path {
    fill: var(--marketplaceColor);
  }

@media (min-width: 1024px) {

.LoadableComponentErrorBoundary_logoMobile__11_Ut {
    display: none
}
  }

.LoadableComponentErrorBoundary_logoDesktop__WzJea {
  display: none;
  width: auto;
  height: 27px
}

@media (min-width: 1024px) {

.LoadableComponentErrorBoundary_logoDesktop__WzJea {
    display: block
}
  }

.LoadableComponentErrorBoundary_content__1D02h {
  max-width: 587px;
  width: 80%;
  margin-top: 60px;
  margin-bottom: 60px
}

@media (min-width: 768px) {

.LoadableComponentErrorBoundary_content__1D02h {
    margin-top: 10vh
}
  }

.LoadableComponentErrorBoundary_number__35Ors {
  font-size: 36px;
  line-height: 100%;
  letter-spacing: -0.7px;
  margin-top: 0px;
  margin-bottom: 14px
}

@media (min-width: 768px) {

.LoadableComponentErrorBoundary_number__35Ors {
  font-size: 50px;
  letter-spacing: -1.5px;
  margin-bottom: 23px
}
    }

.LoadableComponentErrorBoundary_number__35Ors {
  text-align: center;
  color: var(--marketplaceColor);
  margin: 1px 0 0 0
}

@media (min-width: 768px) {

.LoadableComponentErrorBoundary_number__35Ors {
    margin: 0
}
  }

.LoadableComponentErrorBoundary_heading__1035A {
  font-weight: var(--fontWeightSemiBold);
  font-size: 22px;
  line-height: 100%;
  letter-spacing: -0.5px;
  margin: 0;
  font-weight: var(--fontWeightBold)
}

@media (min-width: 768px) {

.LoadableComponentErrorBoundary_heading__1035A {
  font-weight: var(--fontWeightSemiBold);
  letter-spacing: -0.9px;
  margin: 0
}
    }

.LoadableComponentErrorBoundary_heading__1035A {
  text-align: center;
  margin-top: 16px
}

@media (min-width: 768px) {

.LoadableComponentErrorBoundary_heading__1035A {
    margin-top: 22px
}
  }

.LoadableComponentErrorBoundary_description__YYgH9 {
  text-align: center;
  margin-bottom: 0
}

@media (min-width: 768px) {

.LoadableComponentErrorBoundary_description__YYgH9 {
    margin-top: 24px;
    margin-bottom: 0
}
  }


/*! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css *//* Document (https://html.spec.whatwg.org/multipage/semantics.html#semantics)
   ========================================================================== *//**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add box sizing inheritence in all browsers (opinionated).
 */*,
::before,
::after {
  background-repeat: no-repeat; /* 1 */
  box-sizing: inherit; /* 2 */
}/**
  * 1. Add text decoration inheritance in all browsers (opinionated).
  * 2. Add vertical alignment inheritence in all browsers (opinionated).
  */::before,
::after {
  text-decoration: inherit; /* 1 */
  vertical-align: inherit; /* 2 */
}/**
  * 1. Add border box sizing in all browsers (opinionated).
  * 2. Add the default cursor in all browsers (opinionated).
  * 3. Prevent font size adjustments after orientation changes in IE and iOS.
  */html {
  box-sizing: border-box; /* 1 */
  cursor: default; /* 2 */
  -ms-text-size-adjust: 100%; /* 3 */
  -webkit-text-size-adjust: 100%; /* 3 */
}/* Sections (https://html.spec.whatwg.org/multipage/semantics.html#sections)
    ========================================================================== *//**
  * Add the correct display in IE 9-.
  */article,
aside,
footer,
header,
nav,
section {
  display: block;
}/**
  * Remove the margin in all browsers (opinionated).
  */body {
  margin: 0;
  background: pink;
}/**
  * Correct the font size and margin on `h1` elements within `section` and
  * `article` contexts in Chrome, Firefox, and Safari.
  */h1 {
  font-size: 2em;
  margin: 0.67em 0;
}/* Grouping content (https://html.spec.whatwg.org/multipage/semantics.html#grouping-content)
    ========================================================================== *//**
  * Add the correct display in IE 9-.
  * 1. Add the correct display in IE.
  */figcaption,
figure,
main {
  /* 1 */
  display: block;
}/**
  * Add the correct margin in IE 8.
  */figure {
  margin: 1em 40px;
}/**
  * 1. Add the correct box sizing in Firefox.
  * 2. Show the overflow in Edge and IE.
  */hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}/**
  * Remove the list style on navigation lists in all browsers (opinionated).
  */nav ol,
nav ul {
  list-style: none;
}/**
  * 1. Correct the inheritance and scaling of font size in all browsers.
  * 2. Correct the odd `em` font sizing in all browsers.
  */pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}/* Text-level semantics (https://html.spec.whatwg.org/multipage/semantics.html#text-level-semantics)
    ========================================================================== *//**
  * 1. Remove the gray background on active links in IE 10.
  * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
  */a {
  background-color: transparent; /* 1 */
  -webkit-text-decoration-skip: objects; /* 2 */
}/**
  * 1. Remove the bottom border in Firefox 39-.
  * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
  */abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted; /* 2 */
}/**
  * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
  */b,
strong {
  font-weight: inherit;
}/**
  * Add the correct font weight in Chrome, Edge, and Safari.
  */b,
strong {
  font-weight: bolder;
}/**
  * 1. Correct the inheritance and scaling of font size in all browsers.
  * 2. Correct the odd `em` font sizing in all browsers.
  */code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}/**
  * Add the correct font style in Android 4.3-.
  */dfn {
  font-style: italic;
}/**
  * Add the correct background and color in IE 9-.
  */mark {
  background-color: #ffff00;
  color: #000000;
}/**
  * Add the correct font size in all browsers.
  */small {
  font-size: 80%;
}/**
  * Prevent `sub` and `sup` elements from affecting the line height in
  * all browsers.
  */sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}sub {
  bottom: -0.25em;
}sup {
  top: -0.5em;
}/*
  * Remove the text shadow on text selections (opinionated).
  * 1. Restore the coloring undone by defining the text shadow (opinionated).
  */::selection {
  background-color: #b3d4fc; /* 1 */
  color: #000000; /* 1 */
  text-shadow: none;
}/* Embedded content (https://html.spec.whatwg.org/multipage/embedded-content.html#embedded-content)
    ========================================================================== *//*
  * Change the alignment on media elements in all browers (opinionated).
  */audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}/**
  * Add the correct display in IE 9-.
  */audio,
video {
  display: inline-block;
}/**
  * Add the correct display in iOS 4-7.
  */audio:not([controls]) {
  display: none;
  height: 0;
}/**
  * Remove the border on images inside links in IE 10-.
  */img {
  border-style: none;
}/**
  * Change the fill color to match the text color in all browsers (opinionated).
  */svg {
  fill: currentColor;
}/**
  * Hide the overflow in IE.
  */svg:not(:root) {
  overflow: hidden;
}/* Tabular data (https://html.spec.whatwg.org/multipage/tables.html#tables)
    ========================================================================== *//**
  * Collapse border spacing
  */table {
  border-collapse: collapse;
}/* Forms (https://html.spec.whatwg.org/multipage/forms.html#forms)
    ========================================================================== *//**
  * Remove the margin in Firefox and Safari.
  */button,
input,
optgroup,
select,
textarea {
  margin: 0;
}/**
  * Inherit styling in all browsers (opinionated).
  */button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}/**
  * Show the overflow in IE.
  * 1. Show the overflow in Edge.
  */button,
input {
  /* 1 */
  overflow: visible;
}/**
  * Remove the inheritance of text transform in Edge, Firefox, and IE.
  * 1. Remove the inheritance of text transform in Firefox.
  */button,
select {
  /* 1 */
  text-transform: none;
}/**
  * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
  *    controls in Android 4.
  * 2. Correct the inability to style clickable types in iOS and Safari.
  */button,
 html [type="button"], /* 1 */
 [type="reset"],
 [type="submit"] {
  -webkit-appearance: button; /* 2 */
}/**
  * Remove the inner border and padding in Firefox.
  */button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}/**
  * Restore the focus styles unset by the previous rule.
  */button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}/**
  * 1. Correct the text wrapping in Edge and IE.
  * 2. Correct the color inheritance from `fieldset` elements in IE.
  * 3. Remove the padding so developers are not caught out when they zero out
  *    `fieldset` elements in all browsers.
  */legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}/**
  * 1. Add the correct display in IE 9-.
  * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
  */progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}/**
  * 1. Remove the default vertical scrollbar in IE.
  * 2. Change the resize direction on textareas in all browsers (opinionated).
  */textarea {
  overflow: auto; /* 1 */
  resize: vertical; /* 2 */
}/**
  * 1. Add the correct box sizing in IE 10-.
  * 2. Remove the padding in IE 10-.
  */[type='checkbox'],
[type='radio'] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}/**
  * Correct the cursor style of increment and decrement buttons in Chrome.
  */[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}/**
  * 1. Correct the odd appearance in Chrome and Safari.
  * 2. Correct the outline style in Safari.
  */[type='search'] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}/**
  * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
  */[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}/**
  * 1. Correct the inability to style clickable types in iOS and Safari.
  * 2. Change font properties to `inherit` in Safari.
  */::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}/* Interactive elements (https://html.spec.whatwg.org/multipage/forms.html#interactive-elements)
    ========================================================================== *//*
  * Add the correct display in IE 9-.
  * 1. Add the correct display in Edge, IE, and Firefox.
  */details, /* 1 */
 menu {
  display: block;
}/*
  * Add the correct display in all browsers.
  */summary {
  display: list-item;
}/* Scripting (https://html.spec.whatwg.org/multipage/scripting.html#scripting-3)
    ========================================================================== *//**
  * Add the correct display in IE 9-.
  */canvas {
  display: inline-block;
}/**
  * Add the correct display in IE.
  */template {
  display: none;
}/* User interaction (https://html.spec.whatwg.org/multipage/interaction.html#editing)
    ========================================================================== *//*
  * Remove the tapping delay on clickable elements (opinionated).
  * 1. Remove the tapping delay in IE 10.
  */a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] { /* 1 */
  touch-action: manipulation;
}/**
  * Add the correct display in IE 10-.
  */[hidden] {
  display: none;
}/* ARIA (https://w3c.github.io/html-aria/)
    ========================================================================== *//**
  * Change the cursor on busy elements (opinionated).
  */[aria-busy='true'] {
  cursor: progress;
}/*
  * Change the cursor on control elements (opinionated).
  */[aria-controls] {
  cursor: pointer;
}/*
  * Change the display on visually hidden accessible elements (opinionated).
  */[aria-hidden='false'][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}/*
  * Change the cursor on disabled, not-editable, or otherwise
  * inoperable elements (opinionated).
  */[aria-disabled] {
  cursor: default;
}/* ================ Custom Properties aka CSS variables ================ *//**
 * These variables are available in global scope through ":root"
 * element (<html> tag). You can use Custom Properties to achieve
 * more dynamic theming by changing the value on the fly with JS:
 * document.documentElement.style.setProperty("--marketplaceColor", '#55AA55');
 *
 * Read more about how to use CSS Custom Properties
 * https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
 */:root {
  /* ================ Colors ================ */

  --marketplaceColor: #282828;
  /* hsl(260, 63%, 46%) */
  --marketplaceColorLight: #282828;
  /* hsl(260, 63%, 56%), +10L from marketplaceColor */
  --marketplaceColorDark: #040404;
  /* hsl(260, 63%, 26%), -20L from marketplaceColor */

  /* Used with inline CSS SVGs */
  --marketplaceColorEncoded: %23a02cbf;

  --successColor: #040404;
  --successColorDark: #212020;
  --successColorLight: #f0fff6;
  --failColor: #ff0000;
  --failColorLight: #fff0f0;
  --attentionColor: #ffaa00;
  --attentionColorLight: #fff7f0;
  --bannedColorLight: var(--marketplaceColorLight);
  --bannedColorDark: var(--marketplaceColor);

  --matterColorDark: #000000;
  --matterColor: #5a5a5a;
  --matterColorAnti: #b2b2b2;
  --matterColorNegative: #e7e7e7;
  --matterColorBright: #fcfcfc;
  --matterColorLight: #ffffff;

  --matterColorLightTransparent: rgba(255, 255, 255, 0.65);

  /* ================ Font ================ */
  --fontFamily: 'Open Sans', sans-serif;
  ;

  --fontWeightThin: 300;
  --fontWeightRegular: 400;
  --fontWeightMedium: 500;
  --fontWeightSemiBold: 600;
  --fontWeightBold: 700;

  --fontWeightHighlightEmail: var(--fontWeightBold);

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */
  --spacingUnit: 6px;
  --spacingUnitDesktop: 8px;

  /* Shadows */
  --boxShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowTop: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowLight: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  --boxShadowPopup: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  --boxShadowPopupLight: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowBreakdown: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowButton: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  --boxShadowListingCard: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  --boxShadowNotFoundPageSearch: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  --boxShadowSectionLocationHover: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  --boxShadowBottomForm: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
  --boxShadowFilterButton: 0 4px 16px 0 rgba(0, 0, 0, 0.2);

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  --zIndexTopbar: 10;
  /* small popups on UI should use z-indexes above 50 */
  --zIndexPopup: 50;
  /* modals and UI overlays should use z-indexes above 100 */
  --zIndexModal: 100;
  /* generic error message should overlay even modals */
  --zIndexGenericError: 200;

  /* ================ Border radius ================ */

  --borderRadius: 2px;
  --borderRadiusMobileSearch: 3px;

  /* ================ Transition styles ================ */

  --transitionStyle: ease-in 0.2s;
  --transitionStyleButton: ease-in-out 0.1s;

  /* ================ Topbar related ================ */

  --topbarHeight: 50px;
  --topbarHeightDesktop: 76px;

  --TopbarMobileMenu_topMargin: 96px;

  --Topbar_logoHeight: 25px;
  --CheckoutPage_logoHeight: 25px;
  --CheckoutPage_logoHeightDesktop: 27px;

  --TopbarSearchForm_inputHeight: 53px;
  --TopbarSearchForm_topbarMargin: 94px;
  --TopbarSearchForm_bottomBorder: 3px;

  /* ================ Modal default padding ================ */

  --modalPadding: 24px 24px 48px 24px;
  --modalPaddingMedium: 55px 60px 55px 60px;

  /* ================ Filters ================ */
  --marketplaceButtonSmallDesktopPadding: 9px 16px 9px 16px;

  /* ================ DateInput, DateRangeInput, DateRangeController ================ */
  --ReactDates_selectionHeight: 36px;
  --ReactDates_hoveredOverlayColor: rgba(255, 255, 255, 0.2);

  --DateInput_selectionHeight: var(--ReactDates_selectionHeight);
  --DateInput_hoveredOverlayColor: var(--ReactDates_hoveredOverlayColor);
  --DateRangeInput_selectionHeight: var(--ReactDates_selectionHeight);
  --DateRangeInput_hoveredOverlayColor: var(--ReactDates_hoveredOverlayColor);
  --DateRangeController_selectionHeight: var(--ReactDates_selectionHeight);

  /* ================ SectionHero ================ */

  --SectionHero_desktopTitleMaxWidth: 625px;

  /* ================ TabNav ================ */
  --TabNav_linkWidth: 240px;

  /* ================ LandingPage ================ */
  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;

  /* ================ EditListingAvailabilityForm, ManageAvailabilityCalendar ================ */
  --ManageAvailabilityCalendar_gridColor: #e0e0e0;
  --ManageAvailabilityCalendar_availableColor: #ffffff;
  --ManageAvailabilityCalendar_availableColorHover: #fafafa;
  --ManageAvailabilityCalendar_blockedColor: #ebebeb;
  --ManageAvailabilityCalendar_blockedColorHover: #e6e6e6;
  --ManageAvailabilityCalendar_reservedColor: #e6fff0;
  --ManageAvailabilityCalendar_reservedColorHover: #e1faeb;
  --ManageAvailabilityCalendar_failedColor: #fff2f2;

  /* ================ ProfileSettingsForm ================ */
  --ProfileSettingsForm_avatarSize: 96px;
  --ProfileSettingsForm_avatarSizeDesktop: 240px;
}/* ================ Global element styles ================ */body {
  font-family: 'Open Sans', sans-serif;
  ;
  -webkit-font-smoothing: antialiased;
  /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
  -moz-osx-font-smoothing: grayscale;
  /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
  text-rendering: optimizeSpeed;

  background-color: var(--matterColorBright);
}::selection {
  background: var(--marketplaceColor);
  /* WebKit/Blink Browsers */
  color: var(--matterColorLight);
}::-moz-selection {
  background: var(--marketplaceColor);
  /* Gecko Browsers */
  color: var(--matterColorLight);
}a {
    /* Position and dimensions */
  display: inline;
  margin: 0;
  padding: 0;

    /* Borders */
  border: none;

    /* Colors */
  color: var(--marketplaceColor);

    /* Text size should be inherited */
  text-decoration: none;
}a:hover {
  text-decoration: underline;
  cursor: pointer;
    }h1 {
  font-weight: var(--fontWeightSemiBold);
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.5px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 18px;
  margin-bottom: 18px;
}@media (min-width: 768px) {h1 {
  font-size: 40px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 48px;
  letter-spacing: -1px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 24px;
  margin-bottom: 24px;
}
    }h2 {
  font-weight: var(--fontWeightSemiBold);
  font-size: 21px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 21px;
  margin-bottom: 17px;
}@media (min-width: 768px) {h2 {
  line-height: 32px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 21px;
  margin-bottom: 19px;
}
    }h3 {
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 16px;
  margin-bottom: 14px;
}@media (min-width: 768px) {h3 {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 16px;
  margin-bottom: 16px;
}
    }h4 {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}@media (min-width: 768px) {h4 {
  font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 17px;
  margin-bottom: 15px;
}
    }h5 {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}@media (min-width: 768px) {h5 {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px;
}
    }h6 {
  font-weight: var(--fontWeightBold);
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;
}@media (min-width: 768px) {h6 {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 6px;
}
    }li {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
    /* No margins for default font */
}@media (min-width: 768px) {li {
  font-size: 16px;
  line-height: 32px;
}
    }p,
pre {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 12px;
  margin-bottom: 12px;
}@media (min-width: 768px) {p,
pre {
  font-size: 16px;
  line-height: 28px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 16px;
  margin-bottom: 16px;
}
    }/* ================ Normalisations ================ */html {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
    /* No margins for default font */
}@media (min-width: 768px) {html {
  font-size: 16px;
  line-height: 32px;
}
    }html {
  color: var(--matterColor);
  padding: 0;
  margin: 0;
}ul {
  list-style: none;
  padding: 0;
}legend,
label {
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px
}@media (min-width: 768px) {legend,
label {
  line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
  margin-top: 10px;
  margin-bottom: 14px
}
    }legend,
label {
  font-weight: var(--fontWeightSemiBold);
  display: block;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 3px;
  padding-bottom: 5px
}@media (min-width: 768px) {legend,
label {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0
}
  }button {
  font-family: 'Open Sans', sans-serif;
}select {
    /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;
  padding: 6px 15px;
  border-radius: 3px;

    /* Unset user agent styles */
  -webkit-appearance: none;
          appearance: none;

    /* Background */
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="9" viewBox="0 0 14 9" xmlns="http://www.w3.org/2000/svg"><path d="M6.53 7.472c.26.26.68.26.94 0l5.335-5.333c.26-.263.26-.684 0-.944-.26-.26-.683-.26-.943 0L7 6.056l-4.862-4.86c-.26-.26-.683-.26-.943 0-.26.26-.26.68 0 .943L6.53 7.47z" stroke="%234A4A4A" fill="%234A4A4A" fill-rule="evenodd"/></svg>');
  background-size: 16px 16px;
  background-position: center right 15px;

    /* Effects */

  cursor: pointer;
  transition: border-color var(--transitionStyle);
}select:hover,
    select:focus {
  border-color: var(--matterColor);
  outline: none;
    }select {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
    /* No margins for default font */
}@media (min-width: 768px) {select {
  font-size: 16px;
  line-height: 32px;
}
    }input {
    /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;
  padding: 6px 15px;

    /* Borders */
  border: solid 1px #dcdcdc;
  border-radius: 3px;
}input::-webkit-input-placeholder {
  color: var(--matterColorAnti);
    }input:-ms-input-placeholder {
  color: var(--matterColorAnti);
    }input::placeholder {
  color: var(--matterColorAnti);
    }input {

    /* Effects */

  transition: border-color var(--transitionStyle);
}input:hover,
    input:focus {
  border-color: var(--matterColor);
  outline: none;
    }input:disabled {
  color: var(--matterColorAnti);
  border-color: var(--matterColorAnti);
    }input {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
    /* No margins for default font */
}@media (min-width: 768px) {input {
  font-size: 16px;
  line-height: 32px;
}
    }textarea {
    /* Dimensions */
  display: block;
  width: 100%;
  margin: 0;
  padding: 6px 15px;

    /* Borders */
  border: solid 1px #dcdcdc;
  border-radius: 3px;
}textarea::-webkit-input-placeholder {
  color: var(--matterColorAnti);
    }textarea:-ms-input-placeholder {
  color: var(--matterColorAnti);
    }textarea::placeholder {
  color: var(--matterColorAnti);
    }textarea {

    /* Effects */

  transition: border-color var(--transitionStyle);
}textarea:hover,
    textarea:focus {
  border-color: var(--matterColor);
  outline: none;
    }textarea:disabled {
  color: var(--matterColorAnti);
  border-color: var(--matterColorAnti);
    }textarea {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
    /* No margins for default font */
}@media (min-width: 768px) {textarea {
  font-size: 16px;
  line-height: 32px;
}
    }textarea {

  /* Todo: All textareas are using auto-sizing extension which currently calculates required space incorrectly when box-sixing is "border-box" */
  box-sizing: border-box;
  min-height: 100px;
}.fontsLoaded body {
    font-family: 'Open Sans', sans-serif;
  }.fontsLoaded button {
    font-family: 'Open Sans', sans-serif;
  }
