.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.tabs {
  display: flex;
  overflow-x: scroll;
  min-height: 48px;
  padding-top: 2px;
  padding-left: 2px;

  @media (--viewportMedium) {
    overflow-x: initial;
    padding-left: 0px;
    min-height: 56px;
    padding-top: 10px;
  }

  @media (--viewportMedium) {
    min-height: auto;
    padding-top: 0;
  }
}

.tab {
  display: flex;
  align-items: flex-end;
  height: 100%;
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }
}