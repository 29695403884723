.input {
  border: solid 1px;
  border-color: #dcdcdc;
}

.inputSuccess {
  border-color: var(--successColor);
}

.inputError {
  border-color: var(--failColor);
}