@import '../../../styles/propertySets.css';

/* Desktop */
.root {
  width: 100%;
  padding: 10px 15px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowLight);

  @media (--viewportLarge) {
    padding: 10px 24px;
  }
}

.fixWidthContainer {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

/* logo */
.logoLink {
  flex-shrink: 0;
  height: 100%;
}

.logo {
  height: 56px;
  max-width: 150px;
  object-fit: contain;
  object-position: left center;
}

.navLink {
  font-size: 15px;
  line-height: 130%;
  color: var(--matterColor);
  padding: 8px 15px;
  border-radius: 0px;
  transition: all ease 0.5s;
  border: solid 1px transparent;

  &.signupLink {
    background-color: #140f0f;
    color: var(--matterColorLight);
    border-color: #515151;
    margin-left: 20px;

    &:hover {
      text-decoration: none;
      background-color: var(--marketplaceColor);
      border-color: var(--marketplaceColor);
      color: var(--matterColorLight);
    }
  }

  &.loginLink {
    background-color: var(--matterColorLight);
    border-color: var(--marketplaceColorDark);
    color: var(--marketplaceColorDark);
    margin-left: 10px;

    &:hover {
      text-decoration: none;
      background-color: var(--marketplaceColor);
      border-color: var(--marketplaceColor);
      color: var(--matterColorLight);
    }
  }

  &:hover {
    text-decoration: none;
    color: var(--marketplaceColor);
    transition: all ease 0.5s;
  }
}

/* Search */
.searchLink {
  flex-grow: 1;

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.search {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
  color: var(--matterColor);
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;

  @media (--viewportLarge) {
    padding-left: 34px;
  }
}

.inbox {
  @apply --TopbarDesktop_label;
  position: relative;
}

.notificationDot {
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 2px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  /* @apply --TopbarDesktop_linkHover; */
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0px;
  margin-left: 10px;

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
  top: calc(100% + 10px);
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  @apply --marketplaceH4FontStyles;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Font is specific to this component */
  @apply --marketplaceH5FontStyles;
  font-size: 14px;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);

  &:hover {
    color: var(--matterColorDark);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--matterColorDark);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--matterColorDark);
    }
  }
}

.links {
  display: flex;
  align-items: center;
}

.tabDot {
  height: 10px;
  width: 10px;
  background-color: #000;
  border-radius: 50%;
  display: inline-block;
  margin-left: 3px;
}