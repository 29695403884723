.sectionTitle {
  font-size: 20px;
  font-weight: 600;
  color: var(--matterColorDark);
  margin: 0;
}

.profileSliderButtons {
  position: absolute;
  top: 40%;
  right: 0;
  z-index: 2;
  cursor: pointer;

  & .iconHolder {
    background-color: #fff;
    border: 1px solid #ebebeb;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all ease 0.5s;
    cursor: pointer;

    @media (--viewportXXLarge) {
      width: 56px;
      height: 56px;
    }

    &:hover {
      border-color: #484848;
      cursor: pointer;
    }

    &:first-child {
      margin-right: 4px;

      @media (--viewportXXLarge) {
        margin-right: 14px;
      }
    }

    & svg {
      fill: none;
    }
  }
}

.homepage {
  width: calc(100% / 3 - 15px);
  margin-bottom: 15px;
  margin-right: 15px;
  scroll-snap-align: start;

  &:last-child {
    margin-right: 0px;

    &>a {
      margin-right: 0px;
    }
  }
}

.moreServices {
  & .moreServicesTitle {
    margin-bottom: 15px;

    & h1 {
      font-family: 'Sofia Pro Soft';
      font-style: normal;
      font-weight: bold;
      color: #1F1F1F;
      font-size: 25px;
      line-height: 100%;
      letter-spacing: -0.04em;
      margin: 0px 0px 16px 0;

      @media (min-width:480px) {
        font-size: 36.6667px;
        line-height: 100%;
      }

      @media (min-width:768px) {
        font-size: 28px;
        line-height: 100%;
      }

      @media (min-width:1260px) {
        font-size: 32px;
        line-height: 100%;
      }

      @media (min-width:1366px) {
        font-size: 32px;
        line-height: 100%;
      }

      @media (min-width:1660px) {
        font-size: 36.8889px;
        line-height: 100%;
      }

      @media (min-width:1921px) {
        font-size: 56.8956px;
        line-height: 100%;
      }
    }

    & .sliderButtons {
      display: none;

      @media (--viewportMedium) {
        display: block;
      }

      & .iconHolder {
        background-color: #fff;
        border: 1px solid #ebebeb;
        width: 32px;
        height: 32px;
        border-radius: 100px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: all ease 0.5s;

        @media (--viewportXXLarge) {
          width: 56px;
          height: 56px;
        }

        &:hover {
          border-color: #484848;
          cursor: pointer;
        }

        &:first-child {
          margin-right: 4px;

          @media (--viewportXXLarge) {
            margin-right: 14px;
          }
        }

        & svg {
          fill: none;
        }
      }
    }
  }

  & .cardSlider {
    /* hide scroll bar */
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;
    z-index: 0;
    scroll-snap-coordinate: 0 0;
    scroll-snap-points-x: repeat(100%);
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
      display: none;
    }

    @media(max-width: 767px) and (min-width: 581px) {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      & .cardSliderItem {
        scroll-snap-align: start;
        width: calc(100% / 3 - 10px);
        margin-bottom: 15px;
        margin-right: 15px;
        scroll-snap-align: start;

        &:nth-child(3n) {
          margin-right: 0px;
        }

        & .imgWrapper {
          height: 200px;
        }
      }
    }

    @media (max-width: 580px) {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;

      & .cardSliderItem {
        scroll-snap-align: start;
        width: calc(100% / 3 - 10px);
        margin-bottom: 15px;
        /* margin-right: 15px; */
        scroll-snap-align: start;

        &:nth-child(odd) {
          /* margin-right: 15px; */
        }

        &:nth-child(even) {
          /* margin-right: initial; */
        }

        & .imgWrapper {
          height: 120px;
        }
      }
    }

    @media (max-width: 414px) {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      & .cardSliderItem {
        width: calc(63% / 2 - 5px);
        margin-bottom: 10px;
        /* margin-right: 10px; */

        &:nth-child(odd) {
          /* margin-right: 10px; */
        }

        &:nth-child(even) {
          margin-right: initial;
        }

        & .imgWrapper {
          height: 111px;
        }
      }
    }

    @media screen and (min-width: 320px) and (max-width: 370px) {
      & .cardSliderItem {
        & .imgWrapper {
          height: 84px;
        }
      }
    }

    &>div {
      &>div {
        overflow: hidden;
      }
    }

    &>a:first-child {
      padding: 0 0 0 24px;

      @media (--viewportSmall) {
        padding: 0;
      }
    }
  }

  & .recentlyAddedSlider {

    @media (max-width: 767px) {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;

      & .homepage {
        min-width: 260px;
        margin-right: 15px;
        scroll-snap-align: start;

        &:last-child {
          margin-right: 0px;

          &>a {
            margin-right: 0px;
          }
        }
      }

    }
  }

}

.moreServicesTitle {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 0 0 24px; */

  @media (--viewportSmall) {
    padding: 0;
  }

  &>h1 {
    font-size: 28px;
    line-height: 1;
    letter-spacing: -1.12px;
  }
}


.authors {
  width: 250px;
  height: 250px;
  border-radius: 50%;
}


.cardSliderItem {

  &.cardSliderItemMakes {

    @media(max-width: 767px) and (min-width: 581px) {
      scroll-snap-align: start;
      width: calc(100% / 3 - 10px);
      margin-bottom: 15px;
      /* margin-right: 15px; */
      scroll-snap-align: start;

      &:nth-child(3n) {
        margin-right: 0px;
      }
    }

    @media (max-width: 580px) {
      scroll-snap-align: start;
      width: calc(100% / 3 - 10px);
      margin-bottom: 15px;
      /* margin-right: 15px; */
      scroll-snap-align: start;

      &:nth-child(odd) {
        /* margin-right: 15px; */
      }

      &:nth-child(even) {
        /* margin-right: initial; */
      }
    }

    @media (max-width: 414px) {
      width: calc(100% / 3 - 5px);
      margin-bottom: 10px;
      /* margin-right: 10px; */

      &:nth-child(odd) {
        /* margin-right: 10px; */
      }

      &:nth-child(even) {
        margin-right: initial;
      }
    }

    &>a {
      &>div {
        height: 100px !important;
        width: 100px !important;
        border-radius: 100%;
        margin: 0 auto;

        @media screen and (min-width: 320px) and (max-width: 370px) {
          height: 85px !important;
          width: 85px !important;
        }

        @media (min-width:480px) {
          height: 120px !important;
          width: 120px !important;
        }

        @media (min-width:667px) {
          height: 200px !important;
          width: 200px !important;
        }

        @media (min-width:991px) {
          height: 250px !important;
          width: 250px !important;
        }
      }
    }

    & .imgWrapper {

      &>img {
        object-fit: contain;
        padding: 0px 18px;

        @media (min-width:667px) {
          padding: 0px 18px;
        }
      }
    }
  }

  &>a {
    display: inline-block;
    width: 100%;
    text-align: center;


    &:hover {
      text-decoration: none;
      transition: all ease 0.5s;

      & .imgWrapper {
        transition: all ease 0.5s;

        &>img {
          transform: scale(1.05);
          transition: all ease 0.5s;
        }
      }
    }

    & .imgWrapper {
      height: 250px;
      width: 100%;
      border: solid 1px #ececec;
      overflow: hidden;
      transition: all ease 0.5s;
      border-radius: 10px;

      &>img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: scale(1);
        transition: all ease 0.5s;
      }
    }

    & .cardItemTitle {
      font-size: 13px;
      font-weight: 600;
      margin-top: 10px;
      margin-bottom: 0;
      color: var(--marketplaceColorLight);
    }
  }

  &.popularDesignerWrapper {
    @media (max-width:767px) {
      min-width: 250px !important;
    }

    & .popularDesignerImg {
      width: 100%;
      height: 100%;

      &>img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.makesImage {
  &>img {
    max-width: 160px;
  }
}