@import '../../styles/propertySets.css';

.root {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);
  width: 100%;
  padding: 24px;
  background-color: var(--matterColorLight);
  position: relative;
  bottom: 0;

  @media (max-width:480px) {
    padding: 14px;
  }

  @media (--viewportMedium) {
    position: absolute;
    bottom: 0;
  }

}

.content {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }

  & .list {
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media (max-width:480px) {
      flex-wrap: wrap;
    }

    @media (--viewportMedium) {
      width: initial;
      justify-content: initial;
    }

    & .listItem {
      min-height: 24px;
      margin-bottom: 6px;

      @media (max-width:480px) {
        width: calc(100% / 3);
      }

      @media (--viewportMedium) {
        margin-bottom: 0;
      }

      & .link {
        @apply --marketplaceH5FontStyles;
        line-height: 20px;
        color: var(--matterColor);
        transition: var(--transitionStyleButton);
        padding: 10px;
        display: inline-block;
        margin-top: 0;
        margin-bottom: 12px;
        font-size: 15px;

        @media (max-width:767px) {
          margin-bottom: 8px;
          padding: 0px;
        }

        &:hover {
          color: var(--marketplaceColor);
          text-decoration: none;
        }

        @media (--viewportMedium) {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  & .extraLinks {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-right: 36px;
    @media (--viewportMedium) {
      width: initial;
    }

    & .socialLinks {}

    & .copyrightLinks {

      & .rightLinks {

        &:last-child {
          margin-right: 0;
        }
      }
    }

    & .rightLinks {
      margin-right: 12px;
    }

    & .logo {
      object-fit: contain;
      object-position: left center;
      height: 37px;
      width: 80px;
    }

    & .icon {
      display: inline-block;
      width: 20px;
      height: 36px;
      text-align: center;

      &.twitter {
        &>svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.mobileContent {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }

  & .list {
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media (max-width:480px) {
      /* flex-wrap: wrap; */
      /* padding: 18px 4px; */
    }

    @media (--viewportMedium) {
      width: initial;
      justify-content: initial;
    }

    & .listItem {
      min-height: 24px;
      margin-bottom: 6px;

      @media (max-width:480px) {
        width: calc(100% / 2);
      }

      @media (--viewportMedium) {
        margin-bottom: 0;
      }

      & .link {
        @apply --marketplaceH5FontStyles;
        line-height: 20px;
        color: var(--matterColor);
        transition: var(--transitionStyleButton);
        padding: 10px;
        display: inline-block;
        margin-top: 0;
        margin-bottom: 12px;
        font-size: 15px;

        @media (max-width:767px) {
          margin-bottom: 8px;
          padding: 0px;
        }

        &:hover {
          color: var(--marketplaceColor);
          text-decoration: none;
        }

        @media (--viewportMedium) {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }

  & .extraLinks {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @media (--viewportMedium) {
      width: initial;
    }

    & .socialLinks {}

    & .copyrightLinks {

      & .rightLinks {

        &:last-child {
          margin-right: 0;
        }
      }
    }

    & .rightLinks {
      margin-right: 12px;
    }

    & .logo {
      object-fit: contain;
      object-position: left center;
      height: 37px;
      width: 80px;
    }

    & .icon {
      display: inline-block;
      width: 20px;
      height: 36px;
      text-align: center;

      &.twitter {
        &>svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}


/* Footer links */