@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.link {
  display: inline-block;
  white-space: nowrap;
  @apply --marketplaceTabNavFontStyles;
  color: var(--matterColor);
  padding: 10px 20px;
  margin-top: auto;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  transition: var(--transitionStyleButton);

  &:hover {
    text-decoration: none;
    color: var(--matterColorDark);
  }

  @media (--viewportLarge) {
    border-bottom-width: 0px;
  }
}

.selectedLink {
  color: var(--matterColorDark);
  text-align: center;
  background-color: #fff;
  box-shadow: 0px 0px 4px #ddd;
}

.disabled {
  pointer-events: none;
  color: var(--matterColorAnti);
  text-decoration: none;
}