@import '../../../styles/propertySets.css';

.quantityField {
  margin-bottom: 10px;
  width: 100%;
}

.deliveryField {}

.singleDeliveryMethodSelected {
  margin: 0;
  font-weight: 700;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.breakdownWrapper {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-top: 40px;
  }

  &>h3 {
    color: var(--matterColorDark);
  }
}

.submitButton {
  margin-top: 14px;

  &>button {
    min-height: 50px;
    width: 100%;
    padding: 10px 30px;
  }
}

.messageButton {
  margin-top: 14px;

  &>button {
    min-height: 50px;
    width: 100%;
    padding: 10px 30px;
    background-color: #ffffff;
    color: black;
    border: 1px solid #dedede;

    &:hover,
    &:focus,
    &:active {
      background-color: #ffffff;
      color: black;
    }
  }
}

.finePrint {
  @apply --marketplaceTinyFontStyles;
  text-align: center;
}

.error {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);

  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
}