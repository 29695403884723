.root {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  padding-bottom: 136px;

  @media (min-width:320px) {
    padding-bottom: 100px;
  }

  @media (--viewportMedium) {
    padding-bottom: 90px;
  }
}

.searchRoot {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  padding-bottom: 136px;

  @media (max-width:767px) {
    padding-bottom: 0px;
  }

  @media (--viewportMedium) {
    padding-bottom: 90px;
  }
}

.content {}

.scrollingDisabled {
  /* position: fixed and width are added to prevent iOS from scrolling content */
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}