.root {}

.select {
  color: var(--matterColorAnti);
  border: solid 1px;
  border-color: #dcdcdc;
  padding-right: 20px;

  &>option {
    color: var(--matterColor);
  }

  &>option:disabled {
    color: var(--matterColorAnti);
  }
}

.selectSuccess {
  color: var(--matterColor);
  /* border-color: var(--successColor); */
}

.selectError {
  border-color: var(--failColor);
}