@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
  border: solid 1px #e7e7e7;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  z-index: 0;
  height: 100%;

  &:hover {
    text-decoration: none;
  }
}

.listingCardBlock {
  position: relative;
  height: 100%;

  & .favorite {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgb(255 255 255 / 81%);
    /* z-index: 1; */
    border-radius: 100px;
    padding: 6px;
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &>span {
      line-height: 0;

      &>svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.threeToTwoWrapper {
  display: block;
  width: 100%;
  position: relative;
  transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 4ms;
  height: 200px;
  overflow: hidden;

  @media (min-width:1024px) {
    height: 230px;
  }
  @media (max-width: 767px) {
   height: 160px;
  }

  &:hover {
    transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 4ms;
    box-shadow: var(--boxShadowListingCard);

    & img {
      transform: scale(1.05);
      transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 4ms;
    }
  }

  & .cardTag {
    background-color: rgb(255 255 255 / 81%);
    color: #333;
    font-size: 12px;
    position: absolute;
    bottom: 6px;
    z-index: 1;
    padding: 0px 18px;
    left: 6px;
    border-radius: 6px;
    z-index: 1;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  background: var(--matterColorNegative);
  height: 100%;

  & img {
    transition: all 2000ms cubic-bezier(.19, 1, .22, 1) 4ms;
  }
}

.rootForImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  display: flex;
  flex-direction: column;
  padding: 10px;

  & .mainInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 6px 0;

    & .category {
      font-size: 14px;
      color: rgb(145 145 145);
      margin: 0;
    }

    & .title {
      @apply --marketplaceH3FontStyles;
      color: #282727;
      margin-top: 0;
      margin-bottom: 0;
      font-size: 15px;
      line-height: 130%;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    & .itemPrice {
      font-size: 13px;
      line-height: 110%;
    }

    & .certificateInfo {
      @apply --marketplaceTinyFontStyles;
      color: var(--matterColor);
      margin-top: 0;
      margin-bottom: 0;

      @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  & .itemDiscount {
    margin-bottom: 2px;

    & .itemModal {
      font-size: 14px;
      color: #949494;
      line-height: 111%;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &>span {
        margin-right: 4px;
        font-weight: 400;
      }
    }
  }

  & .itemDescription {
    margin-bottom: 8px;

    &>p {
      font-size: 14px;
      color: rgb(145 145 145);
      line-height: 111%;
      margin: 0;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  & .price {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-shrink: 0;

    & .priceValue {
      @apply --marketplaceH3FontStyles;
      margin-top: 0;
      margin-bottom: 0;

      @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    & .perUnit {
      @apply --marketplaceTinyFontStyles;
      color: var(--matterColor);
      margin-top: 0;
      margin-bottom: 0;

      @media (--viewportMedium) {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
}



.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.make {
  display: flex;
  justify-content: space-between;
  padding: 0px 21px 8px 21px;
}

.category {
  color: #282727;
  font-size: 13px;
}