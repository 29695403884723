.root {
  display: block;
  width: 100%;
  overflow: hidden;
  position: relative;
  transition: all ease 0.5s;

  &:hover {
    transition: all ease 0.5s;

    & .aspectPadding {
      & .aspectBox {
        /* & img {
          transform: scale(1.05);
          box-shadow: var(--boxShadowListingCard);
          transition: all ease 0.5s;
        } */
      }
    }
  }

  & .aspectPadding {

    & .aspectBox {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;

      & img {
        transform: scale(1);
        transition: all ease 0.5s;
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }
}