/* #root {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  min-width: 700px;
} */

.tags {
  background: #fff;
  border: 1px solid #ebebeb;
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  margin: 0;
  padding: 6px 36px 6px 15px;
  border-radius: 3px;

  & ul {
    margin: 0;
  }
}

.tagsItem {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}

.deleteAllTags {
  position: absolute;
  top: 9px;
  right: 6px;
  line-height: 0;
  padding: 10px;
  cursor: pointer;

  &>svg {
    stroke: none;
  }

  &:hover {
    &>svg {
      fill: #f00;
    }
  }
}

.tag {
  color: #fff;
  font-weight: bold;
  background: #fff;
  float: left;
  padding: 8px 9px;
  border-radius: 10em;
  margin: 2px 4px 2px 0;
  color: #0d0d0d;
  letter-spacing: -0.04em;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  background: #e9e9e9;
  border-radius: 26px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  & button {
    background: transparent;
    border: 0;
    cursor: pointer;
  }

  & svg {
    margin-left: 7px;
    cursor: pointer;
  }
}

.tagInput {
  padding: 2px 0px 5px;
  box-sizing: border-box;
  color: #0d0d0d;
  letter-spacing: -0.04em;
  font-weight: normal;
  line-height: 100%;
  border: 0;
  float: left;
  margin: 0px 0 0;
  font-size: 16px;
  outline: 0;
  width: auto;
  background: #fff;
  border: 0 !important;

  &:focus {
    border: 0 !important;
  }

  &:hover {
    border: 0 !important;
  }
}

code {
  /* font-size: 12px;
  background: #fcf8d0;
  display: inline-block;
  font-family: courier;
  padding: 4px 6px;
  border-radius: 4px; */
}

.tip {
  /* color: #7f8c8d;
  font-size: 14px;
  margin-top: 10px;
  display: block;
  line-height: 16px; */
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.04em;
  color: #767676;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.totalTags {
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.04em;
  color: #767676;
  width: auto !important;
}

.form {
  & .labelWrapper {
    margin-bottom: 12px;

    & label {
      padding: 0;
      font-size: 14px;
      line-height: 100%;
      letter-spacing: -0.04em;
      color: #5a5a5a;
      font-weight: 600;
    }

    & button {}
  }

  & .tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.ingresosWrapper {
  position: relative;
}

.tooltip {
  position: absolute;
  padding: 14px !important;
  right: 0px;
  width: 318px;
  height: auto;
  top: 34px;
  left: 0px;
  background-color: white;
  cursor: pointer;
  border: 0px solid black;
  border-radius: 5px;
  z-index: 1;
  display: block;
  padding: 5px;
  box-shadow: 0 -1px 4px 0 rgb(0 0 0 / 12%), 0 4px 4px 0 rgb(0 0 0 / 25%);

  @media (--viewportMedium) {
    top: 34px;
    left: 10px;
  }

  @media (--viewportLarge) {
    top: 34px;
    left: 10px;
  }
}

.tooltip::after {
  content: ' ';
  position: absolute;
  bottom: 100%;
  left: 90px;
  margin-left: -10px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
  border-left: solid 12px transparent;
  border-right: solid 12px transparent;
  border-bottom: solid 8px #fff;
  filter: drop-shadow(1px -1.2px 1px #00000020);
  height: 0px;
  width: 0;

  @media (--viewportMedium) {
    left: 40px;
  }
}

.tooltipStyle {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: -0.56px;
  text-align: left;
  color: #484848;
  font-family: 'Sofia Pro Soft';
  margin-bottom: 0px !important;
}

.toltipCloseBtn {
  position: absolute;
  right: 15px;
  top: 15px;
}