.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Container for side and content */
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: calc(1200px + 24px + 24px);
  margin: 30px auto;
  padding: 0 24px;
}

.layoutWrapperMain {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: #fff;
  box-shadow: 0px 0px 4px #ddd;
}