@import '../../styles/propertySets.css';

/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: 100%;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 36px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColorDark);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.price {
  @apply --marketplaceH2FontStyles;
  color: var(--marketplaceColor);
  font-weight: 700;
}

.sellerInfo {
  margin: 20px 0;
  float: left;
  width: 100%;
}

.author {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin: 0px;

  @media (--viewportMedium) {
    color: var(--matterColorDark);
    font-size: 17px;
  }
}

.providerAvatar {
  margin-right: 10px;
  width: 76px;
  height: 76px;

  &>span {
    font-size: 20px !important;
  }
}

.orderHeading {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & .title {
    margin-bottom: 0;
  }
}

.orderTitle {
  /* Font */
  color: var(--matterColorDark);
  font-weight: var(--fontWeightSemiBold);
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 2px;

  @media (--viewportLarge) {
    margin-bottom: 0;
  }
}

.orderHelp {
  display: none;
  @apply --marketplaceH5FontStyles;

  @media (--viewportMedium) {
    color: var(--matterColor);
    display: block;
    margin-top: 0;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 0 0 84px 0;

  @media (--viewportMedium) {
    margin: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.bookingDatesSubmitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
}

.openOrderForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 5px 12px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.orderButton {
  @apply --marketplaceButtonStylesPrimary;
  padding: 10px 30px;
  min-height: 50px;
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.receipt {
  flex-shrink: 0;
}

.tags {
  flex-wrap: wrap;
  display: flex;

  &>span {
    border: 1px solid black;
    border-radius: 2px;
    display: grid;
    align-items: center;
    grid-auto-flow: column;
    padding: 2px 8px;
    text-decoration: none;
    transition: background-color .25s ease, color .25s ease;
    margin-right: 3px;
    color: black;
    line-height: 20px;
    margin-bottom: 3px;
    font-size: 14px;
    word-break: break-all;
  }
}

.bookmark {
  &>svg {
    width: 20px;
  }
}

.productDetails {
  margin-bottom: 15px;

  & .productinfo {
    width: 100%;
  }
}

.tagsSection {
  margin-top: 20px;
  float: left;
  width: 100%;

  &>span {
    color: var(--marketplaceColor);
    margin: 0 0 10px 0;
    font-size: 18px;
  }

  &>h2 {
    margin: 0 0 10px 0;
    color: var(--marketplaceColor);
    font-size: 18px;
  }

  & .tags {}
}

.paypalPurchase {
  margin-top: 20px;
  float: left;
  width: 100%;

  &>h2 {
    margin: 0 0 10px 0;
    color: var(--marketplaceColor);
    font-size: 18px;

    &>img {}
  }

  &>p {
    margin: 0;
  }
}

.orderSellerInfo {
  margin: 40px 0 20px 0;

  &>h2 {
    font-size: 18px;
    line-height: 100%;
    font-weight: var(--fontWeightSemiBold);
    color: var(--matterColorDark);
  }
}